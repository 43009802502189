import React, {Component, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Container, Row, Col, Form, Table, Modal  } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as RiIcons from "react-icons/ri";
import { useParams } from 'react-router-dom';
import {  RiBillLine } from "react-icons/ri";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import Loader2 from "react-loader-spinner";
import { Typeahead } from 'react-bootstrap-typeahead';

import './receipt-voucher.css'

function ReceiptVoucher(props) {
    const [btnloader, setBtnloader] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [shownw, setShownw] = React.useState(false);
    const [receiptvoucherId, setReceiptvoucherId] = React.useState('');
    const [registerDate, setRegisterDate] = React.useState(new Date);
    const [refDate, setRefdate] = React.useState(new Date);
    const [refno, setRefno] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [billType, setBilltype] = React.useState('');
    const [invoice, setInvoice] = React.useState('');
    const [invoNum, setInvoNu] = React.useState('')
    const [paymode, setPaymode] = React.useState('');
    const [userId, setUserid] = React.useState('');
    const [modal, setModal] = React.useState(false);
    const [allmodal, setAllmodal] = React.useState(false);
    const [againstmodal, setAgainstmodal] = React.useState(false);

    const [ledgercode, setLedgercode] = React.useState("");
    const [ledgername, setLedgername] = React.useState("");
    const [ledgerid, setLedgerid] = React.useState("");

    const [paymentcode, setPaymentcode] = React.useState("");
    const [paymentname, setPaymentname] = React.useState("");
    const [paymentamount, setPaymentamount] = React.useState("");
    const [paymentaccid, setPaymentaccid] = React.useState("");
    const [paymenttype, setPaymenttype] = React.useState("");
    const [totalAmount, setTotalamount] = React.useState(0);
    const [editmode, setEditmode] = React.useState(false);

    const [accountlist, setAccountlist] = React.useState([]);
    const [billtypelist, setBilltypelist] = React.useState([]);
    const [allaccountlist, setAllaccountlist] = React.useState([]);
    const {id} = useParams()
    const [saleslist, setSaleslist] = React.useState([]);

    const [salesid, setSalesid] = React.useState("");
    const [billinvoice, setBillinvoice] = React.useState("");
    
    const [type, setType] = React.useState("");

    const [tableRow, setTablerow] = React.useState([{key: "", reciept_code: "", rvd_account_code: "", rvd_account_id_fk: "", rvd_transtype: "other", rvd_remarks:"", rvd_amount: "", pay_account_name: "", rvd_type: "", rvd_sales_fk: ""}]);

    const [selectedIndex, setSelectedindex] = React.useState("");
    const [debitTotal, setDebittotal] = useState(0);
    const [creditTotal, setCredittotal] = useState(0);
    const [bank, setBank] = React.useState("");
    const [accbalance, setAccbalance] = React.useState("");
    const [banklist, setBanklist] = React.useState([]);
    const [pdcamount, setPdcamount] = React.useState("");
    const [pdcamt, setPdcamt] = useState("");
    const [bpaymode, setBpaymode] = React.useState("");
    const [entrydate, setEntrydate] = React.useState(new Date);
    const [postdate, setPostdate] = React.useState(new Date);
    const [chequeno, setChequeno] = React.useState("");
    const [pdcamt2, setPdcamt2] = useState("");

    const [selected, setSelected] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [instrudate, setInstrudate] = React.useState(new Date);
    const [detls, setDetls] = React.useState(false);

    const selectChange = (value) => {
        setSelected(value)
        setLedgerid(value[0].ledger_id)
        setLedgername(value[0].label)
        setLedgercode(value[0].ledger_code)
    }

    const accountChange = (event, index) => {
        console.log("accounts", event)
        var input = [...tableRow]
        input[index].reciept_code = event[0].common_code
        input[index].rvd_account_code = event[0].common_code
        input[index].pay_account_name = event[0].label
        input[index].rvd_account_id_fk = event[0].common_id
        input[index].rvd_type = event[0].type
    }

    const paymodeChange = (event) => {
        setPaymode(event.target.value);

    };

    const pdcamtChange = (e) => {
        setPdcamt(e.target.value)
    }

    const pdcamtChange2 = (e) => {
        setPdcamt2(e.target.value)
    }

    const selectAccount = () => {
        setModal(true)
        getAccountlist()
    }

    const bpaymodeChange = (event) => {
        setBpaymode(event.target.value);
    };

    const modalClick2 = () => {
        setDetls(true)
    }



    useEffect(() => {
        calcTotal()
    }, [tableRow]);

    const calcTotal = () => {
        var debitTotal = 0
        for(var i=0; i<tableRow.length; i++) {
              debitTotal += parseInt(tableRow[i].rvd_amount)
        }
        setDebittotal(debitTotal)
    }

    useEffect(() => {
        getbankdata()
    }, [])

    const getbankdata =() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        
  
        var data = {
          "usertype": userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewBankmaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              if(responseJson.status == "false") {
               
                setBanklist(responseJson.data)
                // console.log("response", responseJson.data)
              }
              else{
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const getAccountlist = () => {
        const data = {
            "payment_mode": paymode,
        }
        fetch(global.url + "viewPaymentByMode", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response list7", responseJson)
            setAccountlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
            
        })
    }

    useEffect(() => {
        console.log("id", id)
        
        getBilltype()
        if(id == "add") {
            getInvoiceno()
            setEditmode(false)
        }
        else{
           getDetailsbyid(id)
           setEditmode(true)
        }
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
    }, []);

    const modalClick = () => {
        setShownw(true)
    }


    const getDetailsbyid = (id) => {
        console.log("id", id)
        const data = {
            "reciept_id": id,
        }
        fetch(global.url + "viewReciept", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("payment head", responseJson)
            if(responseJson.status == "false") {
                console.log("payment head", responseJson.data)
                setBilltype(responseJson.data.reciept_bill_type)
                setInvoice(responseJson.data.reciept_invoice_no)
                var invoiceDate=new Date(responseJson.data.reciept_date).toISOString();
                var referenedate=new Date(responseJson.data.reciept_ref_date).toISOString();
                setRegisterDate(invoiceDate)
                setRefdate(referenedate)
                setRefno(responseJson.data.reciept_ref_no)
                setPaymode(responseJson.data.reciept_payment_mode)
                setLedgercode(responseJson.data.ledger_code)
                setLedgername(responseJson.data.ledger_name)

                console.log("payment table", responseJson.details)
                setTablerow(responseJson.details)
                setRemarks(responseJson.data.reciept_remarks)
                setReceiptvoucherId(responseJson.data.reciept_id)
                setLedgerid(responseJson.data.reciept_account_id_fk)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

    useEffect(() => {
        calculateTotal()
    }, [tableRow]);

    const calculateTotal = () => {
        var totalValues = 0
        for(var i = 0; i < tableRow.length; i++) {
            console.log("amount", tableRow[i].rvd_amount)
            totalValues += parseInt(tableRow[i].rvd_amount)
        }
        setTotalamount(totalValues)
    }

    const deleteClick = (index, id, invoice) => {
        deleteFromApi(id, invoice)
        var input = [...tableRow]
        input.splice(index, 1)
        setTablerow(input)
    }

    const deleteFromApi = (id, invoice) => {
            if(window.confirm('Are you Want to delete this ? It will be deleted from your database')){
            var userdata = JSON.parse(localStorage.getItem("user"))
            const data = {
                "rvd_id" : id,
                "invoice": invoice,
                "userid": userdata.user_id
            }
            console.log("data detid", data)
            fetch(global.url + "deleteRecieptinEdit", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("del respo", responseJson)
                if(responseJson.error == true) {
                    alert(responseJson.message)
                }
                else{
                    alert(responseJson.message)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }else{
            window.location.reload()
        }
    }

      const getInvoiceno = () => {
        fetch(global.url + "recieptinvoiceno", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("list response", responseJson)
                setInvoice(responseJson.data.invoiceno)
                setInvoNu(responseJson.data.invoiceno)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewBillSetting", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("list response", responseJson)
              setBilltypelist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }
      


      const selectedAcc = (name, code, id) => {
          console.log("click")
            setLedgercode(code)
            setLedgername(name)
            setLedgerid(id)
            setModal(false)
      }

    //   const addRow = () => {
    //       if(paymentcode == "") {
    //           return alert("Code requred")
    //       }
    //       if(paymentamount == "") {
    //         return alert("Amount requred")
    //       }
    //     setTablerow([...tableRow, {reciept_code: paymentcode, rvd_account_code: paymentcode, rvd_account_id_fk: paymentaccid, rvd_amount: paymentamount, pay_account_name: paymentname, rvd_type: paymenttype, rvd_sales_fk : salesid}])
    //     setPaymentcode("")
    //     setPaymentname("")
    //     setPaymenttype("")
    //     setPaymentaccid("")
    //     setPaymentamount("")
    //     setSalesid("")
    // }

    const addRow = () => {
        setTablerow([...tableRow, {key: "", reciept_code: "", rvd_account_code: "", rvd_account_id_fk: "", rvd_transtype: "", rvd_remarks:"", rvd_amount: "", pay_account_name: "", rvd_type: "", rvd_sales_fk: ""}])
    }

    const tableselectedAccount = (index, code, name, id, type) => {
        // console.log("click")
        // setPaymentcode(code)
        // setPaymentname(name)
        // setPaymenttype(type)
        // setPaymentaccid(id)
        // setAllmodal(false)
        // setPaymentamount("")
        // setSalesid("")

        var input = [...tableRow]

        input[index].key = index
        input[index].reciept_code = code
        input[index].rvd_account_code = code 
        input[index].pay_account_name = name
        input[index].rvd_type = type
        input[index].rvd_account_id_fk = id

        setTablerow(input) 
        setAllmodal(false)       
        addRow()
    }

    const debitChange = (event, key) => {
        var input = [...tableRow]
        input[key].rvd_amount = event.target.value
        setTablerow(input)
    }

    const narrationChange = (event, key) => {
        var input = [...tableRow]
        input[key].rvd_remarks = event.target.value
        setTablerow(input)
    }

    const addCode = (index) => {
        setSelectedindex(index)
        getAllaccountlist()
        setAllmodal(true)
    }

    useEffect(() => {
        getAllaccountlist()
    }, [])

    const getAllaccountlist = () => [
        fetch(global.url + "allTransctionList", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("list response", responseJson)
              setAllaccountlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
    ]

    const onSave = () =>{   
        var paymentdate;
        var refdate;
        if(editmode == true) {
            paymentdate = registerDate.split('T')[0]
            refdate = refDate.split('T')[0]
        }
        else{
            paymentdate = registerDate.toISOString().split('T')[0]
            refdate = refDate.toISOString().split('T')[0]
        } 
        setBtnloader(true)
        const data = {
            "reciept_id": receiptvoucherId,
            "reciept_user_id":userId,
            "invoice_no": invoice,
            "reference_no": refno,
            "reciept_date": paymentdate,
            "reciept_bill_type": billType,
            "payment_mode": paymode,
            "reciept_remarks": remarks,
            "reciept_table": tableRow,
            "reciept_code": ledgercode,
            "reciept_name": ledgername,
            "reciept_account_id_fk": ledgerid,
            "reciept_branchid" : userbranchid,
            "reciept_companyid" : usercompanyid,
            "rvd_account_code" : ledgercode
        }
        console.log("receipt data", data)
        fetch(global.url + "updateReciept", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            if(responseJson.error == true) {
                alert(responseJson.message)
                setBtnloader(false)
            }
            else{
                alert(responseJson.message)
                window.location.reload();
                setBtnloader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getBill = () => {
        setAgainstmodal(true)
        console.log("paymentaccid", paymentaccid)
        const data = {
            "against_id": paymentaccid,
            "type": "customer",
        }
        console.log("invoice_no", data)
        fetch(global.url + "againstReciept", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("bill details", responseJson)
            if(responseJson.error == false){
                setSaleslist(responseJson.data)
            }
            else{
                alert("No Record Found")
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const billSelect = (total, id, bill) => {
        setPaymentamount(total)
        setSalesid(id)
        setBillinvoice(bill)
        setAgainstmodal(false)
    }
    
    const upClick = () => {
        setInvoice(preCount => preCount + 1)
    }

    const downClick = () => {
        if(invoice > 1){
            setInvoice(preCount => preCount - 1)
        }
    }
    const purchaseDetails = () => {
        getPurchaseDetails()
    }
    const getPurchaseDetails = () => {
        var data = {
            'invoice_no' : invoice
          }
          console.log("data", data)
          fetch(global.url + "RecieptdetailByInvoiceno", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson) => {
            //   console.log("responseJson invoice", responseJson)
              if(responseJson.error == false){
                setBilltype(responseJson.data.billtype)
                setRefno(responseJson.data.refno)
                setRefdate(responseJson.data.refdate)
                setRegisterDate(responseJson.data.date)
                setPaymode(responseJson.data.paymode)
                setLedgercode(responseJson.data.code)
                setLedgername(responseJson.data.account)
                if(responseJson.data.details){
                    setTablerow(responseJson.data.details)
                }
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const valueEdit = (id, index) => {
        var input  = [...tableRow]
        input[index].rvd_amount = id
        setTablerow(input)
    }

    const bankChange =(event) => {
        setBank(event.target.value)
        
        const data = {
            "bankmasterid": event.target.value,
          }
          fetch(global.url + "accountByBankMaster", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("respone", responseJson)
              setLedgerid(responseJson.data.bankmaster_ledgername)
              setLedgercode(responseJson.data.bankmaster_bankcode)
              setLedgername(responseJson.data.bankmaster_bankcode)
              getBalance(responseJson.data.bankmaster_ledgername)
          })
          .catch((error)=>{
              console.log(error)
              
          })
      }

      const getBalance = (id) => {
        const data = {
            "ledgerid": id,
          }
          fetch(global.url + "accountBalanceByBank", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            //   console.log("balance response", responseJson)
              setAccbalance(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
              
          })
      }

      const pdcamountChange = (e) => {
        setPdcamount(e.target.value)
        setPdcamt(e.target.value)
    }

    useEffect(() => {
        seleteAccount()
    }, [])

    const seleteAccount = () => {
        fetch(global.url + "SelectAccount",{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
        
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log('response search', responseJson)
            if(responseJson.error == false){
                setFilteredData(responseJson.data)
            } else {
                setFilteredData([])
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }


  return (
        <div className="main-content">
            <div style={{background: '#6d37b9', width: '100%', padding: 10, display: 'flex'}}>
                <img src={require("../../../assets/images/icons/payment-voucher.svg").default} />
                <p style={{marginBottom: 'unset', color: '#fff', fontWeight: 'bold', marginLeft: 10}}>Reciept Voucher</p>
            </div>
            <Row>
                <Col sm={12}>
                    <Row>
                        {/* <Col sm={1} style={{marginTop:30}}>
                            <p className="label">Bill Type</p>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bill Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={billType}
                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {billtypelist.map((row, index) => {
                                return (
                                    <MenuItem value={row.billset_id}>{row.billset_formtype}</MenuItem>
                                )})}
                                    </Select>
                            </FormControl>
                        </Col> */}
                        <Col md={1} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={invoice} onChange={(e) => setInvoice(e.target.value)} id="outlined-basic" label="SL No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={1}>
                            <div style={{marginTop: 22}}>
                            {invoNum == invoice ?
                                <Button style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>:
                                <Button onClick={upClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleUp  color="White" fontSize={15}  /></Button>}
                                <Button onClick={downClick} style={{backgroundColor: '#6d37b9', padding: 0}} variant="contained"><FaAngleDown  color="White" fontSize={15} /></Button>
                            </div>
                        </Col>
                        <Col md={1}>
                            <Button onClick={purchaseDetails} style={{marginLeft: -26, marginTop: 28, backgroundColor: '#6d37b9', border: 'none', color: '#fff', height: 30}} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button>
                        </Col>
                        <Col md={2} style={{marginTop: 30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    size="small"
                                        label="Date"
                                        value={registerDate}
                                        onChange={(newValue) => {
                                        setRegisterDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        {/* <Col md={2} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Pay Mode</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={paymode}
                                        label="Age"
                                        onChange={paymodeChange}
                                        size="small"   
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                        <MenuItem value={"Cash"}>Cash Account</MenuItem>
                                        <MenuItem value={"Bank"}>Bank Account</MenuItem>
                                    </Select>
                            </FormControl>
                        </Col> */}
                        <Col md={5}>
                        {paymode == "Bank" ? 
                            <Row>
                                    <Col md={4} style={{marginTop:30}}>
                                    <FormControl sx={{  width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Bank Account</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={bank}
                                                label="Age"
                                                onChange={bankChange}
                                                size="small"  
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {banklist.map((row, index) => {
                                                return (
                                                        <MenuItem value={row.bankmaster_id}>{row.bankmaster_bankcode}</MenuItem>
                                                )})
                                            }
                                            </Select>
                                    </FormControl>
                                    <p style={{color: 'red'}}>Balance: {accbalance}</p>
                                </Col>
                                <Col md={6} style={{marginTop:30}}>
                                    <FormControl component="fieldset">
                                        <TextField autoComplete='off' type="number"  onChange={(e) => pdcamountChange(e)} value={pdcamount} id="outlined-basic" label="Amount" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>:
                            <Row>
                                {/* <Col sm={3} style={{marginTop:30}}>
                                    <FormControl component="fieldset">
                                        <TextField  disabled value={ledgercode} id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col> */}
                                <Col md={8} style={{marginTop:30}}>
                                    {/* <FormControl onClick={selectAccount} style={{width: '100%'}} component="fieldset">
                                        <TextField  disabled value={ledgername} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                                    </FormControl> */}
                                    <Typeahead
                                        style={{width: '100%'}}
                                        id="basic-example"
                                        onChange={(e) => selectChange(e)}
                                        options={filteredData}
                                        placeholder="Account"
                                        selected={selected}
                                    />
                                </Col>
                                <Col md={4} style={{marginTop:30}}>
                                    <FormControl component="fieldset">
                                        <TextField autoComplete='off' id="outlined-basic" label="Amount" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                    {paymode == "Bank" ? 
                    <Row>
                            <div>
                                <Row>
                                    <Col sm={2} style={{marginTop:10}}>
                                        <FormControl sx={{  width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Transaction Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={bpaymode}
                                                    label="Age"
                                                    onChange={bpaymodeChange}
                                                    size="small"  
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                    <MenuItem value={"transfer"}>Transfer</MenuItem>
                                                    <MenuItem value={"pdc"}>PDC</MenuItem>
                                                </Select>
                                        </FormControl>
                                    </Col>
                                    { bpaymode == "pdc" ? 
                                    <Col md={2} style={{marginTop:10}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="PDC Issue Date"
                                                        value={entrydate}
                                                        onChange={(newValue) => {
                                                        setEntrydate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>:
                                    <Col md={2} style={{marginTop:10}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Issue Date"
                                                        value={entrydate}
                                                        onChange={(newValue) => {
                                                        setEntrydate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>}
                                    <Col md={2} style={{marginTop:10}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Instrument Date"
                                                        value={postdate}
                                                        onChange={(newValue) => {
                                                        setPostdate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:10}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'  onChange={(e) => setChequeno(e.target.value)} value={chequeno} id="outlined-basic" label="Instrument No." variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:10}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'   id="outlined-basic" value={pdcamt} onChange={(e) => pdcamtChange(e)} label="Amount" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:10}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'   id="outlined-basic" label="PDC Note" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                </Row>
                                { parseFloat(pdcamount) > parseFloat(pdcamt) ?
                                <Row style={{marginTop: 20}}>
                                    <Col sm={2} style={{marginTop:20}}>
                                        <FormControl sx={{  width: '100%' }}>
                                                <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Transaction Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={bpaymode}
                                                    label="Age"
                                                    onChange={bpaymodeChange}
                                                    size="small"  
                                                >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                    <MenuItem value={"transfer"}>Transfer</MenuItem>
                                                    <MenuItem value={"pdc"}>PDC</MenuItem>
                                                </Select>
                                        </FormControl>
                                    </Col>
                                    { bpaymode == "pdc" ? 
                                    <Col md={2} style={{marginTop:20}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="PDC Issue Date"
                                                        value={entrydate}
                                                        onChange={(newValue) => {
                                                        setEntrydate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>:
                                    <Col md={2} style={{marginTop:20}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Issue Date"
                                                        value={entrydate}
                                                        onChange={(newValue) => {
                                                        setEntrydate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>}
                                    <Col md={2} style={{marginTop:20}}>
                                        <FormControl sx={{  width: '100%' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                    size="small"
                                                        label="Instrument Date"
                                                        value={postdate}
                                                        onChange={(newValue) => {
                                                        setPostdate(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:20}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'  onChange={(e) => setChequeno(e.target.value)} value={chequeno} id="outlined-basic" label="Instrument No." variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:20}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'   id="outlined-basic" value={pdcamt2} onChange={(e) => pdcamtChange2(e)}  label="Amount" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                    <Col sm={2} style={{marginTop:20}}>
                                        <FormControl component="fieldset">
                                            <TextField autoComplete='off'   id="outlined-basic" label="PDC Note" variant="outlined"  size="small"/>
                                        </FormControl>
                                    </Col>
                                </Row>: 
                                null}
                            </div>
                    </Row>: 
                    null
                }
                    {/* <Row>
                        <Col sm={1} style={{marginTop:30}}>
                            <p className="label">Ref No</p>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <TextField value={refno}  onChange={(e) => setRefno(e.target.value)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={1} style={{marginTop:30}}>
                            <p className="label">Ref Date</p>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Date"
                                            value={refDate}
                                            onChange={(newValue) => {
                                            setRefdate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        
                    </Row> */}
                    {/* <Row>
                        <Col sm={2} style={{marginTop:30}}>
                            <FormControl component="fieldset">
                                <TextField  disabled value={ledgercode} id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={2} style={{marginTop:30}}>
                            <FormControl component="fieldset">
                                <TextField  disabled value={ledgername} id="outlined-basic" label="Account" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <Button onClick={selectAccount} variant="outlined">SELECT ACCOUNT</Button>
                        </Col>
                     </Row> */}
                </Col>
            </Row>
            {/* <Row style={{marginTop: 10}}>
                {salesid != "" ? 
                    <p style={{fontSize: 10, color: 'green'}}>Against bill - {billinvoice}</p> : null
                    }
            </Row>
            <Row style={{marginTop: 20}}>
                <Col sm={2} style={{marginTop:20}}>
                    <FormControl component="fieldset">
                        <TextField onClick={addCode} value={paymentcode} disabled id="outlined-basic" label="Code" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={3} style={{marginTop:20}}>
                    <FormControl component="fieldset" style={{width: '100%'}}>
                        <TextField id="outlined-basic"  disabled value={paymentname} label="Account" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={2} style={{marginTop:20}}>
                    <FormControl component="fieldset">
                        <TextField id="outlined-basic" type="number" value={paymentamount} onChange={(e) => setPaymentamount(e.target.value)} label="Amount" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>
                <Col sm={2} style={{marginTop:20}}>
                    <Button onClick={getBill} variant="outlined">Against bill</Button>
                </Col>
                <Col sm={2} style={{marginTop:20}}>
                    <Button onClick={addRow} variant="outlined">ADD ACCOUNT</Button>
                </Col>
            </Row> */}
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    {/* <Table bordered>
                        <thead>
                            <tr>
                            <th>SlNo</th>
                            <th style={{width: '20%'}}>Account Code</th>
                            <th style={{width: '50%'}}>Account</th>
                            <th>Amount</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((row, index) => {
                                return (
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{row.rvd_account_code}</td>
                                        <td>{row.pay_account_name}</td>
                                        <td><TextField id="outlined-basic" type="number" value={row.rvd_amount} onChange={(e) => valueEdit(e.target.value, index)} variant="outlined"  size="small"/></td>
                                        <td >
                                            <a href="javascript:void(0)" onClick={() => deleteClick(index, row.rvd_id, row.reciept_invoice_no)}>
                                                <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                            </a>
                                        </td>
                                    </tr>
                                )}
                                )}
                            <tr>
                                <td colSpan="3" style={{textAlign: 'right', fontWeight: 'bold'}}>Total</td>
                                <td>{totalAmount}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table> */}
                    <Table bordered>
                        <thead>
                            <tr>
                                {/* <th style={{width: 100}}>SlNo</th> */}
                                {/* <th style={{width: 150}}>Code</th> */}
                                <th style={{width: "0%"}}>Account</th>
                                <th style={{width: '0%'}}>Amount</th>
                                <th style={{width: '0%'}}>Transaction Type</th>
                                <th style={{width: '0%'}}>Narration</th>
                                {/* <th style={{width: "0%"}}></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow.map((item, index) => {
                                return (
                                    <tr>
                                        {/* <td>{index+1}</td> */}
                                        {/* <td>
                                            <div onClick={() => addCode(index)} className='contrabox'>
                                                {item.reciept_code}
                                            </div>
                                        </td> */}
                                        <td>
                                            {/* <div className='contrabox'>{item.pay_account_name}</div> */}
                                            {/* <input style={{width: 200}} autocomplete="off"  type="number" disabled value={item.pay_account_name} /> */}
                                            <Typeahead
                                                style={{width: 220, boxShadow: 'none'}}
                                                id="basic-example"
                                                onChange={(e) => accountChange(e, index)}
                                                options={allaccountlist}
                                            />
                                        </td>
                                        <td>
                                            <div style={{display: 'flex'}}>
                                            <input autocomplete="off"  type="number" value={item.rvd_amount} onChange={(event) => debitChange(event, index)} />
                                                {/* <Button onClick={modalClick} >< RiBillLine color="#000" fontSize={20} style={{marginRight: 10}} /></Button> */}
                                                {/* <Button onClick={getBill} >< RiBillLine color="#6d37b9" fontSize={20} /></Button> */}
                                            </div>
                                        
                                        </td>
                                        <td style={{width :0}}>
                                            {/* <input autocomplete="off"  type="number" disabled = {item.creditdisabled == true? true: false} value={item.jd_credit == 0 ? null : item.jd_credit} onChange={(event) => creditChange(event, index)} /> */}
                                            
                                            <div style={{display: 'flex'}}>
                                                <FormControl sx={{  width: 200 }}>
                                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Trasaction Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-helper-label"
                                                        id="demo-simple-select-helper"
                                                        value="other"
                                                        label="Age"
                                                        onChange={paymodeChange}
                                                        size="small"  
                                                    >
                                                    {/* <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem> */}
                                                        {/* <MenuItem value={"transfer"}>Transfer</MenuItem>
                                                        <MenuItem value={"pdc"}>PDC</MenuItem>
                                                        <MenuItem value={"cdc"}>CDC</MenuItem> */}
                                                        <MenuItem value={"other"}>Other</MenuItem>
                                                
                                                    </Select>
                                                </FormControl>
                                                {/* <Button onClick={modalClick2} >< RiBillLine color="#000" fontSize={20} style={{marginRight: 10}} /></Button> */}
                                            </div>
                                        </td>
                                        <td><input autocomplete="off" style={{width: 250}}  type="text" value={item.jd_narration} onChange={(event) => narrationChange(event, index)} /></td>
                                        {/* <td>
                                            <a href="javascript:void(0)">
                                                <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                            </a>
                                        </td> */}
                                    </tr>
                                )
                            })}
                            {/* <tr>
                                <td colSpan={3}>Total</td>
                                <td style={{textAlign: 'right'}}>{parseFloat(debitTotal)?.toFixed(2)}</td>
                                <td style={{textAlign: 'right'}}>{parseFloat(creditTotal)?.toFixed(2)}</td>
                                <td></td>
                                <td></td>
                            </tr> */}
                            <tr>
                                <td >Total</td>
                                <td>
                                <input autocomplete="off"  type="number" disabled value={parseFloat(debitTotal)?.toFixed(2)}/>
                                </td>
                                <td style={{textAlign: 'right'}}>
                                    {/* {parseFloat(creditTotal)?.toFixed(2)} */}
                                </td>
                                <td> 
                                    <ul className="list-inline" style={{marginBottom: 'unset'}}>
                                        <li>
                                        {
                                            btnloader ? 
                                            <Loader2
                                                type="Oval"
                                                color="#38B0DE"
                                                height={30}
                                                width={30}
                                                style={{float: 'left', marginTop:20}}
                                            />:
                                            <Button onClick={onSave} style={{height: 25, fontSize: 12}} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button>}
                                        </li>
                                        <li>
                                            <Button onClick={addRow} style={{height: 25, fontSize: 12}} variant="outlined">Add</Button>
                                        </li>
                                    </ul>
                                </td>
                                {/* <td></td> */}
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {/* <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <TextareaAutosize 
                    value={remarks}  onChange={(e) => setRemarks(e.target.value)}
                        minRows={3}
                        placeholder="Remarks"
                        style={{background: 'transparent', width: '100%'}}
                    />
                </Col>
            </Row> */}
            
            {/* <Row style={{marginTop: 20}}>
                <Col xs={12} >
                    <ul className="list-inline">
                        <li>
                        {
                            btnloader ? 
                            <Loader2
                                type="Oval"
                                color="#38B0DE"
                                height={30}
                                width={30}
                                style={{float: 'left'}}
                            />:
                            <Button onClick={onSave} variant="outlined"><IconName.BsDownload style={{marginRight: 10}}/>Save</Button>}
                        </li>
                        <li><Button onClick={purchaseDetails} variant="outlined"><GI.GiBinoculars style={{marginRight: 10}}/>Find</Button></li>
                        <li><Button variant="outlined"><BI.BiPrinter style={{marginRight: 10}}/>Print</Button></li>
                        <li><Button variant="outlined"><RI.RiDeleteBin6Line style={{marginRight: 10}}/>Delete</Button></li>
                        <li><Button variant="outlined"><RI.RiCloseFill style={{marginRight: 10}}/>Close</Button></li>
                    </ul>
                </Col>
            </Row> */}

            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {accountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() =>selectedAcc(row.ledger_name, row.ledger_code, row.ledger_id)}>
                                        <li className="pay-lists" style={{color: '#000'}}>{row.ledger_name}</li>
                                    </a>
                                )}
                                )
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={allmodal}
                onHide={() => setAllmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {allaccountlist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => tableselectedAccount(selectedIndex, row.common_code, row.common_name, row.common_id, row.type)}>
                                        <li className="pay-lists" style={{color: '#000'}}>{row.common_name} - {row.type}</li>
                                    </a>
                                )})
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={againstmodal}
                onHide={() => setAgainstmodal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                     Select Bill
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <ul>
                            {saleslist.map((row, index) => {
                                return (
                                    <a style={{textDecoration: 'none', listStyle: 'none'}} href="javascript:void(0)" onClick={() => billSelect(row.sales_total, row.sales_id, row.sales_invoice)}>
                                        <li className="pay-lists" style={{color: '#000',  marginTop: 5, fontSize: 15, cursor: 'pointer'}}>{row.sales_invoice} - {row.sales_date} - {row.sales_total}</li>
                                    </a>
                                )}
                                )
                                }
                            </ul>
                        </Col>
                        
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={shownw}
                onHide={() => setShownw(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Inv Date</th>
                                        <th>Invoice Number</th>
                                        <th>Amount</th>
                                        <th>Paid Amt</th>
                                        <th>Balance</th>
                                        <th>Adjust</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} style={{textAlign: 'right'}}>Total Adjust Amount</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={detls}
                onHide={() => setDetls(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <FormControl component="fieldset">
                                <TextField id="outlined-basic" type="number" label="Instrument Num" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col md={4}>
                            <FormControl sx={{  width: '100%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                        size="small"
                                            label="Instrument Date"
                                            value={instrudate}
                                            onChange={(newValue) => {
                                                setInstrudate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Col>
                        <Col md={4}>
                            <FormControl component="fieldset">
                                <TextField id="outlined-basic" disabled type="number" label="Instrument Note" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button style={{backgroundColor: '#6d37b9', float: 'right', color: '#fff', marginTop: 20}} variant="primary">Save</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
  );
}

export default ReceiptVoucher;
