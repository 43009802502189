import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'Category', minWidth: 50,span: false },
    { id: '2', label: 'AG code', minWidth: 50,span: false },
    { id: '2', label: 'Sub Category', minWidth: 50,span: false },
    { id: '3', label: 'Descripton', minWidth: 50,span: false },
    { id: '5', label: 'Blocked', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function State(props) {
  const [btnloader, setBtnloader] = React.useState(false);
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")

    const [modal, setModal] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [userId, setUserid] = React.useState("");
    const [subcategoryId, setSubcategoryid] = React.useState("");
    const [subcategoryName, setSubcategoryname] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [blocked, setBlocked] = React.useState(0);
    const [category, setCategory] = React.useState("");
    const [categoryList, setCategorylist] = React.useState([]);

    const [image, setImage] = React.useState();
    const [imageurl, setImageurl] = React.useState("");
    const [editmode, setEditmode] = React.useState(false);
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)

    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewSubcategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          setLoader(false)
          if(responseJson.status == "false") {
            setSource(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const editClick = (
    subcategoryid, 
    subcategoryname,
    category,
    description,
    blocked,
    subcategoryimage
    ) => {
      setModal(true)
      setEditmode(true)
      setSubcategoryid(subcategoryid)
      setSubcategoryname(subcategoryname)
      setDescription(description)
      setBlocked(blocked)
      setCategory(category)
      setImageurl(subcategoryimage)
      getCategory()
  }

  const onAdd = () => {
      setModal(true)
      setEditmode(false)
      setSubcategoryid("")
      setSubcategoryname("")
      setDescription("")
      setBlocked(0)
      setCategory("")
      setImageurl("")
      getCategory()
  }

  const categoryChange = (event) => {
    setCategory(event.target.value);
  };

  const getCategory = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewCategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "true"){
          setCategorylist([])
        }
        else{
          setCategorylist(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onSave = () => {
    setBtnloader(true)
    console.log("save")
    var data = {
      "subCategory_id": subcategoryId,
      "subCategory_category": category,
      "subCategory_name": subcategoryName,
      "subCategory_description": description,
      "subCategory_blocked": blocked,
      "subCategory_userid": userId,
      "subCategory_branchid" : userbranchid,
      "subCategory_companyid" : usercompanyid
    }
    fetch(global.url + "updateSubcategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("responsejson", responseJson);
        if(responseJson.error == false) {
          getData()
          setModal(false)
          alert(responseJson.message)
          setBtnloader(false)
        }else {
          alert(responseJson.message)
          setBtnloader(false)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const deleteClick = (id) =>  {
    var data = {
      "subCategory_id": id,
    }
    fetch(global.url + "deleteSubcategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert("Deleted")
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const deleteImage = () => {
    var data = {
      "subCategory_id": subcategoryId,
    }
    fetch(global.url + "deleteImageSubcategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        if(responseJson.error == false){
          alert(responseJson.message)
          setImageurl("")
        }
        else{

        }
        console.log("delete image response", responseJson)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const uploadImage = (e) => {
    console.log("files", e.target.files[0])
    setImage(e.target.files[0])
  }

  const addImage = () => {

    const formData = new FormData();
    formData.append('subCategory_image', image);
    formData.append('subCategory_id', subcategoryId);
    console.log("subCategory_id", subcategoryId)
    console.log("subCategory_image", image)
    fetch(global.url + "addImageSubcategory", {
        method: "POST",
        body: formData,
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("save image response", responseJson)
        if(responseJson.error == false){
           alert(responseJson.message)
           setImageurl(responseJson.data)
        }
        else{
          alert(responseJson.message)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }


    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sub Category 1</p>
               {
                  usertype == "superadmin" ? 
                  null : 
                  <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.category_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.subCategory_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.subCategory_name}
                              </TableCell>
                              <TableCell >
                                  {row.subCategory_description}
                              </TableCell>
                              <TableCell >
                                  {row.subCategory_blocked == "0" ? "No" : "Yes"}
                              </TableCell>
                              <TableCell >
                              {
                                  usertype == "superadmin" ? 
                                  null : 
                                  <a href="javascript:void(0)" onClick={() => 
                                    editClick(row.subCategory_id, 
                                      row.subCategory_name, 
                                      row.subCategory_category, 
                                      row.subCategory_description, 
                                      row.subCategory_blocked, 
                                      row.subCategory_image
                                    )}>
                                      <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                                }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.subCategory_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Sub Category Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={category}
                              label="Age"
                              onChange={categoryChange}
                              size="small"
                              
                            >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {categoryList.map((item, index) => (
                                <MenuItem value={item.category_id}>{item.category_name}</MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField  onChange={(e) => setSubcategoryname(e.target.value)} value={subcategoryName} id="outlined-basic" label="Sub Category" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField  onChange={(e) => setDescription(e.target.value)} value={description} id="outlined-basic" label="Description" variant="outlined"  size="small" />
                        </FormControl>
                    </Col>

                    {
                      editmode && imageurl != "" &&  imageurl != null ? 
                      <Col xs={12} style={{marginTop: 10}}>
                      
                        <img src={global.images + "ecommerce_photos/" + imageurl} style={{width: 60, height: 60}} /> 
                        <Button onClick={deleteImage}  variant="contained">Delete</Button>
                      </Col> : editmode &&  (imageurl == "" || imageurl == null) ?
                      <Col xs={12}>
                          <label>Image</label>
                          <input type="file" onChange={(e) => uploadImage(e)}  style={{marginTop: 10}}/>
                          <Button onClick={addImage}  variant="contained">Add Image</Button>
                      </Col> :
                      <Col xs={12}>
                          <label>Image</label>
                          <input type="file" onChange={(e) => uploadImage(e)}  style={{marginTop: 10}}/>
                      </Col>
                    }
          
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox checked={blocked == 0? false : true} onChange={e => checkChange(e)} />
                                } label="Blocked" />
                          </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      {/* <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button> */}
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default State;