import React, {useState, useEffect, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactToPrint from 'react-to-print';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Multipleprint from './multiplePrint'

const columns = [
    { id: '1', label: 'Sl No#', minWidth: 50,span: false },
    { id: '1', label: 'Invoice', minWidth: 50,span: false },
    { id: '2', label: 'Location', minWidth: 50,span: false },
    { id: '3', label: 'Building', minWidth: 50,span: false },
    { id: '4', label: 'Street', minWidth: 50,span: false },
    { id: '5', label: 'Zone', minWidth: 50,span: false },   
    { id: '6', label: 'Mobile', minWidth: 50,span: false },
    { id: '7', label: 'Rate', minWidth: 50,span: false },
    { id: '8', label: 'Item, Colour, Size, Quantity', minWidth: 50,span: false },  
    // { id: '9', label: 'Description', minWidth: 50,span: false },
    // { id: '10', label: 'Deliveryboy', minWidth: 50,span: false },
    { id: '11', label: 'Remarks', minWidth: 50,span: false },  
    { id: '7', label: 'Order Time', minWidth: 50,span: false },
  ];

  

  function DeliveryExecutive({branchid}) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")
    const [source, setSource] = React.useState([]);
    const [total, setTotal] = React.useState()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dateFrom, setDatefrom] = React.useState(new Date);
    const [dateTo, setDateTo] = React.useState(new Date);
    const [starttime, setStarttime] = React.useState(new Date);
    const [enddtime, setEndtime] = React.useState(new Date);
    const [loader, setLoader] = React.useState(false);
    const [salesmanid, setSalesmanid] = useState("")
    const [userlist, setUserlist] = useState([])
    const [combo, setCombo] = useState([])
    const [comboview, setComboview] = useState(false)
    const [invoiceFrom, setInvoiceFrom] = React.useState('');
    const [invoiceTo, setInvoiceTo] = React.useState('');
    const [array, setArray] = React.useState([])

    const [modal, setModal] =  useState(false)
    const [printarray, setPrintarray] =  useState([])
    

    var today = new Date();
    var year = today.getFullYear();
    var month = (today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : (today.getMonth()+1);
    var date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    var hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    var minutes = today.getMinutes() < 10 ? '0' + today.getMinutes(): today.getMinutes();

    const componentRef = useRef();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    useEffect(() => {
        getData()
        getUser()
    }, [])

    const userChange = (event) => {
        setSalesmanid(event.target.value)
        getData1(event.target.value)   
    }
    const startChange = (newValue) => {
        setStarttime(newValue);
      };
    
      const endChange = (newValue) => {
        setEndtime(newValue);
      };
      
    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
    
        
        var data = {
          "companyid": company,
          "branchid" : branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("user response", responseJson)
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        setLoader(true)
        var data = {
          "type" :  userdata.user_type,
          "companyid" : company,
          "branchid" : branch,
          "deliver_boy" : salesmanid,
          "orderfrom" : dateFrom.toISOString().split('T')[0],
          "orderto" : dateTo.toISOString().split('T')[0],
          "salesorder_fromtime": new Date(starttime.toISOString()).toLocaleTimeString('en',
                              { timeStyle: 'short', hour12: false, }),
          "salesorder_totime": new Date(enddtime.toISOString()).toLocaleTimeString('en',
                            { timeStyle: 'short', hour12: false, }),
            "invoice_from" : invoiceFrom,
            "invoice_to" : invoiceTo
        }

        console.log('set data', data)
        fetch(global.url + "DeliveryBoyReportUpdate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response ledger", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                  console.log('respon', responseJson.data.notcombo)
                setSource(responseJson.data)
                // setCombo(responseJson.data.sales_combo[0])
                setComboview(true)
              }else{
                setSource([])
                setComboview(false)
                setCombo([])
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })

    }

    const getData1 = (id) => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        setLoader(true)
        var data = {
          "type" :  userdata.user_type,
          "companyid" : company,
          "deliver_boy" : id,
          "branchid" : branch,
        }

        console.log('set data', data)
        fetch(global.url + "DeliveryBoyReportUpdate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response ledger", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                  console.log('respon', responseJson.data.notcombo)
                setSource(responseJson.data)
                // setCombo(responseJson.data.sales_combo[0])
                setComboview(true)
              }else{
                setSource([])
                setComboview(false)
                setCombo([])
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })

    }

    const filter = () => {
        getData();
    }

    const filter2 = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        setLoader(true)
        var data = {
            "type" :  userdata.user_type,
            "companyid" : company,
            "branchid" : branch,
            "deliver_boy" : salesmanid,
            "invoice_from" : invoiceFrom,
            "invoice_to" : invoiceTo
        }

        console.log('set data', data)
        fetch(global.url + "DeliveryBoyReportUpdate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response ledger", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                  console.log('respon', responseJson.data.notcombo)
                setSource(responseJson.data)
                // setCombo(responseJson.data.sales_combo[0])
                setComboview(true)
              }else{
                setSource([])
                setComboview(false)
                setCombo([])
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const onPrint = () => {
            window.print()
    }

    

    const printout = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var company = JSON.parse(localStorage.getItem("company"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        setLoader(true)
        var data = {
            "type" :  userdata.user_type,
            "companyid" : company,
            "branchid" : branch,
            "deliver_boy" : salesmanid,
            "invoice_from" : invoiceFrom,
            "invoice_to" : invoiceTo
        }

        console.log('set data', data)
        fetch(global.url + "DeliveryBoyReportUpdate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response ledger", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                  console.log('respon', responseJson.data.notcombo)
                setSource(responseJson.data)
                // setCombo(responseJson.data.sales_combo[0])
                setComboview(true)
              }else{
                setSource([])
                setComboview(false)
                setCombo([])
               
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const invoiceClick = () => {
        if(array.length != 0) {
            setModal(true)
            var userdata = JSON.parse(localStorage.getItem("user"))
            var branch = JSON.parse(localStorage.getItem("branch"))
            var company = JSON.parse(localStorage.getItem("company"))
            setUsercompanyid(company)
            setUserbranchid(branch)
            setUsertype(userdata.user_type)

            var data = {
                "companyid": company,
                "salesorderid": array,
                "type": userdata.user_type
            }
            
            fetch(global.url + "viewSalesOrderDetail_multiple", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("print details", responseJson)
                setPrintarray(responseJson.data)

            })
            .catch((error)=>{
                console.log(error)
            })
        }
        else{
            alert("Atleast check 1 order")
        }
        
    }

    const parentCallback = () => {
        setModal(false)
    }

    const checkall = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input = [...source]
            var input1 = [...array]
            for(var i = 0; i<input.length ; i++) {
                input[i].checked = true
                input1.push(input[i].salesorder_id)
            }
            setSource(input)
            setArray(input1)
        }
        else{
            var input = [...source]
            for(var i = 0; i<input.length ; i++) {
                input[i].checked = false
               
            }
            setSource(input)
            setArray([])
        }
    }

    const blockChange = (e,id, index) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input.push(id)
            console.log("input", input)
            setArray(input)

            var mainarray = [...source]
            mainarray[index].checked = isChecked
            setSource(mainarray)
        }
        else{

            var testArr = [1, 2, 3, 4, 5];
            const val = 3; // Value to remove

            for (let i = 0; i < array.length; i++) {
                if (array[i] === id) {
                    const removedElements = array.splice(i, 1);
                    console.log(removedElements); // Outputs [3]
                    i--; // Since the indexes of elements following this index get updated after removal
                }
            }

            var mainarray = [...source]
            mainarray[index].checked = isChecked
            setSource(mainarray)
        }
        console.log("array",  array)
    }

      return(
        <div className="main-content">
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2 }}>
            <div style={{margin: 20, marginBottom: 0, marginTop: 0}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Delivery Executive Report</p>
                    <Row>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    value={dateFrom}
                                    onChange={(newValue) => {
                                        setDatefrom(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={dateTo}
                                    onChange={(newValue) => {
                                        setDateTo(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                  label="Start Time Shift"
                                  value={starttime}
                                  onChange={startChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                          </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 25}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="End Time Shift"
                                value={enddtime}
                                onChange={endChange}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                        </Col>
                        
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <Button onClick={filter}  style={{width: '50%'}} variant="contained">Filter</Button>
                        </Col>
                    </Row>
                    <Row>
                    <Col xs={12} md={2}>
                         <FormControl sx={{ mt: 3, width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={salesmanid}
                                            label="Age"
                                            onChange={userChange}
                                        size="small"
                                        >
                                        <MenuItem value="">
                                        <em>None</em>
                                        </MenuItem>
                                        {userlist.map((row, index) => {
                                            return (
                                            <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                        )})}
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setInvoiceFrom(e.target.value)} value={invoiceFrom} placeholder="Invoice From" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setInvoiceTo(e.target.value)} value={invoiceTo} placeholder="Invoice To" id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4} style={{marginTop: 15}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Button onClick={filter2}  variant="contained">Filter</Button>
                                <ReactToPrint
                                    trigger={() => <Button style={{padding: 5, fontSize: 15}}  variant="contained">Print!</Button>}
                                    content={() => componentRef.current}
                                />
                                <Button onClick={() => invoiceClick()} style={{padding: 5, fontSize: 15}}  variant="contained">Invoice Print!</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{margin: 20, marginTop: 5}}>
                {/* {loader ? <Loader /> : */}
                    <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename={source.length == 0 ? "tablexls" : salesmanid == "" ? "All Delivery boy" : source[0].deliver_boy}
                        sheet={source.length == 0 ? "tablexls" : salesmanid == "" ? "All Delivery boy" : source[0].deliver_boy}
                        buttonText="Download as XLS"/>
                        <Table id="table-to-xls" className='section-to-print1' stickyHeader aria-label="sticky table" ref={componentRef}>
                        <caption className='captiontable1'>{date}/{month}/{year} - Delivery boy: {source.length == 0 ? "" : salesmanid == "" ? "All Delivery boy" : source[0].deliver_boy}  </caption>
                            <TableHead className="tableheaderexe1">
                                <TableRow>
                                    <TableCell className='tablecellPrint1 nondisplay' style={{fontWeight: 'bold'}}>
                                        Check
                                        <FormControl sx={{  width: '100%' }}>
                                                <FormControlLabel control={
                                                <Checkbox onChange={e => checkall(e)} />
                                                } label="" />
                                            </FormControl>
                                    </TableCell>
                                    
                                    <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Sl No#
                                    </TableCell>
                                    <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Invoice
                                    </TableCell>
                                    <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Location
                                    </TableCell>
                                    <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Building
                                    </TableCell>

                                    <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Street
                                    </TableCell>
                                    <TableCell  className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Zone
                                    </TableCell>
                                    <TableCell  className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Mobile
                                    </TableCell>
                                    <TableCell  className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Rate
                                    </TableCell>
                                    <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Item, Colour, Size, Quantity
                                    </TableCell>
                                    <TableCell className='tablecellPrint1' style={{fontWeight: 'bold',width: '10%'}}>
                                    Remarks
                                    </TableCell>
                                    {/* <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                    Order Time
                                    </TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                            source.map((row, index) => {
                                return (
                                    <TableRow className='tablerowPrint1'  tabIndex={-1} key={row.code}>
                                        <TableCell className='tablecellPrint1 nondisplay'>
                                            <FormControl sx={{  width: '100%' }}>
                                                <FormControlLabel control={
                                                <Checkbox checked={row.checked == true ?  true : false} onChange={e => blockChange(e, row.salesorder_id, index)} />
                                                } label="" />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {index+1}
                                        </TableCell>
                                        <TableCell className='tablecellPrint1'  style={{fontWeight: 'bold'}}>
                                        {row.salesorder_invoice}
                                        </TableCell>
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.location}
                                        </TableCell>
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.building}
                                        </TableCell>
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.street}
                                        </TableCell>
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.zone}
                                        </TableCell>
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.customer_mobile}
                                        </TableCell>
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.salesorder_total}
                                        </TableCell>
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.notcombo.map((i, k) => {
                                            return(
                                                <ul >
                                                    <li>
                                                    {i.product_refcode}-[{i.color_name} - {i.size_name} - {i.sod_qty}]
                                                    </li>
                                                    
                                                </ul>
                                            )
                                        })}
                                        {row.combo.map((i, k) => {
                                            return(
                                                <ul>
                                                    <li>
                                                    {i.product_refcode} - 
                                                    [
                                                    {
                                                        i.sod_combo_products == null ? null :
                                                        i.comboproducts.map((item, index) => {
                                                            return(
                                                                <span>
                                                                    {item[0].product_refcode} - {item[0].color_name} - {item[0].size_name}
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    ]
                                                    </li>
                                                    
                                                </ul>
                                            )
                                        })}
                                        </TableCell>
                                        {/* <TableCell className='tablecellPrint' style={{fontWeight: 'bold'}}>
                                        {row.deliver_boy}
                                        </TableCell> */}
                                        <TableCell className='tablecellPrint1' style={{fontWeight: 'bold', width: '10%'}}>
                                            {row.remarks}
                                            {row.remarks1},
                                            {row.salesorder_replacereturn == 2 ? <p>Replaced Bill</p>: null}
                                        </TableCell>
                                        {/* <TableCell className='tablecellPrint1' style={{fontWeight: 'bold'}}>
                                        {row.salesorder_date} - {row.salesorder_created_at}
                                        </TableCell> */}
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                         
                </div>
            </Paper> 
            <Multipleprint parentCallback={parentCallback} printarray={printarray} array={array} modal={modal}/>
            
        </div>
      );
  }

  export default DeliveryExecutive;
