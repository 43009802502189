import React, {useState, useEffect} from 'react';
import './stockreport.css';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {BsArrowReturnRight} from "react-icons/bs"


const columns = [
    { id: '1', label: 'Sl#', minWidth: 50,span: false },
    { id: '1', label: 'Code', minWidth: 50,span: false },
    { id: '1', label: 'Barcode', minWidth: 50,span: false },
    { id: '2', label: 'Product name', minWidth: 50,span: false },
    { id: '3', label: 'Batch', minWidth: 50,span: false },
    { id: '4', label: 'Unit', minWidth: 50,span: false },
    { id: '5', label: 'Opening', minWidth: 50,span: false },
    { id: '6', label: 'In', minWidth: 50,span: false },
    { id: '7', label: 'Out', minWidth: 50,span: false },
    { id: '8', label: 'Cost', minWidth: 50,span: false },
    { id: '9', label: 'Stock', minWidth: 50,span: false },
    { id: '10', label: 'Value', minWidth: 50,span: false },
    
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Stockreport(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")
  const [input, setInput] = useState(0);

  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState(false);

  const [source, setSource] = React.useState([]);
  

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [batchcode, setBatchcode] = React.useState("");
  const [blocked, setBlocked] = React.useState(0)

  const [dateFrom, setDatefrom] = React.useState(new Date)
  const [dateTo, setDateTo] = React.useState(new Date)

  const [batlist, setBatlist] = React.useState([])
  const [downloadlink, setDownloadlink] = React.useState("");

  const [wordEntered, setWordEntered] = useState("");
  const [filteredData, setFilteredData] = useState([]);


  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };


  const batchcodeChange = (event) => {
    setBatchcode(event.target.value);
  }

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  // const checkChange = (e) => {
  //   let isChecked = e.target.checked;
  //   if(isChecked == true) {
  //     setBlocked(1)
  //   }
  //   else {
  //     setBlocked(0)
  //   }
   
  // }

  useEffect(() => {
      getData()
    }, [input]);

    const handleSearch = (event) => {
      const searchWord = event.target.value;
      setWordEntered(searchWord);
      var data = {
        'referalcode' : searchWord
      }
      console.log("data search", data)
      fetch(global.url + "batchcodelist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("response search", responseJson)
          if(responseJson.error == false){
            setFilteredData(responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    // const getData = () => {
    //     var userdata = JSON.parse(localStorage.getItem("user"))
    //     var branch = JSON.parse(localStorage.getItem("branch"))
    //     var company = JSON.parse(localStorage.getItem("company"))
    //     setUsercompanyid(company)
    //     setUserbranchid(branch)
    //     setUsertype(userdata.user_type)
    //     setUserid(userdata.user_id)
    //     setLoader(true)
    //     var data = {
    //       "type":  userdata.user_type,
    //       "companyid": company
    //     }
    //     fetch(global.url + "viewStockReport", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data)
    //       })
    //       .then((response)=> response.json())
    //       .then((responseJson)=>{
    //           console.log("response viewStockReport", responseJson)
    //           if(responseJson.error == false){
    //             setLoader(false)
    //             setSource(responseJson.data)
    //           }
    //       })
    //       .catch((error)=>{
    //           console.log(error)
    //       })
    // }

    const getData = () => {
      setFilteredData([])
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
      var data = {
        "type":  userdata.user_type,
        "companyid": company,
        "page" : input*10,
        "limit": 10,
        "branchid" : branch,
      }
      console.log("data", data)
      fetch(global.url + "viewStockReport", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response viewStockReport", responseJson)
            if(responseJson.error == false){
              setLoader(false)
              setSource(responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }
    
    const filter = () => {
      setFilteredData([])
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
      var data = {
        "from_date":  dateFrom.toISOString().split('T')[0],
        "to_date": dateTo.toISOString().split('T')[0],
        "batchcode": batchcode,
        "type":  userdata.user_type,
        "companyid": company,
        "page" : input*10,
        "limit": 10,
        "branchid" : branch,
      }
      console.log("data", data)
      fetch(global.url + "viewStockReport", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response viewStockReport", responseJson)
            if(responseJson.error == false){
              setLoader(false)
              setSource(responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const filter1 = (id) => {
    setFilteredData([])
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    setLoader(true)
    var data = {
      "from_date":  dateFrom.toISOString().split('T')[0],
      "to_date": dateTo.toISOString().split('T')[0],
      "batchcode": id,
      "type":  userdata.user_type,
      "companyid": company,
      "branchid" : branch,
      // "page" : input,
      // "limit": input*10
    }
    console.log("data", data)
    fetch(global.url + "viewStockReport", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("response viewStockReport", responseJson)
          if(responseJson.error == false){
            
            setSource(responseJson.data)
          }
          setLoader(false)
      })
      .catch((error)=>{
          console.log(error)
      })
}

  const excelupload = () => {
    setAge(true)
    console.log("excel upload")
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
      var data = {
        "excelexport":  true,
        "type":  userdata.user_type,
        "companyid": company,
        "branchid" : branch,
      }
      fetch(global.url + "viewStockReport", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response excel", responseJson)
            if(responseJson.error == false){
              setLoader(false)
              setSource(responseJson.data)
              setDownloadlink(responseJson.data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const decrement = () => {
          setInput(prevCount => prevCount - 1);
       
  }

  const increment = () => {
    console.log("increment")
      setInput(prevCount => prevCount + 1);
     
  }

  const getbatch = (value) => {
    setBatchcode(value)
  }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden', paddingBottom: 2 }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Stock Value</p>
            </div>
            <div style={{margin: 20, paddingBottom: 20}}>
              <Row>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="From"
                              value={dateFrom}
                              onChange={(newValue) => {
                                  setDatefrom(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="To"
                              value={dateTo}
                              onChange={(newValue) => {
                                  setDateTo(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                      <div style={{position: 'relative'}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="text" autoComplete="off"  onChange={handleSearch} id="outlined-basic" variant="outlined" label="Ref code"  size="small"/>
                          </FormControl>
                          {filteredData.length == 0 ? null:
                              <div className="dataResult">
                                  {filteredData.map((row, key) => {
                                      return (
                                      <a onClick={() => filter1(row.pb_batch)} className="searchItem" target="_blank">
                                          <div style={{marginTop:10, display: 'flex'}}>
                                              {/* <BsArrowReturnRight color="#000" size={14} style={{marginLeft: 20, marginTop: 2}}/> */}
                                              <p style={{fontSize: 12, marginLeft: 10}}>{row.pb_batch}-{row.product_name}-({row.color_name}, {row.size_name})</p>
                                          </div>
                                          <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                      </a>
                                      );
                                  })}
                              </div>
                          }
                      </div>
                        
                        {/* <FormControl sx={{  width: '100%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Batchcode</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={batchcode}
                                label="Age"
                                onChange={batchcodeChange}
                                size="small"
                            >
                            <MenuItem value="">
                            <em>None</em>
                            </MenuItem>
                            {batlist.map((row, index) => {
                                return (
                                <MenuItem value={row.pb_batch}>{row.pb_batch}-{row.product_name}-({row.color_name}, {row.size_name})</MenuItem>
                            )})}
                        </Select>
                      </FormControl> */}
                  </Col>
                  <Col xs={12} md={1} style={{marginTop: 20}}>
                        <Button onClick={filter}  style={{width: '50%', height: 45}} variant="contained">Filter</Button>
                  </Col>
                  <Col xs={12} md={2} style={{marginTop: 25}}>
                    <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="table-to-xls"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="Download exel"/>
                  </Col>
                  <Col xs={12} md={5} style={{marginTop: 25}}>
                    {downloadlink == "" ?
                        <Button onClick={excelupload}  style={{width: '50%'}} variant="contained">All Excel Report</Button>:
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button"
                          table="table-to-xls"
                          filename="tablexls"
                          sheet="tablexls"
                          buttonText="Download All"/>}
                  </Col>
            </Row>
            <div>
              <TableContainer sx={{ maxHeight: 440, mt: 2, }}>
              {loader ? <Loader /> :
                <Table id="table-to-xls" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                          colSpan={column.span == true ? 2: 1}
                        >
                          {column.label}
                          
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {
                    source
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow  tabIndex={-1} key={row.code}>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {index+1}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_code}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.pb_inter_barcode}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_product_name}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_batch}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_unit}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_opening}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_in}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_out}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_cost}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_stock}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.sr_value}
                                </TableCell>
                                
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              }
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={source.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
              {age == true ? null :
              <div style={{marginTop: 20}}>
                  <Button onClick={increment} style={{float: 'right'}} variant="contained">Next</Button>
                  {input == 0 ? null :
                    <Button onClick={decrement} style={{marginRight: 40, float: 'right', borderColor: '#000'}} className='stock-prev' variant="light">Previous</Button>
                  }
              </div>}
              </div>
            </div>
          </Paper> 
      </div>
    )
}
export default Stockreport;