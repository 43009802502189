import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import './accounts.css'
import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Table, Button } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../../loader/loader'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';



const columns = [
    { id: '1', label: 'Asset', minWidth: 50,span: false },
    { id: '2', label: 'Amount', minWidth: 50,span: false },
   
  ];

  const columns1 = [
    { id: '1', label: 'Liability', minWidth: 50,span: false },
    { id: '2', label: 'Amount', minWidth: 50,span: false },
   
  ];

  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Balancesheet(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

    const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');

  const [incomesource, setIncomesource] = React.useState([]);
  const [expensesource, setExpensesource] = React.useState([]);

  const [date1, setDate1] = React.useState(new Date);
  const [date2, setDate2] = React.useState(new Date);
  

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0)

  const [purchaseacc, setPurchaseacc] = React.useState("");
  const [salesacc, setSalesacc] = React.useState("");
  const [directexpenses, setDirectexpenses] = React.useState("");
  const [indirectExpenses, setIndirectexpenses] = React.useState("");
  const [openstock, setOpenstock] = React.useState("");
  const [closestock, setClosestock] = React.useState("");
  const [indirectincome, setIndirectincome] = React.useState("");
  const [directincome, setDirectincome] = React.useState("");
  const [creditsale, setCreditsale] = React.useState("");
  const [debitsale, setDebitsale] = React.useState("");
  const [creditordebit, setCreditordebit] = React.useState("");
  const [grossprofit, setGrossprofit] = React.useState("");
  const [subtotalcredit, setSubtotalcredit] = React.useState("");
  const [subtotaldebit, setSubtotaldebit] = React.useState("");
  const [debitnetprofit, setDebitnetprofit] = React.useState("");
  const [creditnetprofit, setCreditnetprofit] = React.useState("");

  const [purchaseDetails, setPurchaseDetails] = React.useState([]);
  const [salesDetails, setSalesDetails] = React.useState([]);
  const [directExpDetails, setDirectexpDetails] = React.useState([]);
  const [indirectExpDetails, setIndirectexpDetails] = React.useState([]);
  const [directIncDetails, setDirectIncDetails] = React.useState([]);
  const [indirectIncDetails, setIndirectIncDetails] = React.useState([]);
  const [purchaseDetailsLedger, setPurchaseDetailsLdger] = React.useState([]);
  const [salesDetailsLedger, setSalesDetailsLedger] = React.useState([]);
  const [directExpDetailsLedger, setDirectexpDetailsLedger] = React.useState([]);
  const [indirectExpDetailsLedger, setIndirectexpDetailsLedger] = React.useState([]);
  const [directIncDetailsLedger, setDirectIncDetailsLedger] = React.useState([]);
  const [indirectIncDetailsLedger, setIndirectIncDetailsLedger] = React.useState([]);

  // function setData(childData) {
  //   setToggle(childData)
  // }

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

 
 

  const [assetsource, setAssetource] = React.useState([]);
  const [liabilitysource, setLiabilitysource] = React.useState([]);
  

 
  // function setData(childData) {
  //   setToggle(childData)
  // }

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  // useEffect(() => {
  //     getData()
  //   }, []);

  //   const getData = () => {
  //       var userdata = JSON.parse(localStorage.getItem("user"))
  //       var branch = JSON.parse(localStorage.getItem("branch"))
  //       var company = JSON.parse(localStorage.getItem("company"))
  //       setUsercompanyid(company)
  //       setUserbranchid(branch)
  //       setUsertype(userdata.user_type)
  //       setUserid(userdata.user_id)
  //       setLoader(true)
  //       var data = {
  //         "type":  userdata.user_type,
  //         "companyid": company
  //       }
  //       fetch(global.url + "updatedbalanceSheet", {
  //           method: "POST",
  //           headers: {
  //               'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify(data)
  //         })
  //         .then((response)=> response.json())
  //         .then((responseJson)=>{
  //             console.log("response balancesheet", responseJson)
  //             if(responseJson.error == false){
  //               setLoader(false)
  //               setAssetource(responseJson.data.asset)
  //               setLiabilitysource(responseJson.data.liability)
  //             }
  //         })
  //         .catch((error)=>{
  //             console.log(error)
  //         })
  //   }


    useEffect(() => {
      getData()
    }, []);

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid" : company,
          "fromdate" : date1,
          "todate" : date2
        }
        console.log(data, "data23")
        fetch(global.url + "profitAndLossNewDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("profitAndLossNew route", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                setPurchaseacc(responseJson.data.purchaseac)
                setSalesacc(responseJson.data.salesac)
                setDirectexpenses(responseJson.data.directexpenses)
                setIndirectexpenses(responseJson.data.indirectexpenses)
                setOpenstock(responseJson.data.openstock)
                setClosestock(responseJson.data.closestock)
                setIndirectincome(responseJson.data.indirectincome)
                setDirectincome(responseJson.data.directincome)
                setCreditsale(responseJson.data.creditside)
                setDebitsale(responseJson.data.debitside)
                setCreditordebit(responseJson.data.creditordebit)
                setGrossprofit(responseJson.data.grossprofit)
                setSubtotalcredit(responseJson.data.subtotalcredit)
                setSubtotaldebit(responseJson.data.subtotaldebit)
                setDebitnetprofit(responseJson.data.debitnetprofit)
                setCreditnetprofit(responseJson.data.creditnetprofit)
                setPurchaseDetails(responseJson.data.purchaseac_details)
                setSalesDetails(responseJson.data.salesac_details)
                setDirectexpDetails(responseJson.data.directexpenses_details)
                setIndirectexpDetails(responseJson.data.indirectexpenses_details)
                setDirectIncDetails(responseJson.data.directincome_details)
                setIndirectIncDetails(responseJson.data.indirectincome_details)

                setPurchaseDetailsLdger(responseJson.data.purchaseac_ledgerdetails)
                setSalesDetailsLedger(responseJson.data.salesac_ledgerdetails)
                setDirectexpDetailsLedger(responseJson.data.directexpenses_ledgerdetails)
                setIndirectexpDetailsLedger(responseJson.data.indirectincome_details)
                setDirectIncDetailsLedger(responseJson.data.indirectincome_details)
                setIndirectIncDetailsLedger(responseJson.data.indirectincome_details)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Balance Sheet</p>
            </div>
            
            <div style={{margin: 20}}>
              <Row>
                  <Col sm={3} style={{marginTop:10}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="From"
                              value={date1}
                              onChange={(newValue) => {
                                  setDate1(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col sm={3} style={{marginTop:10}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="To"
                              value={date2}
                              onChange={(newValue) => {
                                  setDate2(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 5}}>
                      <Button onClick={getData} style={{width: '50%', height: 45}} variant="primary">Filter</Button>
                  </Col>
              </Row>
            </div>



            <div>
              <div style={{margin: 20, display: 'flex',}}>
                <div className="pandlh">
                  <Table responsive style={{borderBottomWidth: 'unset'}} >
                      <thead>
                        <tr>
                          <th colSpan={2} style={{width: '50%', borderBottomWidth: 0}}>Liabilities</th>
                          <th style={{width: '25%', borderBottomWidth: 0, textAlign: 'end'}}>Amount</th>
                          <th style={{width: '25%', borderBottomWidth: 0, textAlign: 'end'}}>Amount</th>
                        </tr>
                      </thead>
                  </Table>
                </div>
                <div className="pandlh">
                  <Table responsive >
                    <thead>
                      <tr>
                        <th colSpan={2} style={{width: '50%', borderBottomWidth: 0}}>Assets</th>
                        <th style={{width: '25%', borderBottomWidth: 0, textAlign: 'end'}}>Amount</th>
                        <th style={{width: '25%', borderBottomWidth: 0, textAlign: 'end'}}>Amount</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </div>

              <div style={{margin: 20, display: 'flex', marginTop: -20}}>
                <div className="pandl">
                  {/* <Table responsive >
                      <tbody>
                      <tr>
                        <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Opening Stock</td>
                        <td style={{width: '25%', borderBottomWidth: 0}}></td>
                        <td style={{textAlign : 'end', fontSize: 13,width: '25%', borderBottomWidth: 0}}>{openstock}</td>
                      </tr> 
                      {purchaseDetails.map((item, index) => (
                        <>
                          <tr>
                            <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>{item.accountname}</td>
                            <td style={{width: '25%', borderBottomWidth: 0}}></td>
                            <td style={{textAlign : 'end', fontSize: 13,width: '25%', borderBottomWidth: 0}}>{item.accountamount}</td>
                          </tr>
                          {item.account_details.map((i, index) => (
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>{i.accountname}</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{i.accountamount}</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                            ))}
                        </>
                        
                      ))}
                      {directExpDetails.map((item, index) => (
                        <>
                          <tr>
                            <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>{item.accountname}</td>
                            <td style={{width: '25%', borderBottomWidth: 0}}></td>
                            <td style={{textAlign : 'end', fontSize: 13,width: '25%', borderBottomWidth: 0}}>{item.accountamount}</td>
                          </tr>
                          {item.account_details.map((i, index) => (
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>{i.accountname}</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{i.accountamount}</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                            ))}
                        </>
                      ))}
                    </tbody>
                  </Table> */}
                </div>
                <div className="pandl">
                  <Table responsive>
                      <tbody>
                          {/* {salesDetails.map((item, index) => (
                            <>
                              <tr>
                                <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>{item.accountname}</td>
                                <td style={{width: '25%', borderBottomWidth: 0}}></td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{item.accountamount}</td>
                              </tr>
                              {item.account_details.map((i, index) => (
                                <tr>
                                  <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>{i.accountname}</td>
                                  <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{i.accountamount}</td>
                                  <td style={{width: '25%', borderBottomWidth: 0}}></td>
                                </tr>
                              ))}
                            </>
                        ))}
                        {directIncDetails.map((item, index) => (
                            <>
                              <tr>
                                <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>{item.accountname}</td>
                                <td style={{width: '25%', borderBottomWidth: 0}}></td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{item.accountamount}</td>
                              </tr>
                              {item.account_details.map((i, index) => (
                                <tr>
                                  <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>{i.accountname}</td>
                                  <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{i.accountamount}</td>
                                  <td style={{width: '25%', borderBottomWidth: 0}}></td>
                                </tr>
                              ))}
                            </>
                        ))} */}
                        <tr>
                              <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Closing Stock</td>
                              <td style={{width: '25%', borderBottomWidth: 0}}></td>
                              <td style={{textAlign : 'end', fontSize: 13,width: '25%', borderBottomWidth: 0}}>0</td>
                            </tr>
                      </tbody>
                  </Table>
                </div>
              </div>

              <div style={{margin: 20, display: 'flex', marginTop: -20}}>
                <div className="pandl">
                  <Table responsive >
                      <tbody>
                          {/* <tr>
                            <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Gross Profit C/O</td>
                            <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                            <td style={{textAlign : 'end', fontSize: 13, width: '25%'}}></td>
                          </tr>
                          <tr>
                            <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}></td>
                            <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                            <td style={{textAlign : 'end', fontSize: 13, width: '25%'}}>0</td>
                          </tr> */}
                    </tbody>
                  </Table>
                </div>
                <div className="pandl">
                  <Table responsive>
                    <tbody>
                            {/* <tr>
                              <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Gross Loss C/O</td>
                              <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              <td style={{textAlign : 'end', fontSize: 13, width: '25%'}}></td>
                            </tr>
                            <tr>
                              <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}></td>
                              <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              <td style={{textAlign : 'end', fontSize: 13, width: '25%',}}>0</td>
                            </tr> */}
                      </tbody>
                  </Table>
                </div>
              </div>

              <div style={{margin: 20, display: 'flex', marginTop: -20}}>
                <div className="pandl">
                  <Table responsive >
                      <tbody>
                      {indirectIncDetails.map((item, index) => (
                        <>
                          <tr>
                            <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>{item.accountname}</td>
                            <td style={{width: '25%', borderBottomWidth: 0}}></td>
                            <td style={{textAlign : 'end', fontSize: 13,width: '25%', borderBottomWidth: 0}}>{item.accountamount}</td>
                          </tr>
                          {item.account_details.map((i, index) => (
                              <tr>
                                <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>{i.accountname}</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{i.accountamount}</td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              </tr>
                            ))}
                        </>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="pandl">
                  <Table responsive>
                      <tbody>
                        {indirectIncDetails.map((item, index) => (
                            <>
                              <tr>
                                <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>{item.accountname}</td>
                                <td style={{width: '25%', borderBottomWidth: 0}}></td>
                                <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{item.accountamount}</td>
                              </tr>
                              {item.account_details.map((i, index) => (
                                <tr>
                                  <td colSpan={2} style={{fontSize: 13, paddingLeft: 30, width: '50%', borderBottomWidth: 0}}>{i.accountname}</td>
                                  <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>{i.accountamount}</td>
                                  <td style={{width: '25%', borderBottomWidth: 0}}></td>
                                </tr>
                              ))}
                            </>
                        ))}
                      </tbody>
                  </Table>
                </div>
              </div>

              <div style={{margin: 20, display: 'flex', marginTop: -20}}>
                <div className="pandlb">
                  <Table responsive >
                      <tbody>
                          <tr>
                            <td colSpan={2} className='balance-table' style={{width: '50%'}}>Profit & Loss</td>
                            <td style={{width: '25%'}}></td>
                            <td style={{textAlign : 'end', fontSize: 13,width: '25%'}}>{creditordebit == "debit" ? debitnetprofit: null}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Total</td>
                            <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                            <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>0</td>
                          </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="pandlb">
                  <Table responsive>
                    <tbody>
                            <tr>
                              <td colSpan={2} className='balance-table' style={{width: '50%',}}></td>
                              <td style={{width: '25%'}}></td>
                              <td style={{textAlign : 'end', fontSize: 13,width: '25%'}}>0</td>
                            </tr>
                            <tr>
                              <td colSpan={2} className='balance-table' style={{width: '50%', borderBottomWidth: 0}}>Total</td>
                              <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}></td>
                              <td style={{textAlign : 'end', fontSize: 13, width: '25%', borderBottomWidth: 0}}>0</td>
                            </tr>
                      </tbody>
                  </Table>
                </div>
              </div>

            </div>












            {/* <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
             <Row>
               <Col xs={6} >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                          colSpan={column.span == true ? 2: 1}
                        >
                          {column.label}
                          
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {
                    assetsource
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow  tabIndex={-1} key={row.code}>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.particulars}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.asset}
                                </TableCell>

                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                </Col>
                <Col xs={6}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns1.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                          colSpan={column.span == true ? 2: 1}
                        >
                          {column.label}
                          
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {
                    liabilitysource
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow  tabIndex={-1} key={row.code}>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.particulars}
                                </TableCell>
                                <TableCell style={{fontWeight: 'bold'}}>
                                    {row.liability}
                                </TableCell>
                               
                                
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                </Col>
              </Row>
            }
            </TableContainer> 
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
           
          </Paper> 
        
      </div>
    )
}
export default Balancesheet;