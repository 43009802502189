import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Table , Row, Col,Modal,Button  } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as IconName from "react-icons/bs";
import * as GI from "react-icons/gi";
import * as BI from "react-icons/bi";
import * as RI from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useHistory, useParams } from "react-router-dom";

function SalesApproval(props) {
    let history = useHistory();
    // let { id } = useParams();
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [source, setSource] = useState([])
    const [modal, setModal] = React.useState(false);
    const [date, setDate] = React.useState(new Date);

    const [frominvoice, setFrominvoice] = useState("0")
    const [toinvoice, setToinvoice] = useState("0")

    const [executiveid, setExecutiveid] = useState("")
    const [executiveList, setExecutivelist] = useState([])


    const [mobile, setMobile] = useState("")
    const [invoice, setInvoice] = useState("")

    const [salesmanid, setSalesmanid] = useState("")
    const [userlist, setUserlist] = useState([])

    const [billDetails, setBilldetails] = useState([])

    const productlist = [{id: 1},{id: 2},{id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}]
    const [blocked, setBlocked] = React.useState(0);
    const [array, setArray] = React.useState([])

    const [frontend, setFrontend] = React.useState(0)
    const [showUnasigned, setShowunasigned] = React.useState(0)

    const [approvalsalesman, setApprovalsalesman] = React.useState("")

    const userChange = (event) => {
        setSalesmanid(event.target.value)
        getData(event.target.value)
    }

    const approvalsalesmanChange = (event) => {
        setApprovalsalesman(event.target.value)
    }

    const frontendOrderChange = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            setFrontend(1)
        }
        else{
            setFrontend(0)
        }
    }

    const unasignChange = (e) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            setShowunasigned(1)
        }
        else{
            setShowunasigned(0)
        }
    }
    const blockChange = (e,id) => {
        let isChecked = e.target.checked;
        console.log(isChecked)
        if(isChecked == true) {
            var input  = [...array]
            input.push(id)
            console.log("input", input)
            setArray(input)
        }
        else{

            var testArr = [1, 2, 3, 4, 5];
            const val = 3; // Value to remove

            for (let i = 0; i < array.length; i++) {
                if (array[i] === id) {
                    const removedElements = array.splice(i, 1);
                    console.log(removedElements); // Outputs [3]
                    i--; // Since the indexes of elements following this index get updated after removal
                }
            }
        }
        console.log("array",  array)
    }

    const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        console.log('user',userdata )
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
    
        
        var data = {
          "companyid": company,
          "branchid" : branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("user response", responseJson)
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    useEffect(() => {
        getUser()
        getdata1()
      }, [frontend, showUnasigned]);

      const getdata1 = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getdata2 = (search) => {
        setMobile(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "mobile": search,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getdata3 = (search) => {
        setInvoice(search)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "invoice": search,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const getData = (id) => {
        setMobile("")
        setInvoice("")
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        console.log("id", id)
        var data = {
            "companyid": company,
            "salesmanid": id,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned,
            "type" : userdata.user_type, 
            "branchid" : branch
          }
          fetch(global.url + "viewSalesOrderBySalesman", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const viewBillclick = (id) => {
        setModal(true)
    
        var data = {
            "companyid": usercompanyid,
            "salesorderid": id,
            "type": usertype,
            "frontendorders": frontend,
            "emptydeliveryboys": showUnasigned
        }
        fetch(global.url + "viewSalesOrderDetail", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("report sale detail", responseJson)
          if(responseJson.error == true){
            alert(responseJson.message)
          }
          else{
              if(responseJson.data.sales_details){
                setBilldetails(responseJson.data.sales_details)
              }
              else{
                setBilldetails([])
              }
           
          }
        
        })
      }

      const updatedeliveryboy = () => {
            var data = {
                "salesorder_id": array,
                "deliveryboy": approvalsalesman
            }
            console.log("data", data)
            fetch(global.url + "bulkUpdateSalesman", {
                method: 'POST',
                headers: {
                'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("report sale detail", responseJson)
                if(responseJson.error == false) {
                    alert(responseJson.message)
                    getdata1()
                }
                
            })
      }

      const orderSubmit = () => {
        var data = {
            "salesorder_id": array,
        }
        console.log("data", data)
        fetch(global.url + "salesOrderApproval", {
            method: 'POST',
            headers: {
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("report sale detail", responseJson)
            if(responseJson.error == false) {
                alert(responseJson.message)
                getdata1()
            }
            
        })
        
      }

      const onPrint = () => {
            window.print()
      }

      const dateSearch = (text) => {
          setDate(text)
            var userdata = JSON.parse(localStorage.getItem("user"))
            var branch = JSON.parse(localStorage.getItem("branch"))
            var company = JSON.parse(localStorage.getItem("company"))
            setUsercompanyid(company)
            setUserbranchid(branch)
            setUsertype(userdata.user_type)
            console.log("date", text.toISOString().split('T')[0])
            var data = {
                "companyid": company,
                "salesorder_date": text.toISOString().split('T')[0],
                "frontendorders": frontend,
                "emptydeliveryboys": showUnasigned,
                "type" : userdata.user_type, 
                "branchid" : branch
            }
            fetch(global.url + "viewSalesOrderBySalesman", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("user response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const onfilterClick2 = () => {
            var userdata = JSON.parse(localStorage.getItem("user"))
            var branch = JSON.parse(localStorage.getItem("branch"))
            var company = JSON.parse(localStorage.getItem("company"))
            setUsercompanyid(company)
            setUserbranchid(branch)
            setUsertype(userdata.user_type)
            var data = {
                "companyid": company,
                "invoicedrom": frominvoice,
                "invoiceto": toinvoice,
                "salesmanid": salesmanid,
                "frontendorders": frontend,
                "emptydeliveryboys": showUnasigned,
                "type" : userdata.user_type, 
                "branchid" : branch
            }
            console.log('data', data)
            fetch(global.url + "viewSalesOrderBySalesman", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                })
                .then((response)=> response.json())
                .then((responseJson)=>{
                    console.log("user response", responseJson)
                    setSource(responseJson.data)
                })
                .catch((error)=>{
                    console.log(error)
                })
      }

      const cancelClick = (id, invoice) => {
        if (window.confirm('Are you sure you want to return the bill ' + invoice + " ?")) {
            console.log("id", id)
            var userdata = JSON.parse(localStorage.getItem("user"))
            var data = {
            "salesorderid":  id,
            "user_id":  userdata.user_id,
            }
            fetch(global.url + "salesOrderCancel", {
                method: 'POST',
                headers: {
                'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then((response) => response.json())
            .then((responseJson) => {
            if(responseJson.error == false){
                getData()
                alert(responseJson.message)
            }
                console.log("response", responseJson)
            })
          } else {
            // Do nothing!
            console.log('Thing');
          }


        
      }

      const deliveryClick = () => {
        if (window.confirm('Are you sure you want to move the bills from' + frominvoice + "to" + toinvoice)) {
                    var data = {
                        // "salesorderid":  id,
                    }
                    fetch(global.url + "ApplyDeliveredReport", {
                        method: 'POST',
                        headers: {
                            'Content-Type' : 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if(responseJson.error == false){
                            getData()
                            alert(responseJson.message)
                        }
                        console.log("response", responseJson)
                    })
        } else {
        
            console.log('Thing');
        }
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
            {
                   usertype == 1 || usertype == 2 || usertype == 3 || usertype == 4 ? 
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Order Report</p> :
                <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Order Replacement List</p>
            }
            {/* {
                usertype == 1 || usertype == 2 || usertype == 3 || usertype == 4 ? 
                null: 
                <div>
                    <FormControl style={{marginRight: 10}} sx={{width: '50%' }}>
                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={approvalsalesman}
                                label="Age"
                                onChange={approvalsalesmanChange}
                                size="small"
                            >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {userlist.map((row, index) => {
                                return (
                                <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                            )})}
                            </Select>
                    </FormControl>
                    <Button onClick={() => updatedeliveryboy()} variant="primary" style={{padding: 5, fontSize: 15, marginRight: 15}}>Update Delivery Boy</Button>
                    <Button onClick={() => orderSubmit()} variant="primary" style={{padding: 5, fontSize: 15, marginRight: 15, marginTop: 20}}>Order Approve</Button>
                    <Button onClick={() => onPrint()} variant="primary" style={{padding: 5, fontSize: 15, marginTop: 20}}>Print</Button>
                </div>
            } */}
               
            </div>
            <Row>
                <Col sm={12} >
                    <Row>
                        {/* <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={salesmanid}
                                        label="Age"
                                        onChange={userChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {userlist.map((row, index) => {
                                        return (
                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                    )})}
                                    </Select>
                            </FormControl>
                        </Col> */}
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => getdata2(e.target.value)} placeholder="Mobile Number" value={mobile} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => getdata3(e.target.value)} placeholder="Invoice Number" value={invoice} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={date}
                                    onChange={(newValue) => {
                                    dateSearch(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={salesmanid}
                                        label="Age"
                                        onChange={userChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {userlist.map((row, index) => {
                                        return (
                                        <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                    )})}
                                    </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 30}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setFrominvoice(e.target.value)} value={frominvoice} label='Invoice From' id="outlined-basic" variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>

                      <Col xs={12} md={2} style={{marginTop: 30}}>
                          <FormControl sx={{width: '100%' }}>
                              <TextField type="number" autoComplete='off' onChange={(e) => setToinvoice(e.target.value)} value={toinvoice} id="outlined-basic" label='Invoice To' variant="outlined"  size="small"/>
                          </FormControl>
                      </Col>

                      <Col xs={12} sm={2} style={{marginTop: 30}}>
                            <Button onClick={() => onfilterClick2()} variant="primary">Filter</Button>
                      </Col>
                      <Col xs={12} sm={2} style={{marginTop: 30}}>
                            <FormControl sx={{  width: '100%' }}>
                                <FormControlLabel control={
                                <Checkbox onChange={e => frontendOrderChange(e)} />
                                } label="Website Orders" />
                            </FormControl>
                        </Col>
                        {
                            frontend ? 
                            <Col xs={12} sm={2} style={{marginTop: 30}}>
                                <FormControl sx={{  width: '100%' }}>
                                    <FormControlLabel control={
                                    <Checkbox onChange={e => unasignChange(e)} />
                                    } label="Show unasigned delivery Orders" />
                                </FormControl>
                            </Col> : 
                            null
                        }
                        
                      {/* <Col xs={12} sm={2} style={{marginTop: 30}}>
                            <Button onClick={()=> deliveryClick()} variant="primary" target='_blank'>Move to Delivered Report</Button>
                      </Col> */}
                    </Row>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                    <Table responsive id="table-to-xls" bordered className="section-to-print">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Invoice</th>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Mobile</th>
                                <th>Building</th>
                                <th>Street</th>
                                <th>zone</th>
                                <th>Area</th>
                                <th>Total</th>
                                <th colSpan="2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {source.map(result => {
                            return (
                            <tr>
                                <td>{result.user_name}</td>
                                <td>{result.salesorder_invoice}</td>
                                <td>{result.salesorder_date} - {result.salesorder_created_at}</td>
                                <td>{result.customer_name}</td>
                                <td>{result.customer_mobile}</td>
                                <td>{result.customer_building}</td>
                                <td>{result.customer_street}</td>
                                <td>{result.salesorder_zone}</td>
                                <td>{result.customer_area}</td>
                                <td>{result.salesorder_cashledger}</td>
                                {result.salesorder_isdeleted == 0 ?
                                <td className="print_btn">
                                    {result.salesorder_isreplacebill == 0 ?
                                    <a href={'/Sales/salesorderreplace/' + result.salesorder_id} >
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>:
                                    result.salesorder_replace_invoiceno == 0 || result.salesorder_replace_invoiceno == null ? "Replaced" :
                                    result.salesorder_replace_invoiceno}
                                </td> : 
                                <td></td>}
                                {/* <td  className="print_btn"><Button onClick={() => viewBillclick(result.salesorder_id)} variant="primary" style={{padding: 5, fontSize: 10}}>View Bill</Button></td> */}
                                
                            </tr>
                            )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Modal
                size="lg"
                show={modal}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                  Sales Order Bill
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table  bordered hover responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product code</th>
                            <th>Product name</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billDetails.map((item, index) => (
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.code}</td>
                                <td>{item.productname}</td>
                                <td>{item.sd_qty}</td>
                                <td>{item.unit}</td>
                                <td>{item.price}</td>
                                <td>{item.sd_total_amount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                </Modal.Body>
            </Modal>
          </Paper> 
      </div>
    )
}
export default SalesApproval;