
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Modal , Row, Col } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as FaIcons from "react-icons/fa";
import Loader from '../loader/loader';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const columns = [
    { id: '1', label: 'File Number', minWidth: 50,span: false },
    { id: '2', label: 'File Name', minWidth: 50,span: false },
    { id: '3', label: 'Company Name', minWidth: 50,span: false },
    { id: '4', label: 'Branch', minWidth: 50,span: false },
    { id: '5', label: 'Submitted By', minWidth: 50,span: false },
    { id: '6', label: 'Designation', minWidth: 50,span: false },
    { id: '7', label: 'File Type', minWidth: 50,span: false },
    { id: '8', label: 'File Date', minWidth: 50,span: false },
    { id: '9', label: 'Submit Date', minWidth: 50,span: false },
    { id: '10', label: 'Status', minWidth: 50,span: false },
    { id: '11', label: '', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  
  const source = [
    {
      
    }
  ];

function Filemanager(props) {
  const [downloadlink, setDownloadlink] = React.useState("");

  const onExport = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      companyid: company
    }
      fetch(global.url + "excelExportCurrentStock", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("link", responseJson)
        if(responseJson.error == false){
          console.log("link", responseJson.data)
            setDownloadlink(responseJson.data)
        }
                
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            
            <div style={{margin: 40}}>
              <Row style={{marginTop: 20}}>
                    <Col md={6} >
                    <div>
                      <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Productlist Download</p>
                    </div> 
                    <div style={{marginTop: 20}}>
                      {
                            downloadlink == "" ? 
                            <Button style={{float: "left"}}  variant="contained">Start Excell Export</Button>:
                            <a style={{float: "left"}} href={global.excell + downloadlink}  target="_blank" download>
                                <Button  style={{float: 'right'}} variant="contained">Download File</Button>
                            </a>
                        }
                    </div>
                    </Col>
                    <Col md={6} >
                    <div>
                      <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Stock Adjustment Download</p>
                    </div> 
                    <div style={{marginTop: 20}}>
                        {
                            downloadlink == "" ? 
                            <Button style={{float: "left"}}  variant="contained">Start Excell Export</Button>:
                            <a style={{float: "left"}} href={global.excell + downloadlink}  target="_blank" download>
                                <Button  style={{float: 'right'}} variant="contained">Download File</Button>
                            </a>
                        }
                      </div>  
                    </Col>
                 </Row>   
            </div>        
        </Paper> 
      </div>
    )
}
export default Filemanager;