
import React, {Component, useEffect, useState, useRef} from 'react';
import { Row, Col, Modal, Table } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';


function AddCompany(props) {

  return (
        <div>
             <Row>
                    <Col md={6} >
                        <Row>
                            <Col md={12}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField onChange={(e) => props.setCustoname(e.target.value)} value={props.custoname} id="outlined-basic" label="Company Name" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col md={6}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Local Name" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col md={6}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Company Reg No." variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            
                            <Col md={6}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Telephone" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col md={6}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Mobile" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Email" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Website" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Pincode" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Fax" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Base Currency Symbol" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormControl sx={{ mt: 4, width: '100%' }}>
                                    <TextField  id="outlined-basic" label="Formal Name(Currency)" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={6} >
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <FormControlLabel control={
                                    <Checkbox checked={props.blocked == 0? false : true} onChange={e => props.blockChange(e)} />
                                    } label="Blocked" />
                                </FormControl>
                            </Col>
                        </Row>
                        
                    </Col>
                    
                    <Col xs={12} md={6}>
                        <Row>
                            <Col md={12}>
                                <div style={{display: 'flex', marginTop: 20}}>
                                    <label>Company Logo</label>
                                    <input type="file" onChange={(e) => props.uploadImage(e)}  style={{marginLeft: 20}}/>
                                    <Button  variant="contained">Add Logo</Button>
                                </div>
                            </Col>
                            <Col xs={12} md={4} style={{marginTop: 32}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Valid From"
                                        value={props.dateFrom}
                                        onChange={(newValue) => {
                                            props.setDatefrom(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs={12} md={4} style={{marginTop: 32}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Valid To"
                                        value={props.dateTo}
                                        onChange={(newValue) => {
                                            props.setDateTo(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs={12} md={4} style={{marginTop: 17}}>
                                <label>Company Docs</label>
                                <input type="file" onChange={(e) => props.uploadImage(e)}  style={{marginTop: 5}}/>
                            </Col>
                            <Col md={12} >
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <TextareaAutosize 
                                        minRows={4}
                                        placeholder="Adddress"
                                        style={{background: 'transparent', width: '100%'}}
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} >
                                <FormControl sx={{width: '100%', mt:4 }}>
                                    <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Age"
                                        value={props.country}
                                        onChange={props.countryChange}
                                        size="small"
                                        
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {props.countrylist.map((item, index) => (
                                    <MenuItem value={item.country_id}>{item.country_name}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            {/* <Col xs={12} md={6} >
                                <FormControl sx={{width: '100%', mt:4 }}>
                                        <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Age"
                                            value={props.state}
                                            onChange={props.stateChange}
                                            size="small"
                                            
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {props.stateList.map((item, index) => (
                                        <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                                        ))}
                                        </Select>
                                </FormControl>
                            </Col> */}
                            <Col xs={12} md={6} style={{marginTop: 30}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Financial Year From"
                                        value={props.dateFrom}
                                        onChange={(newValue) => {
                                            props.setDatefrom(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs={12} md={6} style={{marginTop: 30}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Financial Year To"
                                        value={props.dateTo}
                                        onChange={(newValue) => {
                                            props.setDateTo(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs={12} md={6} style={{marginTop: 30}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Books Begining From"
                                        value={props.bookTo}
                                        onChange={(newValue) => {
                                            props.setBookTo(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col xs ={12} md={6} style={{marginTop:30}}>
                                <Button  onClick={props.onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                            </Col>
                        </Row> 
                    </Col>
                </Row>
        </div>
  );
}

export default AddCompany;
