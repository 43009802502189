import React, {Component, useEffect, useState, useRef} from 'react';
import './InvoiceBill.css';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

function SalesReplaceBill(){
    const { id } = useParams();
    const [date, setDate] = React.useState("")
    const [voucherno, setVoucherno] = React.useState("")
    const [details, setDetails] = React.useState([])
    const [salestotal, setTotal] = React.useState("")
    const [othercharges, setOthercharges] = React.useState("")
    const [customer, setCustomer] = React.useState("")
    const [building, setBuilding] = React.useState("")

    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [customername, setCustomername] = useState('')
    const [area, setArea] = useState('')
    const [remarks, setRemarks] = useState('')
    const [ordertype, setOrdertype] = useState('')
    const [street, setStreet] = useState('')

    const [zone, setZone] = useState('')
    const [mobile, setMobile] = useState('')
    const [lpo, setLpono] = useState('')
    const [tableRow, setTableRow] = useState([])
    const [reference, setReference] = useState('')
    const [salesman, setSalesman] = useState('')

    const [totalamount, setTotalamount] = useState('')
    const [roundoff, setRoundoff] = useState('')
    const [subtotal, setSubtotal] = useState('')

    const [variationamount, setVariationamount] = useState('')
    const [deliverycharge, setDeliverycharge] = useState('')
    const [replacereturn, setReplacereturn] = useState('')

    const [replacebefore, setReplacebefore] = useState('')

    const [replacedtot, setReplacedtot] = useState('')
    const [deliverytot, setDeliverytot] = useState(0)
    const [returntot, setReturntot] = useState('')
    const [initialdelivery, setInitialdelivery] = useState('')
    const [extradelivery, setExtradelivery] = useState('')
    const [discount, setDiscount] = useState('')
    const [advance, setAdvance] = useState('')
    const [balancereceive, setBalancereceive] = useState('')

    const [companyname, setCompanyname] = useState('')

    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        setCompanyname(userdata.company_name)
    }, [])

    // useEffect(() => {
    //     var total = parseFloat(value) - parseFloat(replacedtot)
    //     setDeliverytot(total) 
    // }, [value, replacedtot])

    // useEffect(() => {
    //     var totalamt = parseFloat(replacedtot) + parseFloat(deliverytot) + parseFloat(initialdelivery) + parseFloat(extradelivery) - parseFloat(discount) - parseFloat(advance)
    //     setBalancereceive(totalamt)
    // }, [replacedtot, deliverytot, initialdelivery, extradelivery, discount, advance ])
    
    console.log("id billNo", id)
    useEffect(() => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)

        var data = {
            "companyid": company,
            "salesorderid": id,
            "type": userdata.user_type
        }
        
        fetch(global.url + "viewSalesOrderDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("print details", responseJson)
            setCustomername(responseJson.data.customer_name)
            setBuilding(responseJson.data.customer_building)
            setArea(responseJson.data.customer_area)
            setRemarks(responseJson.data.customer_remarks)
            setDate(responseJson.data.sales_date)
            setOrdertype(responseJson.data.customer_whatsapp)
            setStreet(responseJson.data.customer_street)
            setZone(responseJson.data.sales_zone)
            setMobile(responseJson.data.customer_mobile)
            setLpono(responseJson.data.sales_invoice)
            setTableRow(responseJson.data.sales_details)
            setReference(responseJson.data.sales_reference)
            setSalesman(responseJson.data.sales_salesman)
            setOthercharges(responseJson.data.sales_other_charges)
            setRoundoff(responseJson.data.sales_roundoff)
            setSubtotal(responseJson.data.sales_subtotal)
            setTotalamount(responseJson.data.sales_total)
            setReplacedtot(responseJson.data.salesorder_replaceditemstotal)
            setReturntot(responseJson.data.salesorder_returneditemstotal)
            setInitialdelivery(responseJson.data.sales_other_charges)
            setExtradelivery(responseJson.data.salesorder_extradeliverycharge)
            setDiscount(responseJson.data.sales_discount)
            setAdvance(responseJson.data.salesorder_advanceifany)
            setBalancereceive(responseJson.data.salesorder_balancereceivable)
            if(responseJson.data.replacereturn == 1){
                setReplacereturn(responseJson.data.replacereturn)
                setVariationamount(responseJson.data.variation_amount)
                setDeliverycharge(responseJson.data.delivery_charge)
                var data = responseJson.data.variation_amount + responseJson.data.delivery_charge
                setTotalamount(data)
            }

            if(responseJson.data.salesorder_replacebefore == 1){
                setReplacebefore(responseJson.data.salesorder_replacebefore)
                setDeliverycharge(responseJson.data.salesorder_delivery_charge)
                setVariationamount(responseJson.data.salesorder_variation_amount)
                var data = responseJson.data.salesorder_variation_amount + responseJson.data.salesorder_delivery_charge
                setTotalamount(data)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }, []);

    // useEffect(() => {
    //     var totalAmount = 0;
    //     for (var i = 0; i < tableRow.length; i++) {
    //         totalAmount += tableRow[i].sd_total_amount
    //      }
    //      setTotalamount(totalAmount+10)
    // }, [tableRow]);

    const print = () => {
        // exportPDFWithMethod()
        exportPDFWithComponent()
        window.print()
    }

    const pdfExportComponent = React.useRef(null);

    // const exportPDFWithMethod = () => {
    //     let element = document.querySelector(".k-grid") || document.body;
    //     savePDF(element, {
    //       paperSize: "A4",
    //     });
    //   };

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
          pdfExportComponent.current.save();
        }
      };

    return(
        <div>
            <Container fluid >
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                        <Button onClick={print} variant="primary">Print</Button>
                        <div className="invoice-box printing">
                        <PDFExport
                            ref={pdfExportComponent}
                            paperSize="auto"
                            margin={40}
                            fileName={`Report for ${new Date().getFullYear()}`}
                            author="KendoReact Team"
                            >
                            <div className="invoice-inside">
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>{companyname}</p>
                                <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>Salesorder Invoice</p>
                                <Row>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عميل</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Customer: {customername}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Mob No: {mobile}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رقم القسيمة</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Voucher No: {lpo}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Building: {building}</p>
                                </Col>


                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Street: {street}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Zone: {zone}</p>
                                </Col>
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Area: {area}</p>
                                </Col>

                                {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Remarks: {remarks}</p>
                                </Col> */}
                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>بناءب</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Reference: {reference}</p>
                                </Col>

                                <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Date: {date}</p>
                                </Col>
                                {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Mode/Term of Payment: Cash</p>
                                </Col> */}
                                {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Other Reference(s): Cash</p>
                                </Col> */}
                                {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>من خلال إيفاد</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Despatch through: Qatar</p>
                                </Col> */}
                                {/* <Col xs={6}>
                                    <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>شروط التوصيل</p>
                                    <p style={{fontSize: 12, fontWeight: 500}}>Terms of Delivery: Qatar</p>
                                </Col> */}
                                </Row>    
                                <div className='invoice-line' style={{width: '100%', height: 2, backgroundColor: '#d2d2d2'}}></div>
                                <div>
                                    <Table bordered className='invoicetable'>
                                        <thead>
                                            <tr style={{borderBottomColor: '#000'}}>
                                            <th style={{width: '10%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عدد</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>SLNo.</p>
                                            </th>
                                            <th style={{width: '20%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>الرمز الشريطي</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Ref Code</p>
                                            </th>
                                            {/* <th style={{width: '35%'}}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>منتج</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Product</p></th> */}
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>كمية</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Qty</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>السعر</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Price(Qar)</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع</p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>Total(Qar)</p></th>
                                            </tr>
                                        </thead>
                                                <tbody>
                                                    {
                                                            tableRow.map((item, index) => {
                                                            return(
                                                                item.sod_iscancel == 1 ? 
                                                                null:
                                                                <>
                                                                    <tr>
                                                                        <td rowSpan={2}><p style={{marginTop: 20}}>{index+1}</p></td>
                                                                           {item.sales_iscombo == 0 ? 
                                                                            <td colSpan={4}>{item.productname}-{item.color}-{item.size}</td>:
                                                                            <td colSpan={4}>
                                                                               
                                                                                        {item.fetch_products.map((i,k) => (
                                                                                            <p style={{fontSize: 10, marginBottom: 5}}>{i.product_name}-{i.color}-{i.size}</p>
                                                                                        ))}
                                                                                             
                                                                                </td>  
                                                                            } 
                                                                    </tr>
                                                                    <tr style={{borderBottomColor: '#000'}}>
                                                                        
                                                                        <td>{item.code}</td>
                                                                        <td>{item.sd_qty}</td>
                                                                        <td style={{textAlign: "right"}}>{item.price}</td>
                                                                        <td style={{textAlign: "right"}}>{item.sd_total_amount}</td>
                                                                    </tr>
                                                                    
                                                                </>
                                                                
                                                            )
                                                    
                                                        })
                                                    }
                                                         {replacedtot == 0 ? null :                                    
                                                        <tr>
                                                            <th colSpan={4} style={{textAlign: 'end'}}>
                                                                {/* <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p> */}
                                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع العناصر المستبدلة/Replaced Items Total</p></th>
                                                            <td style={{textAlign: "right"}}> {parseFloat(replacedtot)?.toFixed(2)}</td>
                                                        </tr>}
                                                        {/* {deliverytot == 0 ? null :
                                                        <tr>
                                                            <th colSpan={3} style={{textAlign: 'end'}}>
                                                         
                                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع عناصر التوصيل/Delivery Items Total</p></th>
                                                            <td style={{textAlign: "right"}}>{parseFloat(deliverytot)?.toFixed(2)}</td>
                                                        </tr>} */}
                                                        {returntot == 0 ? null :
                                                        <tr>
                                                            <th colSpan={4} style={{textAlign: 'end'}}>
                                                                {/* <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p> */}
                                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>إجمالي العناصر التي تم إرجاعها/Returned Items Total</p></th>
                                                            <td style={{textAlign: "right"}}> {parseFloat(returntot)?.toFixed(2)}</td>
                                                        </tr>}
                                                        {/* {initialdelivery == 0 ? null :
                                                        <tr>
                                                            <th colSpan={3} style={{textAlign: 'end'}}>
                                                                <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p>
                                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رسوم التسليم الأولي/Initial delivery Charge</p></th>
                                                            <td style={{textAlign: "right"}}> {parseFloat(initialdelivery)?.toFixed(2)}</td>
                                                        </tr>} */}
                                                        {extradelivery == 0 ? null :
                                                        <tr>
                                                            <th colSpan={4} style={{textAlign: 'end'}}>
                                                                {/* <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p> */}
                                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رسوم التوصيل الإضافية/Extra delivery Charge</p></th>
                                                            <td style={{textAlign: "right"}}> {parseFloat(extradelivery)?.toFixed(2)}</td>
                                                        </tr>}
                                                        {discount == 0 ? null :
                                                        <tr>
                                                            <th colSpan={4} style={{textAlign: 'end'}}>
                                                                {/* <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p> */}
                                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تخفيض/Discount</p></th>
                                                            <td style={{textAlign: "right"}}> {parseFloat(discount)?.toFixed(2)}</td>
                                                        </tr>}
                                                        {/* {advance == 0 ? null :
                                                        <tr>
                                                            <th colSpan={3} style={{textAlign: 'end'}}>
                                                        
                                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>يتقدم/Advance</p></th>
                                                            <td style={{textAlign: "right"}}> {parseFloat(advance)?.toFixed(2)}</td>
                                                        </tr>} */}
                                                     
                                                        <tr>
                                                            <th colSpan={4} style={{textAlign: 'end'}}>
                                                                {/* <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي</p> */}
                                                            <p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>الرصيد المستحق/Balance Receivable</p></th>
                                                            <td style={{textAlign: "right"}}> {parseFloat(balancereceive)?.toFixed(2)}</td>
                                                        </tr>
                                                </tbody>
                                    </Table>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src={require("../../../assets/images/qrcode.png").default} style={{width: 50, height: 50}}/>
                                </div>
                                <div style={{marginTop: 30}} className="invoicebill">
                                    <ul className='billliststyle'>
                                        <li>Sales are non refundable</li>
                                        <li>Marchandise can only be exchanged upon presentation of a valid reciept within 7 days of the date of sale</li>
                                        <li>Used, Cleaned, altered or damaged marchandise will not be accepted for exchange</li>
                                        <li>Marchandise for exchange must inclued or iginal packing and labels</li>
                                    </ul>
                                </div>
                                <div style={{marginTop: 30}} className="invoicebill">
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Visit Again!</p>
                                    <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>{companyname}</p>
                                    {/* <p style={{textAlign: 'center', fontSize: 13, fontWeight: 600, marginBottom: 'unset'}}>Doha, Qatar</p> */}
                                </div>
                            </div>
                        </PDFExport>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}


export default SalesReplaceBill;