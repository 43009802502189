import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col,} from "react-bootstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const columns = [
    { id: '1', label: 'Ref code', minWidth: 170,span: false },
    { id: '1', label: 'Barcode', minWidth: 170,span: false },
    { id: '2', label: 'Product Name', minWidth: 170,span: false },
    { id: '3', label: 'Color', minWidth: 170,span: false },
    { id: '4', label: 'Size', minWidth: 170,span: false },
    { id: '5', label: 'Prev Stock', minWidth: 170,span: false },
    { id: '5', label: 'Current Stock', minWidth: 170,span: false },
    { id: '5', label: 'Changed Stock', minWidth: 170,span: false },
  ];

  const rows = [
    {
      demo: 'test',
    }
  ];

function Historystock(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [source, setSource] = useState([])
    const [downloadlink, setDownloadlink] = React.useState("");

    const [dateFrom, setDatefrom] = React.useState(new Date)
    const [dateTo, setDateTo] = React.useState(new Date)

    const [batchcode, setBatchcode] = useState("")
    
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
   getData()
  }, []);

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
        "companyid": company,
        "type": userdata.user_type,
        "branchid" : branch
    }
    fetch(global.url + "viewExcelCountedStock", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("stock report", responseJson)
        setSource(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const filter = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
        "companyid": company,
        "type": userdata.user_type,
        "branchid" : branch,
        "batchcode": batchcode,
        "fromdate":  dateFrom.toISOString().split('T')[0],
        "todate": dateTo.toISOString().split('T')[0],
    }
    console.log("stock viewExcelCountedStock", data)
    fetch(global.url + "viewExcelCountedStock", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("stock report", responseJson)
        setSource(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }



  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>History Stock Report</p>
               
            </div>
      
            <Row>
                <Col sm={3}>
                    <FormControl sx={{ width: '100%', mt: 3 }}>
                        <TextField 
                        onChange={(e) => setBatchcode(e.target.value)}
                        autoComplete="off" id="outlined-basic" label="Batch code" variant="outlined"  size="small"/>
                    </FormControl>
                </Col>

                <Col xs={12} md={3} style={{marginTop: 25}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="From"
                              value={dateFrom}
                              onChange={(newValue) => {
                                  setDatefrom(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 25}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="To"
                              value={dateTo}
                              onChange={(newValue) => {
                                  setDateTo(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                <Col sm={3}>
                    <Col xs={12} md={3} style={{marginTop: 20}}>
                        <Button onClick={filter}  style={{height: 45}} variant="contained">Filter</Button>
                    </Col>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell >
                                            {row.product_refcode}
                                        </TableCell>
                                        <TableCell >
                                            {row.pb_inter_barcode}
                                        </TableCell>
                                        <TableCell >
                                            {row.product_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.color_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.size_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.ecs_current_stock}
                                        </TableCell>
                                        <TableCell >
                                            {row.ecs_counted_stock}
                                        </TableCell>
                                        <TableCell >
                                            {row.ecs_changedstock}
                                        </TableCell>
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={source.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </Col>
            </Row>
          </Paper> 
      </div>
    )
}
export default Historystock;