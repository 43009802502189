import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
  { id: 'name', label: 'HSN Name', minWidth: 170,span: false },
  { id: 'name', label: 'AG code', minWidth: 170,span: false },
  { id: 'code', label: 'HSN Code', minWidth: 170,span: false },
  { id: 'code', label: 'Blocked', minWidth: 170,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 100,
    span: true
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  {
    country: 'Lorem Ipsum',
  },
  {
    country: 'Lorem Ipsum',
  },
];

function HSN(props) {
  const [btnloader, setBtnloader] = React.useState(false);
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")

  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');

  const [loader, setLoader] = React.useState(false);
  const [userId, setUserid] = React.useState("");
  const [source, setSource] = React.useState([]);

  const [hsnId, setHsnid] = React.useState("");
  const [hsnname, setHsnname] = React.useState("");
  const [hsncode, setHsncode] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);

  function setData(childData) {
    setToggle(childData)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData()
  }, []);

  const editClick = (
    hsnid, 
    hsncode,
    hsnname,
    blocked,
    ) => {
      setModal(true)
      setHsnid(hsnid)
      setHsncode(hsncode)
      setHsnname(hsnname)
      setBlocked(blocked)
  }

  const onAdd = () => {
    setModal(true)
      setHsnid("")
      setHsncode("")
      setHsnname("")
      setBlocked(0)
  }

  const blockChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }


  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUserid(userdata.user_id)
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewHSN", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          setLoader(false)
          if(responseJson.status == "false") {
            setSource(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const deleteClick = (id) =>  {
    var data = {
      "hsn_id": id,
      "user_id": userId
    }
    fetch(global.url + "deleteHSN", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.data)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const onSave = () => {
    setBtnloader(true)
    var data = {
      "hsn_name": hsnname,
      "hsn_userid": userId,
      "hsn_code": hsncode,
      "hsn_blocked": blocked,
      "hsn_id": hsnId,
      "hsn_branchid" : userbranchid,
      "hsn_companyid" : usercompanyid
  }
  console.log("vdsfbgsd", data)
    fetch(global.url + "updateHSN", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("responsejson", responseJson);
        if(responseJson.status == "false") {
          getData()
          setModal(false)
          alert(responseJson.message)
          setBtnloader(false)
        } else {
          alert(responseJson.message)
          setBtnloader(false)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>HSN</p>
               {
                    usertype == "superadmin" ? 
                    null : 
                  <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell >
                                  {row.hsn_name}
                              </TableCell>
                              <TableCell >
                                  {row.hsn_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.hsn_code}
                              </TableCell>
                              <TableCell >
                              {row.hsn_blocked  == "0" ? "No" : "Yes"}
                              </TableCell>
                              <TableCell >
                              {
                                    usertype == "superadmin" ? 
                                    null : 
                                    <a href="javascript:void(0)" onClick={() => 
                                      editClick(row.hsn_id, 
                                        row.hsn_code, 
                                        row.hsn_name, 
                                        row.hsn_blocked, 
                                      )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.hsn_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 HSN
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setHsnname(e.target.value)} value={hsnname} id="outlined-basic" label="HSN Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setHsncode(e.target.value)} value={hsncode} id="outlined-basic" label="HSN Code" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={5}>
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <FormControlLabel control={
                            <Checkbox checked={blocked == 0? false : true} onChange={e => blockChange(e)} />
                            } label="Blocked" />
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button  onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default HSN;
