import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const columns = [
  { id: '1', label: 'Sl No.', minWidth: 50,span: false },
  { id: '2', label: 'Main Group', minWidth: 50,span: false },
  { id: '3', label: 'Supplier Group', minWidth: 50,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 50,
    span: true
  },
];

function SubSuppier() {
  const [btnloader, setBtnloader] = React.useState(false);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [country, setCountry] = React.useState("");
  const [countryid, setCountryid] = React.useState("");
  const [source, setSource] = React.useState([]);
  const [grouplist, setGrouplist] = React.useState([]);
  const [group, setGroup] = React.useState("");
  const [subsupplier, setSubsupplier] = React.useState("");
  const [subsupplierid, setSubsupplierid] = React.useState("");

  useEffect(() => {
    getData()
  }, [])

const groupChange = (event) => {
    setGroup(event.target.value);
};

const getData = () => {
    setLoader(true)
    var company = JSON.parse(localStorage.getItem("company"))
var data = {
    "companyid" : company
}
  fetch(global.url + "viewSubSupplier", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("viewSubSupplier", responseJson)
      setLoader(false)
      if(responseJson.error == false) {
        setSource(responseJson.data)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
}

const getGroup = () => {
    setLoader(true)
    var company = JSON.parse(localStorage.getItem("company"))
var data = {
    "companyid" : company
}
  fetch(global.url + "viewProductsupplier", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("viewProductsupplier", responseJson)
      setLoader(false)
      if(responseJson.status == "false") {
        setGrouplist(responseJson.data)
      }
    })
    .catch((error)=>{
        console.log(error)
    })
}

const onAdd = () => {
  setModal(true)
  getGroup()
  setSubsupplier("")
  setSubsupplierid("")
  setGroup("")
}

const onSave = () =>{
  setBtnloader(true)
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
  var data = {
    "psubid" : subsupplierid,
    "psub_name" : subsupplier,
    "psub_maingroupid": group,
    "companyid" : company,
    "branchid" : branch
  }
  fetch(global.url + "createSubSupplier", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
      console.log("responsejson", responseJson);
      if(responseJson.error == false) {
        setModal(false)
        alert(responseJson.message)
        setBtnloader(false)
        getData()
      }else {
        alert(responseJson.message)
        setBtnloader(false)
      }
  })
  .catch((error)=>{
      console.log(error)
  })
}

const editClick = (
  supplierid,
  suppliername,
  group
) => {
  setModal(true)
  getGroup()
  setSubsupplierid(supplierid)
  setSubsupplier(suppliername)
  setGroup(group)
}

  return (
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Supplier Group - International Or Local</p>
               <Button onClick={onAdd} variant="contained">Add</Button>
            </div>
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source.map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                            <TableCell >
                                  {index + 1}
                              </TableCell>
                              <TableCell >
                                {row.maingroupname}
                              </TableCell>
                              <TableCell >
                                  {row.psub_name}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => editClick(
                                    row.psub_id,
                                    row.psub_name,
                                    row.maingroupid
                                  )}>
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={distlist.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>


          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Supplier Group Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                  <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Main Group</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={group}
                                label="Age"
                                onChange={groupChange}
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {grouplist.map((item, index) => (
                                  <MenuItem value={item.psupplier_id}>{item.psupplier_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12}>
                        <FormControl sx={{ mt: 3, width: '100%' }}>
                            <TextField onChange={(e) => setSubsupplier(e.target.value)} value={subsupplier} id="outlined-basic" label="Sub Supplier Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      {/* <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button> */}
                    </Col>
                  </Row>
              </Modal.Body>
           </Modal>
        </div>
  );
}

export default SubSuppier;
