import React, {Component, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import Addpage from './AddNew'

const columns = [
  { id: '1', label: 'Company Name', minWidth:50,span: false },
  // { id: '2', label: 'Local Name', minWidth:50,span: false },
  // { id: '3', label: 'Address', minWidth:50,span: false },
  // { id: '4', label: 'Telephone', minWidth:50,span: false },
  // { id: '5', label: 'Mobile', minWidth:50,span: false },
  // { id: '6', label: 'Email', minWidth:50,span: false },
  // { id: '7', label: 'Website', minWidth:50,span: false },
  // { id: '8', label: 'Pincode', minWidth:50,span: false },
  // { id: '9', label: 'Fax', minWidth:50,span: false },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 50,
    span: true
  },
];


function CompanyCreation() {
  const [btnloader, setBtnloader] = React.useState(false);
  const [usertype, setUsertype] = useState("")
  const [modal, setModal] = useState(false);

  const [loader, setLoader] = React.useState(false);
  const [source, setSource] = React.useState([]);
  const [blocked, setBlocked] = React.useState(0);
  const [countrylist, setCountryList] = React.useState([]);
  const [country, setCountry] = React.useState("");
  const [stateList, setStateList] = React.useState([]);
  const [state, setState] = React.useState("");
  const [dateFrom, setDatefrom] = React.useState(new Date)
  const [dateTo, setDateTo] = React.useState(new Date);
  const [image, setImage] = React.useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [custoname, setCustoname] = React.useState("");
  const [bookTo, setBookTo] = React.useState(new Date);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const countryChange = (event) => {
    setCountry(event.target.value)
  }

  const stateChange = (event) => {
    setState(event.target.value)
  }

  const uploadImage = (e) => {
    // console.log("files", e.target.files[0])
    setImage(e.target.files[0])
  }

const getCountry = () => {
    fetch(global.url + "viewCountry", {
      method: "GET",
      headers: {
          'Content-Type': 'application/json',
      },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false") {
          setCountryList(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onhide = () => {
    setModal(false)
  }

  const editClick = () => {
      setModal(true)
      getCountry()
      // getState()
  }

  const onAdd = () => {
    setModal(true)
    getCountry()
    // getState()
  }

  const blockChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
    beginDate()
    getData()
  }, [])

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setLoader(true)
    console.log("userdata id", userdata.user_id)
    if(userdata.user_id == 0) {
        var data = {
          "user_id": "",
          "type": "superadmin"
        }
        console.log("company data1", data)
        fetch(global.url + "viewcompany", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          console.log("data", responseJson)
            setSource(responseJson.data)
            setLoader(false)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    if(userdata.user_id != 0) {
        var data = {
          "user_id": userdata.user_id,
          "type": userdata.user_type
        }

        console.log("company data2", data)
        fetch(global.url + "viewcompany", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          console.log("data", responseJson)
            setSource(responseJson.data)
            setLoader(false)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
  }

  const beginDate = () => {
      var company = JSON.parse(localStorage.getItem("company"))
      var data = {
        "companyid": company
      }
      fetch(global.url + "beginDate", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("beginDate", responseJson)
          // if(responseJson.status == "true") {
          //     setPurchaseorderlist([])
          //     console.log("true")
          // }
          // else{
          //     console.log("false")
          //     setPurchaseorderlist(responseJson.data)
          // }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const onSave = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setLoader(true)
    console.log("userdata id", userdata.user_id)
        var data = {
          "companyid": company,
          "company_name" : custoname,
          "company_begindate" : bookTo
        }
        console.log("company data1", data)
        fetch(global.url + "updatecompany", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          console.log("updatecompany", responseJson)
          if(responseJson.status == "false"){
            alert(responseJson.message)
            getData()
            setModal(false)
          }else{
            alert(responseJson.message)
          }
           
        })
        .catch((error)=>{
            console.log(error)
        })
  }


  return (
        <div className="main-content">
        {modal == false ?
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Company Creation</p>
               {
                  usertype == "superadmin" ? 
                  null : 
                    <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 20 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell >
                            {row.company_name}
                              </TableCell>
                            
                              <TableCell >
                              {
                                    usertype == "superadmin" ? 
                                    null : 
                                    <a href="javascript:void(0)" onClick={() => 
                                      editClick()}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                              }
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.size_id)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>:
            <div>
                <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
                    <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Create Company</p>
                    <Button variant="contained"  onClick={onhide}>Back</Button>
                </div>
                <Addpage
                    country = {country}
                    countryChange = {countryChange}
                    countrylist = {countrylist}
                    state = {state}
                    stateChange = {stateChange}
                    stateList = {stateList}
                    dateFrom = {dateFrom}
                    setDatefrom = {setDatefrom}
                    dateTo = {dateTo}
                    setDateTo = {setDateTo}
                    blocked = {blocked}
                    blockChange = {blockChange}
                    uploadImage = {uploadImage}
                    onSave = {onSave}
                    custoname = {custoname}
                    setCustoname = {setCustoname}
                    bookTo = {bookTo}
                    setBookTo = {setBookTo}
                />
            </div>
          }

        </div>
  );
}

export default CompanyCreation;
