// import '../Tax-Settings/TaxSettings.css';
import React, {Component, useEffect, useState, useRef} from 'react';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Table } from "react-bootstrap";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { BiRefresh } from "react-icons/bi";
import { GoSettings } from "react-icons/go";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { HiSave } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdClearAll } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { AiOutlineClose, AiFillMinusCircle } from "react-icons/ai";
import { usePreviousMonthDisabled } from '@mui/lab/internal/pickers/hooks/date-helpers-hooks';
import Loader1 from '../loader/loader';
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const columns = [
    { id: '1', label: 'SI No.', minWidth: 50,span: false },
    { id: '2', label: 'Tax Name', minWidth: 50,span: false },
    { id: '3', label: 'Tax %', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
    },
    {
      company: 'Lorem Ipsum',
    },
  ];

function State(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [modal, setModal] = useState(false);
    const [modaldel, setModaldel] = useState(false);
    const [deletotalqty, setDeltotalqty] = React.useState('');
    const [delindex, setDelindex] = React.useState('');
    const [delprice, setDelprice] = React.useState('');
    const [delsodid, setDelsodid] = React.useState('');
    const [delqty, setDelqty] = React.useState('');
    const [date, setDate] = React.useState(new Date);
    const [tableRow,setTableRow] = React.useState([{key:"",product_batch_id:"",  newItem : 1 , newItem2 : "1" , product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "",color: "", size: "",colorsize: [],addnew: true, fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}] }])
    const [tableRow2,setTableRow2] = React.useState([{key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, sod_id: "",color: "", size: "",colorsize: [],addnew: true, fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}] }])
    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [editmode, setEditmode] = React.useState(false);
    const [addnew, setAddnew] = React.useState(false);

    const [loader, setLoader] = React.useState(false);
    const [btnloader, setBtnloader] = React.useState(false);
    const [replaceorder, setReplaceorder] = React.useState(0);
    // const [productname, setProductname] = React.useState("")
    // const [selectedIndex, setSelectedindex] = React.useState("")


    // api values
    const [amount, setAmount] = React.useState(0);
    const [deleteamt, setDeleteamount] = React.useState(0);
    const [addedamt, setAddedamt] = React.useState(0);
    const [extradelivery, setExtradelivery] = React.useState(0);
    const [advance, setAdvance] = React.useState(0);
    const [cashledger, setCashledger] = React.useState(0);
    const [initialtotal, setInitialtotal] = React.useState(0);
    const [crfc, setCrfc] = React.useState(0);
    const [deliveredtot, setDeliveredtot] = React.useState(0);
    const [arraydel, setArraydel] = React.useState([{sod_id: "", sod_qty: ""}])
    const [customerList, setCustomerlist] = React.useState([])
    const [customer, setCustomer] = React.useState("")
    const [userId, setUserid] = React.useState("")
    const [userList, setUserlist] = React.useState([])
    const [salesman, setSalesman] = React.useState("")
    const [billTypelist, setBilltypelist] = React.useState([])
    const [billType, setBilltype] = React.useState('');
    const [invoiceno, setInvoiceno] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [refno, setRefno] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [creditLimit, setCreditlimit] = React.useState('');
    const [balance, setBalance] = React.useState('');
    const [limitbalance, setLimitbalance] = React.useState('');
    const [quotation, setQuotation] = React.useState('');
    const [lpono, setLpono] = React.useState('');
    const [lpodate, setLpodate] = React.useState(new Date);
    const [delno, setDelno] = React.useState('');
    const [orderno, setOrderno] = React.useState('');
    const [salesid, setSalesid] = React.useState('');
    const [grandtotal, setGrandtotal] = React.useState(0);
    const [grandtotalex, setGrandtotalex] = React.useState(0);
    const [customerpricelevel, setCustomerpricelevel] = React.useState("");
    const [customerpricename, setCustomerpricename] = React.useState("");

    const [total, setTotal] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [otherCharges, setOthercharge] = React.useState(0);
    const [payable, setPayable] = React.useState('');
    const [roundoff, setRoundoff] = React.useState(0);
    const [salesorderid, setSalesorderid] = React.useState('');

    const [deliverycharge, setDeliverycharge] = React.useState(0);
    const [variationamount, setVariationamount] = React.useState(0);
    const [totalaftervariation, setTotalaftervariation] = React.useState(0);
    const [deletedAmount, setDeletedamount] = React.useState(0);

    const [replacedamt, setReplacedamt] = React.useState(0);
    const [diffamount, setDiffamount] = React.useState(0);
    const [ledgerName, setLedgerName] = React.useState("");
    const [ledgerList, setLedgerlist] = React.useState([]);

    useEffect(() => {
        setTableRow2(tableRow)
    }, [tableRow])

    const ledgerChange = (event) => {
        setLedgerName(event.target.value)
    }

    const [customername, setCustomername] = React.useState('');
    const [building, setBuilding] = React.useState('');
    const [area, setArea] = React.useState('');
    const [remarks, setRemarks] = React.useState('');
    const [ordertype, setOrdertype] = React.useState('');
    const [street, setStreet] = React.useState('');


    const [errormsg, seterrormsg] = React.useState('');

    const [productlist, setProductlist] = React.useState([]);

    const [colorlist, setColorlist] = React.useState([]);
    const [color, setColor] = React.useState("")

    const [code, setCode] = useState("");
    const [productname, setProductname] = useState("")
    const {id} = useParams()

    const [reference, setReference] = React.useState("");
    const [zone, setZone] = React.useState("");

    const [salesorderlist, setSalesorderlist] = React.useState([]);

    const [areashow, setAreashow] = React.useState(false);
    const [arealist, setArealist] = React.useState([]);

    const [cashReceived, setCashReceived] = React.useState("");
    const [balanReceived, setBalanReceived] = React.useState("");
   

    // const results = people.filter(({ name }) => query && name.toLowerCase().includes(query.toLowerCase()) );
    const hasResults = code.length > 0;
    const hasResultsProduct = productname.length > 0;

    const inputRef = useRef();
    const inputRef1 = useRef();
    const resultsRef = useRef();


    // comboproductadd

    const [combotable, setCombotable] = React.useState([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "", stock: ""}]);
    const [comboproductlist, setComboproductlist] = React.useState([]);
    const [combobatchlist, setCombobatchlist] = React.useState([{key: "", value: []}]);
    const [cbproduct, setCbproduct] = React.useState("");
    const [popupindex, setPopupindex] = React.useState("");
    const [combopopupid, setCombopopupid] = React.useState("");

    let history = useHistory();

    const [ordertypelist, setOrdertypelist] = React.useState([]);

    useEffect(() => {
        var totalamt = parseFloat(addedamt) + parseFloat(extradelivery) - parseFloat(discount)
        setCrfc(totalamt)
    }, [addedamt, extradelivery, discount ])

    useEffect(() => {
        var totalamt = parseFloat(addedamt) - parseFloat(deleteamt) + parseFloat(extradelivery) - parseFloat(discount)
        setBalanReceived(totalamt)
    }, [deleteamt, addedamt, extradelivery, discount ])

    // useEffect(() => {
    //     var totalamt = parseFloat(deleteamt) - parseFloat(addedamt)
    //     setCashledger(totalamt)
    // }, [deleteamt, addedamt])

    const discnull = () =>{
        setDiscount("")
    }
    const extranull = () =>{
        setExtradelivery("")
    }
    const advancenull = () =>{
        setAdvance("")
    }

  const billtypeChange = (event) => {
    setOrdertype(event.target.value);
  };

  useEffect(() => {
    getOrdertype()
  }, [])

  const getOrdertype = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
        "companyid" : company,
    }
    fetch(global.url + "showOrderTypelist", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("ordertype respo", responseJson)
        setOrdertypelist(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const cbproductChange = (event, index) => {
      const input = [...combotable]
      input[index].productid = event.target.value
      setCombotable(input)
      getBatchlist(event.target.value,index)
  }

  const cbbatchChange = (event, index) => {
    const input = [...combotable]
    input[index].fetch_id = event.target.value
    console.log("bachid", event.target.value)
    setCombotable(input)
    getProductbybatch(event.target.value, index)
  }

  const colorChange = (event) => {
      var input=[...tableRow]
      console.log("selectedIndex", selectedIndex)
      input[selectedIndex].product_batch_id = event.target.value
      setTableRow(input)
      
      getProductbybatch1(event.target.value, selectedIndex)
    //   setColor(event.target.value)
  }

  const getProductbybatch = (id, index) => {
   
      var data = {
          "pb_id" : id,
      }
      console.log("data", data)
      fetch(global.url + "getProductBatchById", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{

          var comborow = [...combotable]
          comborow[index].color = responseJson.data.color_name
          comborow[index].size = responseJson.data.size_name
          comborow[index].stock = responseJson.data.pb_stock
          setCombotable(comborow)
          
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const getLedger = () => {
        fetch(global.url + "LedgerSelection", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log("res ledge", responseJson)
            if(responseJson.error == false){
              setLedgerlist(responseJson.data)
              setLedgerName(responseJson.data[3].ledger_id)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getProductbybatch1 = (id, index) => {
   
        var data = {
            "pb_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getProductBatchById", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
  
            setDetails(id, index, responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
      }

    const setDetails = (id, index, responseJson) => {

        var input = [...tableRow]
        input[index].key = index
        input[index].product_batch_id = id
        
        input[index].pb_stock = responseJson.pb_stock

        
        
        input[index].sod_qty = 1
        input[index].unit = responseJson.unit_code
        input[index].color = responseJson.color_name
        input[index].size = responseJson.size_name
        
        input[index].price = responseJson.pb_salesrate
        input[index].calcprice = responseJson.pb_salesrate
        input[index].discount = 0 

        input[index].sod_total_amount = responseJson.pb_salesrate 

        if(input[index].newItem){
            input[index].newItem2 = 0 
        }

       
            
            
            console.log("input", input)
            setTableRow(input)
            // setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            // setCode("")
            
            // var total1 = total + responseJson.pb_salesrate
            // console.log("totalcalc", total1)
            // setTotal(total1)
    }

  const getBatchlist = (id, index) => {
      console.log("id", id)
      console.log("id", combopopupid)
        var data = {
            "products_id" : id,
            "products_id_master" : combopopupid
        }
        console.log("data", data)
        fetch(global.url + "getProductbyIdData", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("product list", responseJson)
            var input = [...combobatchlist]
            input[index].value = responseJson.data
            setCombobatchlist(input)

            var comborow = [...combotable]
            comborow[index].product_name = responseJson.master.product_name
            comborow[index].fetch_quantity = 1
            comborow[index].quantity = responseJson.master.fc_product_quantity
            setCombotable(comborow)
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const userChange = (event) => {
    setSalesman(event.target.value);
  };

    const addRow = () => {
        setAddnew(true)
        setTableRow([...tableRow, {key:"", newItem : 1 , newItem2 : "1" ,product_batch_id:"", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "", sod_id: "",color: "", size: "",colorsize: [],addnew: true, fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}]}])
    }

    const customerChange = (event) => {
        setCustomer(event.target.value);
        getCustomerbyid(event.target.value)
        getCustomerbalance(event.target.value)
    };

    const getCustomerbalance = (id) => {
        var data = {
            "customer_id":  id
        }
        fetch(global.url + "viewCustomerBalance", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            if(responseJson.error == false) {
                console.log("customer Balance", responseJson)
                setBalance(responseJson.opening_balance)
                setLimitbalance(responseJson.remain_balance)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getCustomerbyid = (id) => {
        var data = {
            "customer_id":  id
        }
        fetch(global.url + "getCustomerbyId", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("one customer", responseJson)
            
            // setCreditlimit(responseJson.data.customer_credit_limit)
            // setAddress(responseJson.data.customer_address1)
            // setMobile(responseJson.data.customer_mobile)
            // setCustomerpricelevel(responseJson.data.pricelevel_percentage)
            // setCustomerpricename(responseJson.data.pricelevel_name)
            // setTableRow([{key:"",product_batch_id:"", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: "", pb_stock: "", sales_iscombo: "", sod_id: "",color: "", size: "",colorsize: [],  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",fetch_quantity: "", quantity: "",  stock: ""}] }])
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const inputHandler = (text, key)=>{
        // setSelectedindex(key)
        // setProductname(text)
        // console.log("text", text)
        // const _inputs = [...example];
        // _inputs[key].value = text;
        // _inputs[key].key   = key;
        // console.log("inputs", _inputs)
        // setExample(_inputs)
    }

    function onKeyDown(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef.current.focus();
          }
        }
      }

      function onKeyDown1(event) {
        const isUp = event.key === 'ArrowUp';
        const isDown = event.key === 'ArrowDown';
        const inputIsFocused = document.activeElement === inputRef1.current;
    
        const resultsItems = Array.from(resultsRef.current.children)
    
        const activeResultIndex = resultsItems.findIndex(child => {
          return child.querySelector('a') === document.activeElement;
        });
    
        if ( isUp ) {
          console.log('Going up!');
          if ( inputIsFocused ) {
            resultsItems[resultsItems.length - 1].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex - 1] ) {
            resultsItems[activeResultIndex - 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
    
        if ( isDown ) {
          console.log('Going down!')
          if ( inputIsFocused ) {
            resultsItems[0].querySelector('a').focus();
          } else if ( resultsItems[activeResultIndex + 1] ) {
            resultsItems[activeResultIndex + 1].querySelector('a').focus();
          } else {
            inputRef1.current.focus();
          }
        }
      }

      useEffect(() => {
   
        getBilltype()
        getUser()
     

        if(id == "add") {
            getInvoiceno()
            setEditmode(false)
        }
        else{
            setLoader(true)
           setSalesorderid(id)
           getDetailsbyid(id)
           setEditmode(true)
        }
      }, []);

      useEffect(() => {
        getCustomer()
        getLedger()
      }, []);

    //   const roundOffchange = () => {
    //       console.log("roundoff", roundoff)
    //         var round = grandtotal - roundoff
    //         var grandtotal1  =  round
    //         setGrandtotal(grandtotal1)
    //         setPayable(grandtotal1)
    //     }

      useEffect(() => {
        getTotalamount()
      }, [tableRow, discount, roundoff, otherCharges, deliverycharge]); 

    //   useEffect(() => {
    //     setGrandtotal(grandtotalex)
    //     setPayable(grandtotalex)
    //   }, [roundoff]);


      useEffect(() => {
        if(amount > 200) {
            otherChargeschange(0)
        } 
        else{
            otherChargeschange(10)
        }
      }, [amount]);

     

      const getTotalamount = () => {
        var totalAmount = 0;
        var totalAmount1 = 0;
        for (var i = 0; i < tableRow.length; i++) {
          totalAmount += parseInt(tableRow[i].sod_total_amount) 
          if(tableRow[i].sod_id == "" && tableRow[i].product_batch_id != "") {
            totalAmount1 += parseInt(tableRow[i].sod_total_amount)
          }
       }
        setAmount(parseFloat(totalAmount)-parseFloat(deleteamt))
        var disc = discount
        console.log("totalAmount1", totalAmount1)
        console.log("diffamount", diffamount)
        setGrandtotal(parseFloat(amount) - parseFloat(disc) + parseFloat(extradelivery) + parseFloat(deliverycharge) - parseFloat(advance))
        // setGrandtotalex(parseFloat(totalAmount) - parseFloat(disc) + parseFloat(extradelivery) - parseFloat(roundoff))
        // setPayable(totalAmount-disc+otherCharges-roundoff)
        // setVariationamount(totalAmount1 + diffamount)
        // setTotalaftervariation(totalAmount1 + diffamount + parseInt(deliverycharge))
        // setVariationamount(totalAmount1 - deletedAmount)
        // setTotalaftervariation(totalAmount1 - deletedAmount + parseInt(deliverycharge))
    }

    const totalDiscountchange = (event) => {
        setDiscount(event)
        // var result = amount - event
        // var grandtotal1  =  result + parseFloat(otherCharges) 
        // setGrandtotal(grandtotal1)
        // setGrandtotalex(grandtotal1)
        // setPayable(grandtotal1)
    }

    

    const otherChargeschange = (event) => {
        if(event == "") {
            console.log("empty")
            setOthercharge(0)
        }
        else{
            setOthercharge(event)
        }
    }

    //   useEffect(() => {
    //         setGrandtotal(grandtotalex)
    //         setPayable(grandtotalex)
    //         var disc = discount
    //         var result = (amount - disc) + parseInt(otherCharges) 
    //         if(result) {
    //             setGrandtotal(result)
    //             setPayable(result)
    //         }
    //   }, [otherCharges])

    

      const getDetailsbyid = (id) => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
          var data = {
                "companyid": company,
                "salesorderid": id,
                "type": userdata.user_type,
                "branchid" : branch
            }
        fetch(global.url + "viewSalesOrderShowAlsoCancel", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("salesorder head", responseJson.data)
            if(responseJson.status == "false") {
                setCustomername(responseJson.data.customer_name)
                setBuilding(responseJson.data.customer_building)
                setArea(responseJson.data.customer_area)
                setRemarks(responseJson.data.salesorder_narration)
                setOrdertype(responseJson.data.customer_whatsapp)
                setStreet(responseJson.data.customer_street)
                setZone(responseJson.data.sales_zone)
                setMobile(responseJson.data.customer_mobile)
                setInvoiceno(responseJson.data.sales_invoice)
                setInitialtotal(responseJson.data.sales_subtotal)
                setReference(responseJson.data.sales_reference)
                setSalesman(responseJson.data.sales_salesman)
                setTableRow(responseJson.data.sales_details)
                setCashReceived(responseJson.data.salesorder_cashledger)
                if(responseJson.data.sales_details != undefined) {
                    // setTableRow(responseJson.data.sales_details)
                    var deleteamt = responseJson.data.replaced_amount - responseJson.data.cancelled_products
                    setReplacedamt(responseJson.data.replaced_amount)
                    setDeletedamount(responseJson.data.cancelled_products)
                    // setVariationamount(deleteamt)
                    setDiffamount(deleteamt)
                    setDeliverycharge(responseJson.data.sales_other_charges)

                    setReplaceorder(responseJson.data.salesorder_replacebefore)
                }
                else{
                    // setTableRow([])
                    var deleteamt = responseJson.data.replaced_amount_2 - responseJson.data.cancelled_products_2
                    setReplacedamt(responseJson.data.replaced_amount_2)
                    setDeletedamount(responseJson.data.cancelled_products_2)
                    // setVariationamount(deleteamt)
                    setDiffamount(deleteamt)
                    setDeliverycharge(responseJson.data.sales_other_charges)

                    setReplaceorder(responseJson.data.salesorder_replacebefore)
                }
                setRoundoff(responseJson.data.sales_roundoff)
                // setDiscount(responseJson.data.sales_discount)
                setOthercharge(responseJson.data.sales_other_charges)
                setLoader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
      }

      const getInvoiceno = () => {
        fetch(global.url + "salesorderinvoiceno", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("salesinvoiceno", responseJson.data.invoiceno)
              setInvoiceno(responseJson.data.invoiceno)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getUser = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.userdata)
    
        
        var data = {
          "companyid": company,
          "usersType": userdata.userdata,
          "branchid" : branch
        }
        fetch(global.url + "viewDeliveryboy", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("user response", responseJson)
              setUserlist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }


      const getBilltype = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
  
        var data = {
          "type":  userdata.user_type,
          "companyid": company
        }
        fetch(global.url + "viewBillSetting", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("list response", responseJson)
              setBilltypelist(responseJson.data)
          })
          .catch((error)=>{
              console.log(error)
          })
      }

      const getCustomer = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        var data = {
            "type":  userdata.user_type,
            "companyid": company
        }
        fetch(global.url + "viewCustomer", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response", responseJson)
              if(responseJson.Error == "false") {
                setCustomerlist(responseJson.data)
                console.log("response", responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    //   useEffect(() => {
    //     var creditbal = creditLimit - balance;
    //     console.log("creditbal", creditbal)
    //     setLimitbalance(creditbal)
    //   }, [creditLimit])

      useEffect(() => {
        if ( hasResults ) {
          document.body.addEventListener('keydown', onKeyDown);
        } else {
          document.body.removeEventListener('keydown', onKeyDown);
        }

        if ( hasResultsProduct ) {
            document.body.addEventListener('keydown', onKeyDown1);
        } else {
            document.body.removeEventListener('keydown', onKeyDown1);
        }

        
        return () => {
          document.body.removeEventListener('keydown', onKeyDown);
          document.body.removeEventListener('keydown', onKeyDown1);
        }
      }, [hasResults, hasResultsProduct]);

      const listClickcode = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage, stock, iscombo, color, size, colorsize1) => {
            console.log("color1", colorsize1)
            console.log("customer percentege", customerpricelevel)
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].pb_stock = stock
            input[index].productname = name
            input[index].sales_iscombo = iscombo
            input[index].sod_qty = qty
            input[index].unit = unit
            input[index].color = color
            input[index].size = size
            input[index].addnew = true
            input[index].fetch_products = [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: ""}]

            
            
            console.log("no customer")
                
            console.log("no customer no per")
            input[index].price = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            if(discount) {
            
                var totalcalc = (calcprice/100) * discount
            }
            else {
            
                var totalcalc = 0
            }
            input[index].pricelevel = pricelevel
            input[index].sod_total_amount = calcprice - totalcalc

            
            
            
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"",  newItem : 1 , newItem2 : "1" , product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [], addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            setCode("")
            
            var total1 = total + totalcalc
            console.log("totalcalc", total1)
            setTotal(total1)
      }

      const listClickrefcode = (index, productid, productrefcode,productname,products, iscombo) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].products_id = productid
            input[index].code = productrefcode
            input[index].sales_iscombo = iscombo
            input[index].productname = productname
            input[index].colorsize = products
            input[index].addnew = true
            setSelectedindex(index)
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"",  newItem : 1, newItem2 : "1" , product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sales_iscombo: "",sod_id: "",color: "", size: "",colorsize: [],addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            setCode("")
        }

      const listClickname = (index, batchid, code, name, pricelevel, qty, unit, salesrate, calcprice, discount, amount, percentage) => {
            var input = [...tableRow]
            input[index].key = index
            input[index].product_batch_id = batchid
            input[index].code = code
            input[index].productname = name
            input[index].pricelevel = pricelevel
            input[index].sod_qty = qty
            input[index].unit = unit
            input[index].price = salesrate
            input[index].calcprice = calcprice
            input[index].discount = discount
            
            input[index].sod_total_amount = calcprice
            setTableRow([...tableRow, input])
            setTableRow([...tableRow, {key:"",product_batch_id:"",product_id: "", code: "", productname: "", pricelevel: "", sod_qty: "", unit: "", price: "", calcprice: "", discount: "", sod_total_amount: 0, pb_stock: "", sod_id: "",color: "", size: "",colorsize: [],addnew: true,  fetch_products: [{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "",  fetch_quantity: "", quantity: "",  stock: ""}] }])
            setProductname("")
    }

      const deleteRow = (index, price, sodid, qty) => {
        setModaldel(true)
        setDeltotalqty(qty)
        setDelindex(index)
        setDelprice(price)
        setDelsodid(sodid)
      }

      const DeleteQtyClick = () => {
            setModaldel(false)
            setTotal(total-delprice)
            var input = [...tableRow]
            var input2 = [...tableRow2]
            input[delindex].sod_iscancel = 1
            input2[delindex].sod_iscancel = 1
            input2[delindex].sod_qty = delqty
            var totalprice = input2[delindex].price * delqty
            input2[delindex].sod_total_amount = totalprice
            console.log("input", input)
            setTableRow(input)
            setTableRow2(input2)
            var array = [...arraydel]
            array[delindex].sod_id = delsodid
            array[delindex].sod_qty = delqty
            // array.push(sodid)
            setArraydel(array)
            
      }

      const disountChange = (event, index) => {
        var input = [...tableRow]
        input[index].discount = event.currentTarget.value
        var disc = (input[index].calcprice/100) * input[index].discount
        input[index].sod_total_amount = input[index].calcprice - disc
        setTableRow(input)
      }


      const handleOnChange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].code = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setCode(event.currentTarget.value);
        getSearch(event.currentTarget.value, type)
    }

    const handleProductchange = (event, key, type) => {
        var textArray1 = [...tableRow]
        textArray1[key].productname = event.currentTarget.value;
        setTextarray([...tableRow, textArray1])
        setSelectedindex(key)
        setProductname(event.currentTarget.value);
        console.log("type", type)
        getSearch(event.currentTarget.value, type)
    }

    // for calculating quantity * price

    const qtyChange = (event, index) => {
        var textArray1 = [...tableRow]
        if(event.target.value > textArray1[index].pb_stock){
            seterrormsg("Can't enter qty greater than stock")
        }
        else{
            seterrormsg("")
            console.log("price", textArray1[index].price)
            var totalprice = textArray1[index].price * event.target.value
            textArray1[index].sod_qty = event.target.value
            textArray1[index].calcprice = totalprice
            textArray1[index].sod_total_amount = totalprice
            setTableRow(textArray1)
        }
    }

    const getSearch = (text, type) => {
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))

        if(type == "code") {
            var data = {
                "type" : type,
                "product_code":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        else {
            var data = {
                "type" : type,
                "refcode":  text,
                "companyid" : company,
                "branchid" : branch
            }
        }
        console.log("searchdata", data)
        
        fetch(global.url + "getSearchOnlyStockprd", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setProductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const onSave = () => {
        
        if(mobile.length < 8 ) {
            alert("8 Digit in mobile Number required")
        }
        else{
            var paymentdate;
            var lpodate1;
            // if(editmode == true) {
            //     paymentdate = date.split('T')[0]
            //     lpodate1 = lpodate.split('T')[0]
            // }
            // else{
            //     paymentdate = date.toISOString().split('T')[0]
            //     lpodate1 = lpodate.toISOString().split('T')[0]
            // }
    
            const exampleArray = []
            var exampleSubarray = false
            for(var i = 0 ; i< tableRow.length; i++) {
                if(tableRow[i].product_batch_id == "" || tableRow[i].sales_isedit == 1 || tableRow[i].sod_id != "" ) {
                    // tableRow.splice(i, 1)
                }
                else{
                    console.log("fetchproducts1",tableRow[i].fetch_products)
                    if(tableRow[i].fetch_products != undefined){
                        for(var j = 0 ; j< tableRow[i].fetch_products.length; j++) {

                            if(tableRow[i].fetch_products[j].fetch_id == "" && tableRow[i].sales_iscombo == 1){
                                alert("Empty Product in Combo product, Cannot Save Order")
                                exampleSubarray = true
                            }
                            else{
                                
                            }
                        }
                    }
              if(tableRow[i].sod_iscancel == 1){

              }else{
                exampleArray.push(tableRow[i])
              }
                    
                } 
            }

            if(exampleSubarray == false){
                setBtnloader(true)
                console.log("tableRow", exampleArray)
                var data = {
                    "salesorder_id" : "",
                    "salesorder_user_id" : userId,
                    "salesorder_branchid":  userbranchid,
                    "salesorder_companyid":  usercompanyid,
                    "salesorder_bill_type":  billType,
                    "salesorder_ref_no":  refno,
                    "salesorder_salesman":  salesman,
                    "salesorder_invoice":  invoiceno,
                    "salesorder_date":  "",
                    "salesorder_customer":  customer,
                    "salesorder_crdt_limit":  "",
                    "salesorder_balance":  "",
                    "salesorder_cash_customer":  "",
                    "salesorder_limit_balance":  "",
        
                    "salesorder_other_charges":  "",
                    "salesorder_advance":  "",
                    "salesorder_current_bill":  "",
                    "salesorder_due_date":  "",
                    "salesorder_zone":  zone,
                    "salesorder_reference":  reference,
                    "salesorder_narration":  remarks,
                    "salesorder_date": date,
                    "salesorder_total": amount,
                    "salesorder_discount": discount,
                    "salesorder_other_charges": deliverycharge,
                    "salesorder_subtotal": 0,
                    "salesorder_roundoff": roundoff,
                    "customer_name": customername,
                    "customer_building": building,
                    "customer_street": street,
                    "salesorder_zone": zone,
                    "customer_mobile": mobile,
                    "customer_whatsapp": ordertype,
                    "customer_area": area,
                    "customer_remarks": remarks,
                    "salesorder_details": exampleArray,
                    "variation_amount": variationamount,
                    "delivery_charge": "",
                    "salesorder_replacebefore": replaceorder,
                    "sales_ordertype": 2,
                    "salesorder_returneditemstotal" : deleteamt,
                    "salesorder_replaceditemstotal" : addedamt,
                    "salesorder_extradeliverycharge" : extradelivery,
                    "salesorder_advanceifany" : advance,
                    "salesorder_cashledger" : crfc,
                    "salesorder_ledger_fk" : ledgerName,
                    // "salesorder_cashfromcustomer" : crfc,
                    "salesorder_balancereceivable" : balanReceived, 
                    "salesorder_deleted" : arraydel
                }
                console.log("data salesOrderReplace", data)
                fetch(global.url + "salesOrderReplace", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                .then((response)=> response.json())
                .then((responseJson)=>{
                    console.log("response", responseJson)
                    setBtnloader(false)
                    if(responseJson.error == false) {
                        alert(responseJson.message)
                        window.open('/saleorderreplacebill/' + responseJson.data , "_blank")
                        window.location.reload()
                    }
                    else{
                        alert(responseJson.message)
                    }
                })
                .catch((error)=>{
                    setBtnloader(false)
                    
                    console.log(error)
                })
            }
        }
    }

    const addProduct = (id, index, comborows, comboid) => {
        console.log("popupid", comboid)
        setModal(true)
        setCombopopupid(comboid)
        setPopupindex(index)
        var data = {
            "products_id" : id,
        }
        console.log("data", data)
        fetch(global.url + "getProductbyFetchID", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("product list", responseJson)
            setComboproductlist(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
        setCombotable(comborows)
    }

    const addComborow = () => {
        setCombotable([...combotable, {key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}])
        setCombobatchlist([...combobatchlist, {key: "", value: []}])
    }

   

    const addtoTablerow = () => {
        var counts = [];
        const exampleArray = []
        
            for(var i = 0 ; i < combotable.length; i++) {
                exampleArray.push(combotable[i].productid)
            }
    
            for(var i = 0 ; i < combotable.length; i++) {
                counts.push({value: "", count: 0})
                var input = [...counts]
                input[i].value = combotable[i].productid
                exampleArray.forEach((v) => (v === combotable[i].productid && counts[i].count++ ));
            }
            
            for(var i = 0 ; i < counts.length; i++) {
                for(var j = 0 ; j < combotable.length; j++) {
                    
                    if(counts[i].value == combotable[j].productid){
                        console.log("if", i)
                        if(counts[i].count > combotable[j].quantity){
                            console.log("2if")
                            alert("greater than max quantity - " + combotable[j].product_name)
                            return;
                        }
                    }
                    
                }
            }
            console.log("combotable", combotable)
            console.log("exampleArray", exampleArray)

            if(exampleArray.length == 0){
                alert("No Products added")
            }else{
                var input = [...tableRow]
                console.log("combotable",combotable )
                input[popupindex].fetch_products = combotable
                setTableRow(input)
                console.log("tableRow",tableRow )
                setModal(false)
                setCombotable([{key: "", productid: "", color: "", size: "", fetch_id: "", product_name: "", fetch_quantity: "", quantity: "",  stock: ""}])
            }
        
    }

    const deletePopupitem = (index) => {
       console.log("delete index", index)
        var input = [...combotable]
        input.splice(index, 1)
        setCombotable(input)
    }

    const onPopup = () => {
        setModal(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
            "type":  userdata.user_type,
            "companyid": company
        }
        console.log("data", data)
        fetch(global.url + "viewPurchaseorder", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("purchase response", responseJson)
            if(responseJson.status == "true") {
                setSalesorderlist([])
            }
            else{
                setSalesorderlist(responseJson.data)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const clear = () => {
        history.push('/transaction/Salesorder/add')
        window.location.reload()
    }

    const areaChange = (e) => {
        setArea(e.target.value)
    }

    const setFields = (e) => {
        setZone(e)
        switch (e) {
        case "1":
            setArea("Doha - Al Jasra")
            break;
        case '2':
            setArea("Doha - Al Bidda")
            break;
        case '3':
            setArea("Doha - Mushaireb")
            break;
        case '4':
            setArea("Doha - Mushaireb")
            break;
        case '5':
            setArea("Doha -Fereej Al Asmakh")
            break;
        case '6':
            setArea("Doha - Old Al Ghanim")
            break;
        case "7":
            setArea("Doha - Al Souq (Souq Waqif)")
            break;
        case '12':
            setAreashow(true)
            var array = [
                {"name": "Bidda"},
                {"name": "Rumaila"},
                {"name": "Wadi Al Sail"}]
            setArealist(array)
            break;
       
        case '13':
            setArea("Doha - Mushaireb")
            break;
        case '14':
            setArea("Doha - Fereej Abdel Aziz")
            break;
        case '15':
            setArea("Doha - Doha Al Jadeeda")
            break;
        case "16":
            setArea("Doha - Old Al Ghanim")
            break;
        case '17':
            setAreashow(true)
            var array = [
                {"name": "Doha - Al Ruffaa"},
                {"name": "Al Hitmi"}]
            setArealist(array)
            break;

        case '18':
            setArea("Doha - Al Mirqab")
            break;
        case '19':
            setArea("Doha - Doha Port")
            break;
        case '20':
            setArea("Doha - Wadi Al Sail")
            break;
        case '21':
            setArea("Doha - Al Rumaila")
            break;
        case '22':
            setArea("Bin Mahmoud")
            break;
        case '23':
            setArea("Bin Mahmoud")
            break;
        case '24':
            setAreashow(true)
            var array = [
                {"name": "Doha"},
                {"name": "Rawdat Al Khail"},
                {"name": "Al Muntazah"}]
            setArealist(array)
            break;
        case '25':
            setAreashow(true)
            var array = [
                {"name": "Doha"},
                {"name": "Mansoura"},
                {"name": "Bin Dirhem"}]
            setArealist(array)
            break;
        
        case '26':
            setArea("Doha - Najma")
            break;
        case '27':
            setArea("Doha - Umm Ghuwailina")
            break;
        case '28':
            setAreashow(true)
            var array = [
                {"name": "Doha"},
                {"name": "Al Khulaifat"},
                {"name": "Ras Bu Abboud"}]
            setArealist(array)
            break;
        case '29':
            setArea("Doha - Ras Bu Abboud")
            break;
        case '30':
            setArea("Duhail")
            break;
        case "31":
            setArea("Umm Lekhba")
            break;
        case '32':
            setAreashow(true)
            var array = [
                {"name": "Madinat Khalifa North"},
                {"name": "Dahl Al Hamam"},]
            setArealist(array)
            break;
        case '33':
            setArea("Markhiya")
            break;
        case '34':
            setArea("Madinat Khalifa South")
            break;
        case '35':
            setArea("Fereej Kulaib")
            break;
        case '36':
            setArea("Al Messila")
            break;
        case "37":
            setAreashow(true)
            var array = [
                {"name": "New Al Hitmi"},
                {"name": "Bin Omran"},
                {"name": "Hamad Medical City"}]
            setArealist(array)
            break;

        case '38':
            setArea("Al Sadd")
            break;
        case '39':
            setAreashow(true)
            var array = [
                {"name": "Al Nasr"},
                {"name": "Al Sadd"},
                {"name": "Al Mirqab Al Jadeed"}]
            setArealist(array)
            break;
        case '40':
            setArea("New Slata")
            break;
        case '41':
            setArea("Nuaija")
            break;
        case '42':
            setArea("Hilal")
            break;
        case '43':
            setAreashow(true)
            var array = [
                {"name": "Maamoura"},
                {"name": "Nuaija"},
                {"name": "Fereej Al Ali"},
                {"name": "Fereej Al Asiri"},
                {"name": "New Fereej Al Khulaifat"},]
            setArealist(array)
            break;
        case '44':
            setArea("Nuaija")
            break;
        case '45':
            setArea("Old Airport")
            break;
        case "46":
            setArea("Thumama")
            break;
        case '47':
            setArea("Thumama")
            break;
        case '48':
            setArea("Doha International Airport")
            break;
        case '49':
            setAreashow(true)
            var array = [
                {"name": "Hamad International Airport"},
                {"name": "Banana Island"},
                {"name": "Ras Bu Fontas"},]
            setArealist(array)
            break;
        case '50':
            setArea("Thumama")
            break;
        case '51':
            setAreashow(true)
            var array = [
                {"name": "Bani Hajer"},
                {"name": "Al Seej"},
                {"name": "Al Themaid"},
                {"name": "Rawdat Al Jahhaniya"},
                {"name": "Rawdat Egdaim"},
                {"name": "Shariya"},
                {"name": "Gharaffat Al Rayan"},
                {"name": "Izghawa"},]
            setArealist(array)
            break;
        case "52":
            setAreashow(true)
            var array = [
                {"name": "Luqta"},
                {"name": "Old Al Rayyan"},
                {"name": "Al Shaqab"},
                {"name": "Lebday"},
                {"name": "Education City"},]
            setArealist(array)
            break;
        case '53':
            setAreashow(true)
            var array = [
                {"name": "New Al Rayyan"},
                {"name": "Muaither"},
                {"name": "Wajba"},
                {"name": "Rawdat Abal Heeran"},
                {"name": "Umm Al Juwashen"},]
            setArealist(array)
            break;
        case '54':
            setAreashow(true)
            var array = [
                {"name": "Baaya"},
                {"name": "Muraikh"},
                {"name": "Mehairja"},
                {"name": "Fereej Al Amir"},
                {"name": "Luaib"},
                {"name": "Aspire Zone"},
                {"name": "Fereej Al Soudan"},]
            setArealist(array)
            break;
        case '55':
            setAreashow(true)
            var array = [
                {"name": "Fereej Al Soudan"},
                {"name": "Al Waab"},
                {"name": "Al Aziziya"},
                {"name": "Al Ghanim Al Jadeed"},
                {"name": "Al Mearad"},
                {"name": "Al Sailiya"},
                {"name": "Bu Sidra"},
                {"name": "Fereej Al Manaseer"},
                {"name": "Murra"},
                {"name": "Muaither"},
                {"name": "Umm Al Seneem"}]
            setArealist(array)
            break;
        case '56':
            setAreashow(true)
            var array = [
                {"name": "Bu Hamour"},
                {"name": "Ain Khaled"},
                {"name": "Mesaimeer"},
                {"name": "Maamoura"},
                {"name": "Barwa City"},
                {"name": "Umm Al Seneem"}]
            setArealist(array)
            break;
        case '57':
            setArea("Industrial Area")
            break;
        case '58':
            setArea("Wholesale Market")
            break;
        // case '59':
        //     setArea("Doha - Mushaireb")
        //     break;
        case '60':
            setArea("Dafna")
            break;
        case '61':
            setAreashow(true)
            var array = [
                {"name": "Dafna"},
                {"name": "Al Gassar"},]
            setArealist(array)
            break;
        case "62":
            setArea("Lekhwair")
            break;
        case '63':
            setArea("Onaiza")
            break;
        case '64':
            setArea("Lejbailat")
            break;
        case '65':
            setArea("Onaiza")
            break;
        case '66':
            setAreashow(true)
            var array = [
                {"name": "Legtaifiya"},
                {"name": "Onaiza"},
                {"name": "The Pearl"},
                {"name": "Al Gassar"},
                {"name": "Katara"}]
            setArealist(array)
            break;
        case '67':
            setArea("Hazm Al Markhiya")
            break;
        case '68':
            setAreashow(true)
            var array = [
                {"name": "Jelaiah"},
                {"name": "Al Tarfa"},
                {"name": "Jeryan Nejaima"},]
            setArealist(array)
            break;
        case '69':
            setAreashow(true)
            var array = [
                {"name": "Al Daayen"},
                {"name": "Al Egla"},
                {"name": "Lusail"},
                {"name": "Wadi Al Banat"},]
            setArealist(array)
            break;
        case '70':       
          setAreashow(true)
            var array = [
                {"name": "Al Daayen"},
                {"name": "Leabaib"},
                {"name": "Al Ebb"},
                {"name": "Al Kheesa"},
                {"name": "Rawdat Al Hamama"},
                {"name": "Jeryan Jenaihat"},
                {"name": "Al Sakhama"},
                {"name": "Jery Al Matrooshi"},
                {"name": "Semaisma"},
                {"name": "Tenbek"},
                {"name": "Umm Garn"},
                {"name": "Al Jeryan"},
                {"name": "Al Kheesa"}]
            setArealist(array)
            break;
        case '71':            
            setAreashow(true)
            var array = [
                {"name": "Umm Slal"},
                {"name": "Al Froosh"},
                {"name": "Al Kharaitiyat"},
                {"name": "Bu Fessela"},
                {"name": "Bu Garn"},
                {"name": "Bu Glaila"},
                {"name": "Izghawa"},
                {"name": "Umm Al Amad"},
                {"name": "Umm Ebairiya"},
                {"name": "Umm Slal Ali"},
                {"name": "Umm Slal Mohammed"}]
            setArealist(array)
            break;
        case "72":
            setArea("Doha - Al Jasra")
            break;
        case '73':
            setArea("Doha - Al Bidda")
            break;
        case '74':
            setAreashow(true)
            var array = [
                {"name": "Al Khor"},
                {"name": "Al Thakira"},]
            setArealist(array)
            break;
        case '75':
            setAreashow(true)
            var array = [
                {"name": "Al Khor"},
                {"name": "Al Thakira"},
                {"name": "Ras Laffan"}]
            setArealist(array)
            break;
        case '76':
            setArea("Al Khor")
            break;
        // case '77':
        //     setArea("Doha - Mushaireb")
        //     break;
        // case '78':
        //     setArea("Doha - Mushaireb")
        //     break;
        // case '79':
        //     setArea("Doha - Mushaireb")
        //     break;
        case '80':
            setArea("Sheehaniya")
            break;
        case '81':
            setAreashow(true)
            var array = [
                {"name": "Mebaireek"},
                {"name": "New Industrial Area"},
                {"name": "Mekaines"},]
            setArealist(array)
            break;
        case "82":
            setArea("Sheehaniya")
            break;
        case '83':
            setArea("Doha - Al Bidda")
            break;
        // case '84':
        //     setArea("Doha - Mushaireb")
        //     break;
        // case '85':
        //     setArea("Doha - Mushaireb")
        //     break;
        // case '86':
        //     setArea("Doha - Mushaireb")
        //     break;
        // case '87':
        //     setArea("Doha - Mushaireb")
        //     break;
        // case '88':
        //     setArea("Doha - Mushaireb")
        //     break;
        // case '89':
        //     setArea("Doha - Mushaireb")
        //     break;
        case '90':
            setArea("Wakra")
            break;
        case '91':
            setAreashow(true)
            var array = [
                {"name": "Wakra"},
                {"name": "Mashaf"},
                {"name": "Al Wukair"},
                {"name": "Muaither Al Wukair"},
                {"name": "Wadi Aba Seleel"},]
            setArealist(array)
            break;
        case '92':
            setAreashow(true)
            var array = [
                {"name": "Wakra"},
                {"name": "Mesaieed"},
                {"name": "Mesaieed Free Zone"},]
            setArealist(array)
            break;
        case '93':
            setAreashow(true)
            var array = [
                {"name": "Al Wakra"},
                {"name": "Mesaieed Industrial Area"},
                {"name": "Sealine"},]
            setArealist(array)
            break;
        default:
            setAreashow(false)
            setArea("")
            break
        }
    }

    const checkChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked == true) {
            setReplaceorder(1)
        }
        else {
            setReplaceorder(0)
        }
    }



    const calculateAmt = () => {
        var totalAmount = 0;
        var totalAmount1 = 0;
        for (var i = 0; i < tableRow.length; i++) {
          totalAmount += parseInt(tableRow[i].sod_total_amount) 
          if(tableRow[i].sod_id == "" && tableRow[i].product_batch_id != "") {
            totalAmount1 += parseInt(tableRow[i].sod_total_amount)
          }
       }
        // setVariationamount(totalAmount1 + diffamount)
        // setTotalaftervariation(totalAmount1 + diffamount + parseInt(deliverycharge) )
    }

   useEffect(() => {
        var totAmount = 0;
        var totAmount2 = 0;
        for (var i =0 ; i<tableRow2.length ; i++){
            if(tableRow2[i].sod_iscancel == 1){
                totAmount += parseFloat(tableRow2[i].sod_total_amount) 
            }
        
        }
        // console.log("totAmount", totAmount)
        for (var i =0 ; i<tableRow.length ; i++){
            if(tableRow[i].sod_total_amount){
                totAmount2 += parseFloat(tableRow[i].sod_total_amount) 
            }
        }
        var tot = parseFloat(totAmount2) - parseFloat(totAmount)
        setAmount(tot)
        setDeleteamount(totAmount)
   }, [tableRow, tableRow2])

   useEffect(() => {
    var totAmount = 0;
    for (var i =0 ; i<tableRow.length ; i++){
        if(tableRow[i].newItem == 1){
            totAmount += parseFloat(tableRow[i].sod_total_amount) 
        }
    
    }
    setAddedamt(totAmount)
}, [tableRow])

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20}}>
                <Row>
                    <Col md={4}>
                        <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Sales Order Replacement</p>
                    </Col>
                    <Col md={2}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField label="Sl#" value="1" disabled id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col md={2}>
                        <FormControl sx={{width: '100%' }}>
                            <TextField label="Invoice" value={invoiceno} disabled id="outlined-basic" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col md={4}>
                        <div  style={{float: 'right'}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={date}
                                    onChange={(newValue) => {
                                    setDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </Col>
                </Row>
               <div>
                    <Row>
                        </Row>
                        {loader ? <Loader1 /> :
                        <Row>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Order Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={ordertype}
                                        label="Age"
                                        onChange={billtypeChange}
                                        size="small"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {ordertypelist.map((row, index) => {
                                        return (
                                        <MenuItem value={row.ordertype_name}>{row.ordertype_name}</MenuItem>
                                    )})}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Customer" autoComplete="off" onChange={(e) => setCustomername(e.target.value)} value={customername} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Phone" type="number" onChange={(e) => setMobile(e.target.value)} value={mobile} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Building No."  onChange={(e) => setBuilding(e.target.value)} value={building} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Street"  onChange={(e) => setStreet(e.target.value)} value={street} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Zone" autoComplete='off' onChange={(e) => setFields(e.target.value)} value={zone} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            {
                                areashow ? 
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Area</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={area}
                                                label="Age"
                                                onChange={areaChange}
                                                size="small"
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {arealist.map((row, index) => {
                                                return (
                                                <MenuItem value={row.name}>{row.name}</MenuItem>
                                            )})}
                                            </Select>
                                    </FormControl>
                                </Col> :
                                <Col xs={12} md={2} style={{marginTop: 20}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField label="Area" value={area} onChange={(e) => setArea(e.target.value)}  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>
                            }
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)}  id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                    <TextField label="Reference" onChange={(e) => setReference(e.target.value)} value={reference} id="outlined-basic" variant="outlined"  size="small"/>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Delivery Boy</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={salesman}
                                            label="Age"
                                            onChange={userChange}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {userList.map((row, index) => {
                                            return (
                                            <MenuItem value={row.user_id}>{row.user_name}</MenuItem>
                                        )})}
                                        </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2} style={{marginTop: 20}}>
                                <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Ledger</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={ledgerName}
                                            // disabled
                                            label="Age"
                                            onChange={ledgerChange}
                                            size="small"
                                        >
                                        {ledgerList.map((row, index) => {
                                            return (
                                            <MenuItem value={row.ledger_id}>{row.ledger_name}</MenuItem>
                                        )})}
                                        </Select>
                                </FormControl>
                            </Col>
                    </Row>

                    }

                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>SlNo</th>
                                        <th>Code</th>
                                        <th>color-size</th>
                                        <th>Product name</th>
                                        <th>Qty</th>
                                        <th>Unit</th>
                                        <th>Price</th>
                                        {/* <th>Calc Price</th> */}
                                        {/* <th>Discount(%)</th> */}
                                        <th>Amount</th>
                                        <th>Close</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow.map((item, index) => {
                                        return ( 
                                            <> 
                                            {item.sod_iscancel == 1 ? null :                                        
                                                <tr style={item.newItem2 == 0 ? {background: '#00d100'}: null}>
                                                    <td >{index+1}</td>
                                                    {/* <td >
                                                        <TextField autoComplete="off" onChange={(event, value) => this.inputHandler(event.target.value, index)} id="outlined-basic" label="Ref No" variant="outlined"  size="small"/>
                                                        {this.state.selectedIndex == index && this.state.textLength.length >= 1  ? 
                                                        <div ref={this.myRef} className="develop">sacdscsd</div>: null
                                                        }
                                                        </td> */}
                                                    <td>
                                                    {
                                                        item.addnew == false ?
                                                        <input autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />:
                                                        <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                    }
                                                    
                                                        {hasResults && selectedIndex == index && (
                                                            <div className="autocomplete">
                                                                <ul ref={resultsRef} className="people scrolling-div">
                                                                    {productlist.map(item => {
                                                                        return (
                                                                            item.product_fetchiscombo == 0 ? 
                                                                            item.products.map(result => {
                                                                                return (
                                                                                // <li key={result.url}>
                                                                                //     <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                //         index, 
                                                                                //         result.pb_id,
                                                                                //         result.product_refcode,
                                                                                //         result.product_name,
                                                                                //         result.pricelevel_name,
                                                                                //         "1",
                                                                                //         result.unit_name,
                                                                                //         result.pb_salesrate,
                                                                                //         result.pb_salesrate,
                                                                                //         0,
                                                                                //         result.pb_salesrate,
                                                                                //         result.pricelevel_percentage,
                                                                                //         result.pb_stock,
                                                                                //         0,
                                                                                //         result.color_name,
                                                                                //         result.size_name,
                                                                                //         result
                                                                                //         )}>
                                                                                //     {result.product_refcode} - { result.product_name } - {result.color_name} - {result.size_name}
                                                                                //     </a>
                                                                                // </li>
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                        index, 
                                                                                        item.products_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.products,
                                                                                        0
                                                                                        )}>
                                                                                    {item.product_refcode} - { item.product_name }
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                            }) : 
                                                                            <li key={item.url}>
                                                                                <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                    index, 
                                                                                    item.products_id,
                                                                                    item.product_refcode,
                                                                                    item.product_name,
                                                                                    item.pricelevel_name,
                                                                                    "1",
                                                                                    item.unit_name,
                                                                                    item.product_comboprice,
                                                                                    item.product_comboprice,
                                                                                    0,
                                                                                    item.product_comboprice,
                                                                                    item.pricelevel_percentage,
                                                                                    item.product_combostock,
                                                                                    1
                                                                                    )}>
                                                                                {item.product_refcode} - { item.product_name }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        </td>
                                                        {
                                                            
                                                        item.addnew == true ? 
                                                                <td>
                                                                <FormControl sx={{width: '100%' }}>
                                                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-helper-label"
                                                                                id="demo-simple-select-helper"
                                                                                value={item.product_batch_id}
                                                                                label="Age"
                                                                                onChange={colorChange}
                                                                                size="small"
                                                                            >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            {item.colorsize.map((row, index) => {
                                                                                return (
                                                                                <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                            )})}
                                                                            </Select>
                                                                    </FormControl>
                                                                    </td>
                                                                : 
                                                                <td></td>
                                                        }
                                                        <td style={{fontSize: 10}}>

                                                            {item.sales_iscombo == 0 || item.sales_iscombo == null   ? 
                                                            
                                                                <p> {item.productname} - {item.color} - {item.size} </p> :
                                                                    
                                                                    item.sales_iscombo == 1 ?
                                                                    <div>
                                                                        {
                                                                    item.addnew == false ? null :
                                                                    <Button style={{height: 30}} variant="contained" onClick={() => addProduct(item.product_batch_id, index, item.fetch_products, item.product_batch_id)}>Add Product</Button>
                                                                        }
                                                                        {
                                                            
                                                                            item.fetch_products.map((result, k) => {
                                                                            return(
                                                                                <label style={{fontSize: 10}}>
                                                                                    {result.product_name} - {result.color} - {result.size}
                                                                                </label>
                                                                                )
                                                                            })
                                                                        }
                                                                
                                                                    </div> : 
                                                                        null
                                                                }
                                                        </td>
                                                    <td>
                                                        {item.sales_iscombo == 0 ||  item.sales_iscombo == null   ?
                                                            <div>  
                                                                {item.addnew == false ?
                                                                <input min="1" disabled  autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />: 
                                                                <input min="1"  autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />
                                                                }
                                                                {
                                                                    <label style={{fontSize: 12, fontWeight: 'bold', color: 'red'}}>stock: {item.pb_stock}</label>
                                                                }
                                                            </div>: 
                                                            null
                                                        }
                                                    </td>
                                                    <td>{item.unit}</td>
                                                    <td>{item.price}</td>
                                                    {/* {item.sales_iscombo == 0 ?
                                                        <td>{item.calcprice}</td> :
                                                        <td>{item.price}</td>
                                                    } */}
                                                    
                                                    {/* <td>
                                                    {item.addnew == false ?
                                                    <input autocomplete="off" disabled  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                                    :<input autocomplete="off"  type="number" name="query" value={item.discount} onChange={(event) => disountChange(event, index)} />
                                                    }
                                                    </td> */}
                                                    <td>{item.sod_total_amount}</td>
                                                    <td>

                                                    {item.sod_iscancel == 1 ? null : item.sales_isedit == 1 ? null: <Button onClick={() => deleteRow(index, item.amount, item.sod_id, item.sod_qty)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}</td>
                                                </tr> }
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button>
                        </Col>
                    </Row> 
                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>SlNo</th>
                                        <th>Code</th>
                                        <th>color-size</th>
                                        <th>Product name</th>
                                        <th>Qty</th>
                                        <th>Unit</th>
                                        <th>Price</th>
                                        {/* <th>Calc Price</th> */}
                                    
                                        <th>Amount</th>
                                        <th>Close</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow2.map((item, index) => {
                                        return ( 
                                            <>
                                            {item.sod_iscancel == 1 ? 
                                                <tr style={item.sod_iscancel == 1 ? {background: 'red'}: null}>
                                                    <td >{index+1}</td>
                                                   
                                                    <td>
                                                    {
                                                        item.addnew == false ?
                                                        <input autocomplete="off" disabled ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />:
                                                        <input autocomplete="off" ref={inputRef} type="text" name="query" value={item.code} onChange={(event) => handleOnChange(event, index, "refcode")} />
                                                    }
                                                    
                                                        {hasResults && selectedIndex == index && (
                                                            <div className="autocomplete">
                                                                <ul ref={resultsRef} className="people scrolling-div">
                                                                    {productlist.map(item => {
                                                                        return (
                                                                            item.product_fetchiscombo == 0 ? 
                                                                            item.products.map(result => {
                                                                                return (
                                                                            
                                                                                <li key={item.url}>
                                                                                    <a href="javascript:void(0)" onClick={() => listClickrefcode(
                                                                                        index, 
                                                                                        item.products_id,
                                                                                        item.product_refcode,
                                                                                        item.product_name,
                                                                                        item.products,
                                                                                        0
                                                                                        )}>
                                                                                    {item.product_refcode} - { item.product_name }
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                            }) : 
                                                                            <li key={item.url}>
                                                                                <a href="javascript:void(0)" onClick={() => listClickcode(
                                                                                    index, 
                                                                                    item.products_id,
                                                                                    item.product_refcode,
                                                                                    item.product_name,
                                                                                    item.pricelevel_name,
                                                                                    "1",
                                                                                    item.unit_name,
                                                                                    item.product_comboprice,
                                                                                    item.product_comboprice,
                                                                                    0,
                                                                                    item.product_comboprice,
                                                                                    item.pricelevel_percentage,
                                                                                    item.product_combostock,
                                                                                    1
                                                                                    )}>
                                                                                {item.product_refcode} - { item.product_name }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        </td>
                                                        {
                                                            
                                                        item.addnew == true ? 
                                                                <td>
                                                                <FormControl sx={{width: '100%' }}>
                                                                            <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Select</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-helper-label"
                                                                                id="demo-simple-select-helper"
                                                                                value={item.product_batch_id}
                                                                                label="Age"
                                                                                onChange={colorChange}
                                                                                size="small"
                                                                            >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            {item.colorsize.map((row, index) => {
                                                                                return (
                                                                                <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                                                            )})}
                                                                            </Select>
                                                                    </FormControl>
                                                                    </td>
                                                                : 
                                                                <td></td>
                                                        }
                                                        <td style={{fontSize: 10}}>

                                                            {item.sales_iscombo == 0 || item.sales_iscombo == null   ? 
                                                            
                                                                <p> {item.productname} - {item.color} - {item.size} </p> :
                                                                    
                                                                    item.sales_iscombo == 1 ?
                                                                    <div>
                                                                        {
                                                                    item.addnew == false ? null :
                                                                    <Button style={{height: 30}} variant="contained" onClick={() => addProduct(item.product_batch_id, index, item.fetch_products, item.product_batch_id)}>Add Product</Button>
                                                                        }
                                                                        {
                                                            
                                                                            item.fetch_products.map((result, k) => {
                                                                            return(
                                                                                <label style={{fontSize: 10}}>
                                                                                    {result.product_name} - {result.color} - {result.size}
                                                                                </label>
                                                                                )
                                                                            })
                                                                        }
                                                                
                                                                    </div> : 
                                                                        null
                                                                }
                                                        </td>
                                                    <td>
                                                        {item.sales_iscombo == 0 ||  item.sales_iscombo == null   ?
                                                            <div>  
                                                                {item.addnew == false ?
                                                                <input min="1" disabled  autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />: 
                                                                <input min="1"  autocomplete="off" type="number" name="query" value={item.sod_qty} onChange={(event) => qtyChange(event, index)} />
                                                                }
                                                                {
                                                                    <label style={{fontSize: 12, fontWeight: 'bold', color: 'red'}}>stock: {item.pb_stock}</label>
                                                                }
                                                            </div>: 
                                                            null
                                                        }
                                                    </td>
                                                    <td>{item.unit}</td>
                                                    <td>{item.price}</td>
                                                    {/* {item.sales_iscombo == 0 ?
                                                        <td>{item.calcprice}</td> :
                                                        <td>{item.price}</td>
                                                    } */}
                                                    <td>{item.sod_total_amount}</td>
                                                    <td>

                                                    {item.sod_iscancel == 1 ? null : item.sales_isedit == 1 ? null: <Button onClick={() => deleteRow(index, item.amount, item.sod_id)} style={{backgroundColor: '#fff', border: 'none', boxShadow: 'none'}} variant="contained">< AiFillMinusCircle color="#6d37b9" fontSize={30} style={{marginRight: 10}} /></Button>}</td>
                                                </tr> : null }
                                            </>                                         
                                                
                                        )
                                    })}
                                </tbody>
                            </Table>
                            {/* <Button onClick={() => addRow()} style={{float: 'right', marginRight: 20, backgroundColor: '#fff', color: '#000', borderRadius: 20, marginTop: 20}} variant="contained">Add New</Button> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs ={12} md={1}>
                                {btnloader ? 
                                <Loader
                                    type="Oval"
                                    color="#38B0DE"
                                    height={30}
                                    width={30}
                                    style={{float: 'left', marginTop: 32}}
                                />:
                                <Button onClick={onSave} style={{backgroundColor: '#6d37b9', borderRadius: 20, marginTop:20, marginBottom: 20}} variant="contained">Save</Button>}
                            
                            {/* <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><RiDeleteBinLine  color="White" fontSize={20} style={{marginRight: 10}} />Delete</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><AiFillPrinter  color="White" fontSize={20} style={{marginRight: 10}} />Print</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><MdClearAll  color="White" fontSize={20} style={{marginRight: 10}} />Clear</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><GiCash  color="White" fontSize={20} style={{marginRight: 10}} />GST</Button>
                            <Button style={{backgroundColor: '#6d37b9', marginLeft: 20, borderRadius: 20, marginTop:30, marginBottom: 20}} variant="contained"><AiOutlineClose color="White" fontSize={20} style={{marginRight: 10}} />Close</Button> */}
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Returned Items Total" autoComplete="off" type="number" disabled value={parseFloat(deleteamt)?.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> 
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Replaced Items Total" autoComplete="off" type="number" disabled value={parseFloat(addedamt)?.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> 

                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Extra Delivery Charges" autoComplete="off" type="number" onFocus={() => extranull()} value={extradelivery} onChange={(e) => setExtradelivery(e.target.value)}   id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> 
                        <Col xs={12} md={1} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Discount" autoComplete="off" type="number" onFocus={() => discnull()} onChange={(e) => totalDiscountchange(e.target.value)} value={discount} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Cash Ledger" id="outlined-basic" disabled value={parseFloat(crfc)?.toFixed(2)} variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> 
                        <Col xs={12} md={2} style={{marginTop: 20}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField label="Balance Receivable" disabled  value={parseFloat(balanReceived)?.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col> 
                    </Row>
                    <Row>
                        <Col  xs={12}>
                            
                            <Row>
                                
                                {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Initial Delivery Charges
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField autoComplete="off" type="number" disabled value={parseFloat(deliverycharge)?.toFixed(2)}  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>   */}
                                {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Cash Received
                                    </p>
                                </Col> */}
                                {/* <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={advance} onFocus={() => advancenull()} onChange={(e) => setAdvance(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>  */}
                                {/* <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={parseFloat(cashReceived)?.toFixed(2)} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>  */}
                            </Row>
                            <Row>
                                {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Cash Receivable</p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled  value={parseFloat(grandtotal)?.toFixed(2)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>  */}
                                {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Sales Legder
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={parseFloat(cashledger)?.toFixed(2)} disabled id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>  */}                             
                            </Row>
                            <Row>
                                {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Payable
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField value={payable} onChange={(e) => setPayable(e.target.value)} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col>   */}
                                
                                {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Current Items Total
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField disabled value={parseFloat(amount)?.toFixed(2)}  id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col> */}
                                {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                    <p>Other Charges
                                    </p>
                                </Col>
                                <Col xs={12} md={2} style={{marginTop: 30}}>
    
                                    <FormControl sx={{width: '100%' }}>
                                        <TextField  autoComplete="off" type="number" onChange={(e) => otherChargeschange(e.target.value)} value={otherCharges} id="outlined-basic" variant="outlined"  size="small"/>
                                    </FormControl>
                                </Col> */}
                                
                                {/* <Col xs={12} md={2} style={{marginTop: 30}}>
                                    <Button onClick={() => roundOffchange()} variant="contained">Apply Roundoff</Button>
                                </Col> */}
                                
                                   
                                <Row>
                                        {/* <Col xs={12} md={1} style={{marginTop: 30}}>
                                            <p>Extra Delivery Charge
                                            </p>
                                        </Col>
                                        <Col xs={12} md={2} style={{marginTop: 30}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField autoComplete="off" type="number" onChange={(e) => deliveryChargechange(e.target.value)} value={deliverycharge} id="outlined-basic" variant="outlined"  size="small"/>
                                            </FormControl>
                                        </Col>
                                        <Col xs={12} md={1} style={{marginTop: 30}}>
                                            <p>Variation Amount</p>
                                        </Col>
                                        <Col xs={12} md={2} style={{marginTop: 30}}>
                                            <FormControl sx={{width: '100%' }}>
                                                <TextField disabled onChange={(e) => setVariationamount(e.target.value)} value={variationamount} id="outlined-basic" variant="outlined"  size="small"/>
                                            </FormControl>
                                        </Col> */}
{/* 
                                        <Col xs={12} md={1} style={{marginTop: 30}}>
                                            <p>Grand Total After variation </p>
                                        </Col>
                                        <Col xs={12} md={2} style={{marginTop: 30}}>
                                            {variationamount >= 0 ?
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField disabled  value={totalaftervariation} id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>:
                                                <FormControl sx={{width: '100%' }}>
                                                    <TextField disabled  value={((variationamount*-2)/2) - otherCharges} id="outlined-basic" variant="outlined"  size="small"/>
                                                </FormControl>
                                            }
                                        </Col> */}
                                        {/* <Col xs={12} md={2} style={{marginTop: 30}}>
                                            <Button onClick={calculateAmt} variant="contained">Calculate variation amount</Button>
                                        </Col> */}
                                </Row>
                                         
                                
                            </Row>
                            
                        </Col>
                        
                    </Row>  
                </div>
            </div>
          </Paper> 
          <Modal
            size="lg"
            show={modal}
            onHide={() => setModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
               Select Combo product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {combotable.map((item, index) => {
                    return (
                        <Row style={{marginTop: 20}}>
                            <Col xs={12} md={5}>
                                <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Product</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={item.productid}
                                            label="Age"
                                            onChange={(e) => cbproductChange(e,index)}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {comboproductlist.map((row, index) => {
                                            return (
                                            <MenuItem value={row.products_id}>{row.product_name}</MenuItem>
                                        )})}
                                        </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormControl sx={{width: '100%' }}>
                                        <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Color and size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={item.pb_id}
                                            label="Age"
                                            onChange={(e) => cbbatchChange(e,index)}
                                            size="small"
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {combobatchlist[index].value.map((row, index) => {
                                            return (
                                            <MenuItem value={row.pb_id}>{row.color_name} - {row.size_name}</MenuItem>
                                        )})}
                                        </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={2}>
                               <label style={{fontSize: 10, color: 'red'}}>Max Quantiy = {item.quantity}</label>
                               <label style={{fontSize: 10, color: 'red'}}>stock = {item.stock}</label>
                            </Col>
                            <Col xs={12} md={2}>
                                    <a href="javascript:void(0)" onClick={() => deletePopupitem(index)}>
                                            <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                    </a> 
                            </Col>
                            
                        </Row>
                    )
                })
            }
            <Row style={{marginTop: 20}}>
                <Col xs={12} md={2}>
                    <Button style={{height: 30}} variant="contained" onClick={() => addComborow()}>Add</Button>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12} md={2}>
                    <Button style={{height: 30}} variant="contained" onClick={() => addtoTablerow()}>Submit</Button>
                </Col>
            </Row>
            </Modal.Body>
        </Modal>
        <Modal
            size="lg"
            show={modaldel}
            onHide={() => setModaldel(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
               Delete
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row>
                        <Col md={12}>
                            <p style={{fontSize: 15, fontWeight: 'bold'}}>Total Qty : {deletotalqty}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <p style={{fontSize: 15, fontWeight: 'bold'}}>Delete Qty</p>
                        </Col>
                        <Col md={3}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField autoComplete="off" type="number" onChange={(e) => setDelqty(e.target.value)} value={delqty} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                    </Row>
                </div>
                
            <Row style={{marginTop: 20}}>
                <Col xs={12} md={2}>
                    <Button onClick={DeleteQtyClick} style={{height: 30, backgroundColor: 'red', border: 'none'}} variant="contained">Delete</Button>
                </Col>
            </Row>
            </Modal.Body>
        </Modal>
      </div>
    )
}
export default State;