import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Form  } from "react-bootstrap";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '2', label: 'Slno.', minWidth: 50,span: false },
    { id: '3', label: 'Main Group', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
    
  const rows = [
    {
      company: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',

    },
    {
      company: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
      ledger: 'Lorem Ipsum',
    },

  ];

function MainGroup(props) {
  const [btnloader, setBtnloader] = React.useState(false);
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [groupId, setGroupid] = React.useState("");
    const [groupName, setGroupname] = React.useState("");
    const [grpmainId, setGrpmainid] = React.useState("");
    const [blocked, setBlocked] = React.useState(0);
    const [groupUserid, setGroupUserid] = React.useState("");
    const [grpCode, setGroupcode] = React.useState("");
    const [grpCandelete, setGroupcandelete] = React.useState(0);
    const [grpType, setGrptype] = React.useState("")
    const [mainGrouplist, setMaingrouplist] = React.useState([])
    

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setGroupcandelete(1)
    }
    else {
      setGroupcandelete(0)
    }
  }

  const blockChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    setLoader(true)
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setGroupUserid(userdata.user_id)

    fetch(global.url + "viewMaingroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("viewMaingroup", responseJson)
        if(responseJson.error == false){
          setLoader(false)
          setSource(responseJson.data)
        }
        else{
          setLoader(false)
        }
        
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const editClick = (
        groupid,
        grpname,  
      ) => {
        setModal(true)
        setGroupid(groupid)
        setGroupname(grpname)
    }

    const onAdd = () => {
        setModal(true)
        setGroupid("")
        setGroupname("")
        
  }

    const onSave = () => {
      setBtnloader(true)
      var data = {
        "maingroupid" : groupId,
        "maingroupname" : groupName
      }
      console.log("data save", data)
      fetch(global.url + "updateMainGroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("updateMainGroup", responseJson)
          if(responseJson.error == false) {
            getData()
            setModal(false)
            alert(responseJson.message)
            setBtnloader(false)
          } else {
            alert(responseJson.message)
            setBtnloader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    // const deleteClick = (id) =>  {
    //   var data = {
    //     "groupid": id,
    //   }
    //   fetch(global.url + "deleteGroup", {
    //       method: "POST",
    //       headers: {
    //           'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(data),
    //   })
    //   .then((response)=> response.json())
    //   .then((responseJson)=>{
    //     console.log("del", responseJson)
    //       alert("Deleted")
    //       getData()
    //   })
    //   .catch((error)=>{
    //       console.log(error)
    //   })
    // }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Main Groups List</p>
               {
                    usertype == "superadmin" ? 
                    null : 
                    <Button variant="contained" onClick={onAdd}>Add</Button>
                }
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {index + 1}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.maingroupname}
                              </TableCell>
                              <TableCell >
                              {
                                    usertype == "superadmin" ? 
                                    null : 
                                    <a href="javascript:void(0)" onClick={() => 
                                        editClick(
                                            row.maingroupid,
                                            row.maingroupname
                                        )}>
                                          <FaIcons.FaEdit fontSize={20} color="blue" />
                                        </a>
                                }
                              </TableCell>
                              {/* { row.group_candelete == "0" ? null :
                                <TableCell >
                                    <a href="javascript:void(0)" onClick={() => deleteClick(row.group_id)}>
                                        <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                    </a>
                                </TableCell>
                               } */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Main Group Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField value={groupName} onChange={(e) => setGroupname(e.target.value)} id="outlined-basic" label="Group Name" variant="outlined"  size="small"/>
                        </FormControl>
                        <label style={{fontSize: 10}}>Required</label>
                    </Col>
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default MainGroup;