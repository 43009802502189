import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";

import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Fetchpage from './AddCampaign';
import Loader from '../loader/loader';
import BtnLoader from '../loader/btnLoader';

const columns = [
  { id: '1', label: 'Sl#', minWidth: 50,span: false },
    { id: '1', label: 'Online Campaign Name', minWidth: 50,span: false },
    { id: '1', label: 'Online Campaign Number', minWidth: 50,span: false },
    { id: '1', label: 'Campaign Start Date', minWidth: 50,span: false },
    { id: '1', label: 'Campaign End Date', minWidth: 50,span: false },
    { id: '1', label: 'AG code', minWidth: 50,span: false },
    { id: '3', label: 'Ref code', minWidth: 50,span: false },
    { id: '3', label: 'Price', minWidth: 50,span: false },
    { id: '5', label: 'Description', minWidth: 50,span: false },
    { id: '8', label: 'Reference', minWidth: 50,span: false },
    // { id: '11', label: 'Base unit', minWidth: 50,span: false },
    // { id: '12', label: 'Purchase unit', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 50,
      span: true
    },
  ];
    

function OnlineCampaign(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")

    const [modal, setModal] = useState(false);
    const [editMode, setEditmode] = useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [source, setSource] = React.useState([]);
    const [userId, setUserid] = React.useState('');
    const [productid, setProductid] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [errormessage, setErrormessage] = React.useState("");
    const [productmodal, setProductModal] = useState(false);
    const [indexState, setIndexstate] = React.useState(0);
    const [hsn, setHsn] = React.useState('');
    const [hsnList, setHsnlist] = React.useState([]);
    const [color, setColor] = React.useState("");
    const [size, setSize] = React.useState("");
    const [sizelist, setSizelist] = React.useState([]);
    const [colorlist, setColorlist] = React.useState([]);

    const [tax, setTax] = React.useState('');
    const [taxList, setTaxlist] = React.useState([]);

    const [itemGroup, setItemgroup] = React.useState('');
    const [itemgroupList, setItemgrouplist] = React.useState([]);

    const [brand, setBrand] = React.useState('');
    const [brandList, setBrandlist] = React.useState([]);

    const [branches, setBranches] = React.useState([]);

    const [category, setCategory] = React.useState('');
    const [categoryList, setCategorylist] = React.useState([]);

    const [subCategory, setSubcategory] = React.useState('');
    const [subcatlist, setSubcatlist] = React.useState([]);

    const [baseUnit, setBaseunit] = React.useState('');
    const [purchaseUnit, setPurchaseunit] = React.useState('');
    const [salesUnit, setSalesunit] = React.useState('');
    const [defaultPrice, setDefaultprice] = React.useState('');

    const [saveLoader, setSaveloader ] = React.useState(false)


    const [productSave, setProductsave] = React.useState(false);

    const [priceList, setPricelist] = React.useState([]);
    
    const [unitList, setUnitlist] = React.useState([]);


    const [textArray, setTextarray] = React.useState([{key: "", value: ""}])

  
    const [expiryDate, setExpirydate] = React.useState(new Date);
    const [producttype, setProducttype] = React.useState(0);
    const [tableRow,setTableRow] = React.useState([{product_id : "", product_refcode:"", product_quantity:"", product_name: ""}])
    
    const [code,setCode] = React.useState("")
    const hasResults = code.length > 0;
    const [selectedIndex, setSelectedindex] = React.useState("")
    const [productlist, setProductlist] = React.useState([]);
    const [combolistloader, setCombolistloader] = React.useState(false);
    

    //Defaultprice
    const [dproductcolor, setDproductcolor] = React.useState("");
    const [dproductsize, setDproductsize] = React.useState("");
    const [dcost, setDcost] = React.useState("");
    const [dproductpurchaseprice, setDpurchaseprice] = React.useState("");
    const [dsalesrate, setDsalesrate] = React.useState("");
    const [dunit, setDunit] = React.useState("");
    const [dopeningqty, setDopeningqty] = React.useState("");
    const [dexpirydate, setDexpirydate] = React.useState(new Date);


    const [master, setMaster] = React.useState("");
    const [masterlist, setMasterlist] = React.useState([]);
    const [camnumber, setCamnumber] = React.useState("");
    const [camnumberlist, setCamnumberlist] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [branchlist, setBranchlist] = React.useState([]);
    const [campaigncode, setCampaigncode] = React.useState("");
    const [referalcode, setReferalcode] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [camprice, setCamprice] = React.useState("");
    const [dateFrom, setDatefrom] = React.useState(new Date)
    const [dateTo, setDateTo] = React.useState(new Date)
    const [campaignid, setCampaignid] = React.useState("");
    const [refcodenew, setRefcodenew] = React.useState("");
    const [blocked, setBlocked] = React.useState(0);

    const radioChange = (event) => {
      console.log(event.target.value, "event.target.value")
      if(event.target.value == "nonbatch") {

        setProducttype(0)
      }
      else {
        setProducttype(1)
      }
    }

    const checkChange = (e) => {
      let isChecked = e.target.checked;
      if(isChecked == true) {
        setBlocked(1)
      }
      else {
        setBlocked(0)
      }
    }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    console.log("tableRow 777", tableRow)
  }, [tableRow])


  useEffect(() => {
    getBranches()
    getData()
    getCampaignlist()
  }, []);

  useEffect(() => {
    getCampaignnumber()
  }, [master]);

  const getCampCode = () => {
    fetch(global.url + "showCampaignproductCode", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },

      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("showCampaignproductCode", responseJson)
        if(responseJson.error == false){
          setCampaigncode(responseJson.data)
        } 
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getCampaignlist = () => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "branchid":  branch,
      "companyid": company
    }
    fetch(global.url + "viewCampaignList", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
    
        if(responseJson.error == false){
          setMasterlist(responseJson.data)
        } 
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getCampaignnumber = () => {
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "branchid":  branch,
      "companyid": company,
      "campaign_id" : master
    }
    fetch(global.url + "viewCampaignNumberList", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("viewCampaignNumberList", responseJson)
        if(responseJson.error == false){
          setCamnumberlist(responseJson.data)
        } 
      })
      .catch((error)=>{
          console.log(error)
      })
  }


  const getColorlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewColor", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
     
          setLoader(false)
          if(responseJson.status == "false") {
            setColorlist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getSizelist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewSize", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          setLoader(false)
          if(responseJson.status == "false") {
            setSizelist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getBranches = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "company_id": company,
    }
    fetch(global.url + "multipleBranch", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
      console.log("branch", responseJson)
        if(responseJson.error == false){
            setBranchlist(responseJson.data)
        }
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const getHsnlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewHSN", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          setLoader(false)
          if(responseJson.status == "false") {
            setHsnlist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getTaxlist = () =>{
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
      fetch(global.url + "viewTaxmaster", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((response)=> response.json())
      .then((responseJson)=>{
        if(responseJson.status == "false"){
          console.log("response", responseJson.data)
          setTaxlist(responseJson.data)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getBrandlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewBrand", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("brand response", responseJson)
          if(responseJson.status == "false") {
            setBrandlist(responseJson.data)
            console.log("response", responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getItem = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewItemgroup", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("brand response", responseJson)
          if(responseJson.status == "false") {
            setItemgrouplist(responseJson.data)
            console.log("response", responseJson.data)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getUnitlist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewUnit", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.status == "false") {
            setUnitlist(responseJson.data)
            console.log("response", responseJson.data)
          }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }


  const getPricelist = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
      "companyid": company,
      "type": userdata.user_type
    }
    fetch(global.url + "viewPricelevel", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response", responseJson)
          if(responseJson.status == "false") {
            setPricelist(responseJson.data)
          }
         
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const getCategorylist = () => {
    
    var userdata = JSON.parse(localStorage.getItem("user"))
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      "type":  userdata.user_type,
      "companyid": company
    }
    fetch(global.url + "viewCategory", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          setLoader(false)
          if(responseJson.status == "false") {
            setCategorylist(responseJson.data)
            console.log("response", responseJson.data)
          }
          
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    setLoader(true)
   
    var data = {
      "companyid": company,
      "branchid" : branch
    }
    fetch(global.url + "viewCampaignProducts", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("viewCampaignProducts 123", responseJson)
          setLoader(false)
          if(responseJson.error == false) {
            setSource(responseJson.data.camprod_details)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

    const masterChange = (event) => {
      setMaster(event.target.value);
    };

    const camnumChange = (event) => {
      setCamnumber(event.target.value);
    };

    const itemgroupChange = (event) => {
      setItemgroup(event.target.value);
    };

    const brandChange = (event) => {
      setBrand(event.target.value);
    };


    const categoryChange = (event) => {
      setCategory(event.target.value);
    };

    const subcategoryChange = (event) => {
      setSubcategory(event.target.value);
    };

    const baseunitChange = (event) => {
      setBaseunit(event.target.value);
    };


    const purchaseunitChange = (event) => {
      setPurchaseunit(event.target.value);
    };


    const salesunitChange = (event) => {
      setSalesunit(event.target.value);
    };

    const defaultpriceChange = (event) => {
      setDefaultprice(event.target.value);
    };

    const dproductcolorChange = (event) => {
      setDproductcolor(event.target.value);
    };

    const dproductsizechange = (event) => {
      setDproductsize(event.target.value);
    };

    const dunitchange = (event) => {
      setDunit(event.target.value);
    };

    

    

  const onNext = () => {
  
      // setSaveloader(true)

      const exampleArray = []
        for(var i = 0 ; i< tableRow.length; i++) {
            exampleArray.push(tableRow[i]) 
        }
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        var data = {
          "companyid" : company,
          "branchid" : branch,
          "campaign_code" : campaigncode,
          "referencecode" : referalcode, 
          "campaign_id" : master,
          "campaign_number" : camnumber,
          "description" : description,
          "price" : camprice,
          "startdate" : dateFrom.toISOString().split('T')[0],
          "enddate" : dateTo.toISOString().split('T')[0],
          "cp_id" : campaignid,
          "product_details" : exampleArray
        }
        console.log("onNext", data)

        fetch(global.url + "addCampaignProducts", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("responsejson addCampaignProducts", responseJson);
            if(responseJson.error == false){
              alert(responseJson.message)
              window.location.reload()
            }else{
              alert(responseJson.message)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const editClick = (
    desc,
    ref,
    price,
    code,
    refcode,
    fromdate,
    todate
  ) => {
    setModal(true)
    setDescription(desc)
    setReferalcode(ref)
    setCamprice(price)
    setCampaigncode(code)
    setRefcodenew(refcode)
    setDatefrom(fromdate)
    setDateTo(todate)
  }


  const onAdd = () => {
    setModal(true)
    getCampCode()
    setEditmode(false)
    setTableRow([{product_id : "",product_refcode:"",product_quantity:""}])
    setProductsave(false)
    setDescription("")
    setReferalcode("")
    setCamprice("")
    setRefcodenew("")
  }


const getSearchfetch = (text) => {
  var branch = JSON.parse(localStorage.getItem("branch"))
  var company = JSON.parse(localStorage.getItem("company"))
  var data = {
      "type":  "refcode",
      "refcode":  text,
      "companyid" : company,
      "branchid" : branch
  }
  console.log("var data", data)
  fetch(global.url + "getSearchStock", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
  .then((response)=> response.json())
  .then((responseJson)=>{
      console.log("response fetch", responseJson)
      if(responseJson.error == false) {
        setProductlist(responseJson.data)
      }
      
  })
  .catch((error)=>{
      console.log(error)
  })
}


 

  const onhide = () => {
    setEditmode(false)
    setModal(false)
    getData()
  }


  const deleteClick = (id) =>  {
    var data = {
      "products_id": id,
    }
    fetch(global.url + "deleteProducts", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        alert(responseJson.data)
        getData()
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const listClickcode = (index, batchid, code, name, refcode) => {
    var input = [...tableRow]
    input[index].product_refcode = refcode;
    input[index].product_id = batchid;
    
    input[index].product_name = name;
    
    setTableRow(input)
    setCode("")
    // setTableRow([...tableRow, {product_fetchproducts:"",pc_stock:"", product_name: "", product_code : "", color_name: "", size_name: "",pstock: ""}])
}


  const handleOnChange = (event, key, type) => {
    var textArray1 = [...tableRow]
    textArray1[key].product_refcode = event.currentTarget.value;
    setTextarray(textArray1)
    setSelectedindex(key)
    setCode(event.currentTarget.value);
    getSearchfetch(event.currentTarget.value)
}

const indexChange = (index) => {
  console.log("index", index)
  setIndexstate(index)
  setSelectedindex(index)
}

const getbarcodename = (id, productid) => {
  setSelectedindex(indexState)
  setRefcodenew(id)
  console.log("selectedIndex", selectedIndex)
  setProductModal(false)
  var textArray1 = [...tableRow]
  textArray1[indexState].product_refcode = id;
  textArray1[indexState].product_id = productid;
  setTextarray(textArray1)
  setTextarray([...tableRow, textArray1])
  setCode(id);
  // getSearchfetch(id)
}

  const stockChange = (event, key, type) => {
    var textArray1 = [...tableRow]
    textArray1[key].product_quantity = event.currentTarget.value;
    setTextarray([...tableRow, textArray1])
    setSelectedindex(key)
    setErrormessage("")
  }

  const addtablerow = () => {
    setTableRow([...tableRow, {product_id : "",product_refcode:"",product_quantity:""}])
  }


        const deleteCombo = (index) => {
            var input = [...tableRow]
            input.splice(index, 1)
            console.log("input", input)
            setTableRow(input)
        }

    const searchProduct = (text) => {
      
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
      setLoader(true)
     
      var data = {
        "usertype": userdata.user_type,
        "companyid": company,
        "refcode": text,
        "branchid" : branch
      }
      // console.log("ViewFetchCombo data", data)
      fetch(global.url + "ViewFetchCombo", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response", responseJson)
            setLoader(false)
            if(responseJson.error == false) {
              setSource(responseJson.data)
              // setTableRow(responseJson.data.products)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }


    return(
        <div className="main-content">
          {modal == false ? 
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Online Campaign</p>
               {/* <FormControl sx={{ width: '20%' }}>
                    <TextField name="Zone"
                    onChange={(e) => searchProduct(e.target.value)}
                    autoComplete="off" id="outlined-basic" label="Referal code Search" variant="outlined"  size="small"/>
                </FormControl> */}
               <div>
               {
                    usertype == "superadmin" ? 
                    null : 
                    <Button variant="contained"  onClick={onAdd}>Add</Button>
               }
               </div>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell >
                                  {index + 1}
                              </TableCell>
                              <TableCell >
                                  {row.campaign_name}
                              </TableCell>
                              <TableCell >
                                {row.campaign_number}
                              </TableCell>
                              <TableCell >
                                {row.campaign_startdate}
                              </TableCell>
                              <TableCell >
                                  {row.campaign_enddate}
                              </TableCell>
                              <TableCell >
                                {row.campaign_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.refcode}
                              </TableCell>
                              <TableCell >
                                  {row.price}
                              </TableCell>
                              <TableCell >
                                  {row.description}
                              </TableCell>
                              <TableCell >
                                  {row.referencecode}
                              </TableCell>
                              {/* <TableCell >
                              {
                                  usertype == "superadmin" ? 
                                  null : 
                                <a href="javascript:void(0)" onClick={() => 
                                    editClick(
                                        row.products_id, 
                                        row.product_code, 
                                        row.product_name, 
                                        row.product_printname, 
                                        row.product_othername,
                                        row.product_hsn, 
                                        row.product_tax,
                                        row.product_description,
                                        row.product_itemgroup,
                                        row.product_brand,
                                        row.product_category,
                                        row.product_subcategory,
                                        row.product_baseunit,
                                        row.product_purchaseunit,
                                        row.product_saleunit,
                                        row.product_defaultprice,
                                        row.product_branches,
                                        row.product_type,
                                        row.product_refcode,
                                        row.product_warranty,
                                        row.product_comboprice,
                                        row.product_combostock,
                                        row.products
                                    )}>
                                        <FaIcons.FaEdit fontSize={20} color="blue" />
                                    </a>
                                }
                              </TableCell> */}
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => editClick(
                                    row.description,
                                    row.referencecode,
                                    row.price,
                                    row.campaign_code,
                                    row.refcode,
                                    row.campaign_startdate,
                                    row.campaign_enddate
                                  )}>
                                    <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          :
          <Row style={{background: '#fff'}}>
            <Col xs={12}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Online Campaign</p>
               <Button variant="contained"  onClick={onhide}>Back</Button>
            </div>
                <Fetchpage 

                  masterlist = {masterlist}
                  master = {master}
                  masterChange = {masterChange}
                  camnumberlist = {camnumberlist}
                  camnumber = {camnumber}
                  camnumChange = {camnumChange}
                  branchlist = {branchlist}
                  selected = {selected}
                  setSelected = {setSelected}
                  dateFrom = {dateFrom}
                  setDatefrom = {setDatefrom}
                  dateTo = {dateTo}
                  setDateTo = {setDateTo}
                  campaigncode = {campaigncode}
                  setReferalcode = {setReferalcode}
                  referalcode = {referalcode}
                  description = {description}
                  setDescription = {setDescription}
                  camprice = {camprice}
                  setCamprice = {setCamprice}
                  refcodenew = {refcodenew}
                  checkChange = {checkChange}
                  blocked = {blocked}

                      producttype = {producttype}
                      radioChange = {radioChange}
                      editmode = {editMode}

                      

                      color = {color}
                      colorlist = {colorlist}
                  

                      size = {size}
                      sizelist = {sizelist}                     

                      expirydate = {expiryDate}
                      productid = {productid}
                      tableRow = {tableRow}
                      getbarcodename= {getbarcodename}
                      handleOnChange={handleOnChange}
                      indexChange={indexChange}
                      setProductModal={setProductModal}
                      productmodal={productmodal}
                      hasResults={hasResults}
                      selectedIndex={selectedIndex}
                      productlist={productlist}
                      listClickcode={listClickcode}
                      stockChange={stockChange}
                      errormessage ={errormessage}

                      dproductcolor = {dproductcolor}
                      dproductcolorChange={dproductcolorChange}
                      dproductsize={dproductsize}
                      dproductsizechange={dproductsizechange}
                      dcost={dcost}
                      setDcost={setDcost}
                      dproductpurchaseprice={dproductpurchaseprice}
                      setDpurchaseprice={setDpurchaseprice}
                      dsalesrate={dsalesrate}
                      setDsalesrate={setDsalesrate}
                      dunit={dunit}
                      dunitchange={dunitchange}
                      dopeningqty={dopeningqty}
                      setDopeningqty={setDopeningqty}
                      dexpirydate={dexpirydate}
                      setDexpirydate={setDexpirydate}
                      combolistloader={combolistloader}
                      deleteCombo={deleteCombo}
                      addtablerow={addtablerow}
                />
                <Row style={{marginTop: 20}}>
                  <Col xs={12} >
                    {saveLoader ? 
                    <div style={{float: 'right'}}>
                        <BtnLoader />
                    </div>
                  :
                    <Button onClick={onNext} style={{float: 'right', marginBottom: 20}}  variant="contained">Online Campaign Save</Button>
                  }
                  </Col>
                </Row>
            
              </Col>
              
          </Row>
          }
      </div>
    )
}
export default OnlineCampaign;