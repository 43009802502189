import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Loader from '../loader/loader';
import Loader2 from "react-loader-spinner";

const columns = [
    { id: '1', label: 'Vehicle Name', minWidth: 50,span: false },
    { id: '1', label: 'AG code', minWidth: 50,span: false },
    { id: '2', label: 'Register No.', minWidth: 50,span: false },
    { id: '3', label: 'Prev service date', minWidth: 50,span: false },
    { id: '4', label: 'Prev Service KM', minWidth: 50,span: false },
    { id: '5', label: 'Next service date', minWidth: 50,span: false },
    { id: '5', label: 'Next Service KM', minWidth: 50,span: false },
    { id: '5', label: 'Registerdate', minWidth: 50,span: false },
    { id: '5', label: 'Model', minWidth: 50,span: false },
    { id: '5', label: 'Istimara Expirydate', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }
  


function State(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [userId, setUserid] = useState("")
    const [btnloader, setBtnloader] = React.useState(false);
    const [toggle,  setToggle] = useState(false)
    const [modal, setModal] = useState(false);
    const [age, setAge] = React.useState('');
    

    const [source, setSource] = React.useState([]);
    const [loader, setLoader] = React.useState(false);

    const [vehicleId, setVehicleid] = React.useState("");
    const [vehiclename, setVehiclename] = React.useState("");
    const [vehicleType, setVehicletype] = React.useState("");
    const [vehicletypeList, setVehicletypelist] = React.useState([]);
    const [registerDate, setRegisterDate] = React.useState(new Date);
    const [modalno, setModalno] = React.useState("");
    const [serviceDate, setServiceDate] = React.useState(new Date);
    const [prevkm, setPrevkm] = React.useState("");
    const [nextserviceDate, setNextserviceDate] = React.useState(new Date);
    const [nextkm, setNextkm] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [vehicleno, setVehicleNo] = React.useState("");
    const [isthimaraDate, setIsthimaradate] = React.useState(new Date);
    const [expisthimara, setExpisthimara] = React.useState(new Date);
   
    
    

  function setData(childData) {
    setToggle(childData)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData()
    getVehicletype()
  }, []);

  const getVehicletype = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)

      var data = {
        "userid": userdata.user_id,
        "type" : userdata.user_type,
        "companyid": company,
        "branchid" : branch
      }

      fetch(global.url + "viewVehicleType", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            setLoader(false)
            console.log("response",responseJson )
            if(responseJson.status == "false"){
              setVehicletypelist(responseJson.data)
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
  }

  const editClick = (
    vehicleid, 
    vehiclename,
    vehicleno,
    prvservicedata,
    prvkm,
    nxtservicedate,
    nxtkm,
    registerdate,
    modalno,
    isthimara,
    isthimaraexp,
    vehicletypeid
    ) => {
      setModal(true)
      setVehicleid(vehicleid)
      setVehiclename(vehiclename)
      setVehicleNo(vehicleno)
      setServiceDate(prvservicedata)
      setPrevkm(prvkm)
      setNextserviceDate(nxtservicedate)
      setNextkm(nxtkm)
      setModalno(modalno)
      setIsthimaradate(isthimara)
      setExpisthimara(isthimaraexp)
      setVehicletype(vehicletypeid)
      setRegisterDate(registerdate)
      setDescription("")
      getVehicletype()
  }

  const onAdd = () => {
    setModal(true)
    setVehicleid("")
    setVehiclename("")
    setVehicleNo("")
    setVehicletype("")
    setRegisterDate(new Date)
    setModalno("")
    setPrevkm("")
    setNextserviceDate(new Date)
    setNextkm("")
    setServiceDate(null)
    setIsthimaradate(new Date)
    setExpisthimara(new Date)
    setServiceDate(new Date)
    setDescription("")
    getVehicletype()
  }

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
      var data = {
            "companyid": company,
            "type":  userdata.user_type
      }
      fetch(global.url + "viewVehicle", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
          console.log("response", responseJson)
            setLoader(false)
            if(responseJson.status == "false") {
              setSource(responseJson.data)
              
            }
           
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const vehicletypeChange = (event) => {
      setVehicletype(event.target.value);
    };

    const onSave = () => {
      setBtnloader(true)
      var data = {
          "vehicle_id": vehicleId,
          "vehicle_name": vehiclename,
          "vehicle_no": vehicleno,
          "vehicle_type": vehicleType,
          "vehicle_prvservicedate": serviceDate,
          "vehicle_prvkm": prevkm,
          "vehicle_nxtservicedate": nextserviceDate,
          "vehicle_nxtkm": nextkm,
          "vehicle_registerdate": registerDate,
          "vehicle_modal": modalno,
          "vehicle_isthimara": isthimaraDate,
          "vehicle_isthimaraexp": expisthimara,
          "vehicle_companyid": usercompanyid,
          "vehicle_branchid": userbranchid,
          "vehicle_userid": userId
      }
      console.log("data", data)
      fetch(global.url + "updateVehicle", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("responsejson", responseJson);
          if(responseJson.error == false) {
            getData()
            setModal(false)
            alert(responseJson.message)
            setBtnloader(false)
          }else{
            alert(responseJson.message)
            setBtnloader(false)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const deleteClick = (id) =>  {
      fetch(global.url + "Vehicle/deletevehiclebyid?id=" + id, {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem("token")
          },
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          alert(responseJson.message)
          getData()
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Vehicle List</p>
               <Button variant="contained" onClick={onAdd}>Add</Button>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow  tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.vehicle_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.vehicle_agcode}
                              </TableCell>
                              <TableCell >
                                  {row.vehicle_no}
                              </TableCell>
                              <TableCell >
                                  {row.vehicle_prvservicedate}
                              </TableCell>
                              <TableCell >
                                  {row.vehicle_prvkm}
                              </TableCell>
                              <TableCell >
                                  {row.vehicle_nxtservicedate}
                              </TableCell>
                              <TableCell >
                                  {row.vehicle_nxtkm}
                              </TableCell>
                              <TableCell >
                                  {row.vehicle_registerdate}
                              </TableCell>
                              <TableCell >
                                  {row.vehicle_modal}
                              </TableCell>
                              <TableCell >
                                  {row.vehicle_isthimaraexp}
                              </TableCell>
                              <TableCell >
                                  <a href="javascript:void(0)" onClick={() => 
                                    editClick(row.vehicle_id, 
                                      row.vehicle_name, 
                                      row.vehicle_no, 
                                      row.vehicle_prvservicedate, 
                                      row.vehicle_prvkm,
                                      row.vehicle_nxtservicedate,
                                      row.vehicle_nxtkm,
                                      row.vehicle_registerdate,
                                      row.vehicle_modal,
                                      row.vehicle_isthimara,
                                      row.vehicle_isthimaraexp,
                                      row.vehicleType_id
                                    )}>
                                      <FaIcons.FaEdit fontSize={20} color="blue" />
                                  </a>
                              </TableCell>
                              {/* <TableCell >
                                  <a href="javascript:void(0)" onClick={() => deleteClick(row.vehid)}>
                                    <RiIcons.RiDeleteBin7Fill  color="red" fontSize={20}  />
                                  </a>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Vehicle Creation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setVehiclename(e.target.value)} value={vehiclename} id="outlined-basic" label="Vehicle Name" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12} >
                        <FormControl sx={{ mt: 4, width: '100%' }}>
                            <TextField onChange={(e) => setVehicleNo(e.target.value)} value={vehicleno}  id="outlined-basic" label="Vehicle No." variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col xs={12}>
                      <FormControl sx={{ mt: 4, width: '100%' }}>
                              <InputLabel style={{marginTop: -7}} id="demo-simple-select-helper-label">Vehicle Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={vehicleType}
                                label="Age"
                                onChange={vehicletypeChange}
                                size="small"
                                
                              >
                              <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {vehicletypeList.map((item, index) => (
                                    <MenuItem value={item.vehicleType_id}>{item.vehicleType_name}</MenuItem>
                                  ))}
                            </Select>
                      </FormControl>
                    </Col>
                    <Row style={{marginTop: 30}}>
                        <Col xs={6} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                            size="small"
                                label="Register Date"
                                value={registerDate}
                                onChange={(newValue) => {
                                setRegisterDate(newValue);
                                console.log("register", newValue)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                             </LocalizationProvider>
                        </Col>
                        <Col xs={6}>
                          <FormControl sx={{ width: '100%' }}>
                              <TextField onChange={(e) => setModalno(e.target.value)} value={modalno}  id="outlined-basic" label="Modal." variant="outlined"  size="small"/>
                          </FormControl>
                        </Col>
                        
                    </Row>
                    <Row style={{marginTop: 30}}>
                        <Col xs={6} >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="Service Date"
                                  value={serviceDate}
                                  onChange={(newValue) => {
                                  setServiceDate(newValue);
                                  console.log("service", newValue)
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={6}>
                          <FormControl sx={{ width: '100%' }}>
                              <TextField onChange={(e) => setPrevkm(e.target.value)} value={prevkm}  id="outlined-basic" label="Previous Service KM" variant="outlined"  size="small"/>
                          </FormControl>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 30}}>
                        <Col xs={6} >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="Next Service Date"
                                  value={nextserviceDate}
                                  onChange={(newValue) => {
                                  setNextserviceDate(newValue);
                                  console.log("service", newValue)
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={6}>
                          <FormControl sx={{ width: '100%' }}>
                              <TextField onChange={(e) => setNextkm(e.target.value)} value={nextkm}  id="outlined-basic" label="Next Service KM" variant="outlined"  size="small"/>
                          </FormControl>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 30}}>
                        <Col xs={6} >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="Isthimara date"
                                  value={isthimaraDate}
                                  onChange={(newValue) => {
                                  setIsthimaradate(newValue);
                                  console.log("service", newValue)
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={6} >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="Isthimara Expiry date"
                                  value={expisthimara}
                                  onChange={(newValue) => {
                                  setExpisthimara(newValue);
                                  console.log("service", newValue)
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                        </Col>
                    </Row>

  
                    <Col xs ={12} style={{marginTop:30}}>
                    {
                        btnloader ? 
                        <Loader2
                            type="Oval"
                            color="#38B0DE"
                            height={30}
                            width={30}
                            style={{float: 'right'}}
                        />:
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>}
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default State;